import { Box, Typography } from '@mui/material'
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge'

const renderGauge = (value, max, label) => (
  <Box textAlign='center'>
    <Typography variant='h6'>{label}</Typography>
    <Gauge
      width={300}
      height={200}
      value={value}
      valueMax={max}
      startAngle={-100}
      endAngle={100}
      sx={theme => ({
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: 24,
          fontFamily: 'Poppins',
          fontWeight: 300,
        },
        [`& .${gaugeClasses.valueArc}`]: { fill: '#652d92' },
        [`& .${gaugeClasses.referenceArc}`]: { fill: theme.palette.text.disabled },
      })}
      text={`$${value} / $${max}`}
    />
  </Box>
)

export default renderGauge
