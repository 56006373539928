import firebase from './app'
import 'firebase/compat/storage'

// Connect Storage emulator if enabled
const storageEmulator = import.meta.env.VITE_FIREBASE_EMULATOR_STORAGE
if (storageEmulator) {
  const [host, port] = storageEmulator.split(':')
  firebase.storage().useEmulator(host, Number(port))
}

const storage = () => firebase.storage()

export const uploadFile = async (path, file, metadata = {}) => {
  try {
    console.log('  -- Uploading file')
    console.log('  -- Path: ', path)
    console.log('  -- File: ', file)
    console.log('  -- Metadata: ', metadata)
    const storageRef = storage().ref()
    const fileRef = storageRef.child(path)
    await fileRef.put(file, metadata)
    return fileRef.getDownloadURL()
  } catch (error) {
    console.log('**** File Upload Error ****')
    console.log(error)
    return null
  }
}

export default storage
