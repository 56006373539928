import { useEffect, useState, useRef, useCallback } from 'react'
import { Grid, Box, LinearProgress, ButtonGroup, Collapse, CircularProgress } from '@mui/material'
import Zoom from '@mui/material/Zoom'
import TextField from '@mui/material/TextField'
import Typography from '../stories/Typography'
import { useCurrentProfile, useCurrentClinic } from '../context/CurrentUser'
import Button from '../stories/Button'
import { isEmail, isPhone } from '../libs/validators'
import SelectField from '../stories/Select'
import { createAccount } from '../firebase/functions'
import OrderDetailsCard from './OrderDetailsCard'
import Icon from '../stories/Icons'
import { convertFirestoreToDate, formatDateForDatePicker } from '../libs/formatters'

import useEmrOrders from '../hooks/useEmrOrders'

import StepperContainer from '@app/containers/StepperContainer'
import OrdersPatientInfoForm from './EmrOrderFormComponents/OrdersPatientInfoForm'
import DiagnosisCodeSearch from './EmrOrderFormComponents/DiagnosisSearch'
import ServiceSearch from './EmrOrderFormComponents/ServiceSearch'
import MedicationSearch from './EmrOrderFormComponents/MedicationSearch'
import OrderNotes from './EmrOrderFormComponents/OrderNotes'
import ReviewOrder from './EmrOrderFormComponents/ReviewOrder'
import ReviewMedicationOrder from './EmrOrderFormComponents/ReviewMedicationOrder'

import FilterContainer from './EmrOrderFilters/FilterContainer'
import TypeFilters from './EmrOrderFilters/TypeFilters'
import DateFilters from './EmrOrderFilters/DateFilters'
import LastnameFilter from './EmrOrderFilters/LastnameFilter'

const ProviderOrders = () => {
  const {
    organizationId: clinicID,
    organizationName: clinicName,
    firstname: providerProfileFirstname,
    lastname: providerProfileLastname,
    uid: providerID,
    defaultNpi: providerProfileNPI,
    email: providerProfileEmail,
    phone: providerProfilePhone,
  } = useCurrentProfile()

  useEffect(() => {
    console.log('defaultNpi', providerProfileNPI)
  }, [providerProfileNPI])

  const { npi: mainProviderNPI, plansAssociated } = useCurrentClinic()

  const {
    createNewOrder,
    diagnosisCodeData,
    setDiagnosisCodeData,
    serviceSelected,
    setServiceSelected,
    medicationSelected,
    setMedicationSelected,
    SIG,
    setSIG,
    quantity,
    setQuantity,
    drugAllergies,
    setDrugAllergies,
    otherMedications,
    setOtherMedications,
    medicalConditions,
    setMedicalConditions,
    pregnant,
    setPregnant,
    nursing,
    setNursing,
    safetyCap,
    setSafetyCap,
    providerFirstname,
    setProviderFirstname,
    providerNPI,
    setProviderNPI,
    noteContent,
    setNoteContent,
    currentFile,
    setCurrentFile,
    setPatientUserID,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    email,
    setEmail,
    phone,
    setFormattedPhone,
    dob,
    setDob,
    gender,
    setGender,
    street,
    setStreet,
    street2,
    setStreet2,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    submitDisabled,
    clearPatientInfo,
    clearGeneralOrderInfo,
    clearAllOrderInfo,
    fetchEmrOrders,
    displayEmrOrders,
    emrOrdersLoading,
    ordersLimit,
    setOrdersLimit,
    setOrdersFilter,
    ordersFilter,
  } = useEmrOrders({
    voucher: {
      preReg: true,
    },
    provider: {
      id: providerID,
      firstname: providerProfileFirstname,
      lastname: providerProfileLastname,
      email: providerProfileEmail,
      phone: providerProfilePhone,
      npi: providerProfileNPI || mainProviderNPI,
      clinicID,
      clinicName,
    },
  })

  const [newOrderOpen, setNewOrderOpen] = useState(false)

  const [orderType, setOrderType] = useState('')

  const referralOrderBubbleInfo = [
    { stepName: 'Patient Info' },
    { stepName: 'Diagnosis Code Selection' },
    { stepName: 'Service Selection' },
    { stepName: 'Additional Notes' },
    { stepName: 'Review Order' },
  ]

  const medicationOrderBubbleInfo = [
    { stepName: 'Patient Info' },
    { stepName: 'Diagnosis Code Selection' },
    { stepName: 'Medication Selection' },
    { stepName: 'Additional Notes' },
    { stepName: 'Review Order' },
  ]

  // progress bar
  const progressInterval = useRef(null)
  const [progress, setProgress] = useState(0)
  const [buffer, setBuffer] = useState(10)

  const [patientProfile, setPatientProfile] = useState(null)

  const [nextDisabled, setNextDisabled] = useState(false)

  const [errorMessage, setErrorMessage] = useState(null)

  const [algoliaFilters, setAlgoliaFilters] = useState(null)

  const [ordersTab, setOrdersTab] = useState('new')

  const [openFilters, setOpenFilters] = useState(false)

  useEffect(() => {
    if (!openFilters && ordersTab !== 'new') {
      fetchEmrOrders()
    }
  }, [openFilters])

  const handleFilterClear = () => {
    setOrdersFilter({
      startDate: '',
      endDate: '',
      lastname: '',
      medication: false,
      lab: true,
      referral: true,
      other: true,
      paid: true,
      unpaid: true,
      failed: true,
    })
  }

  const handleFilterReset = () => {
    setOrdersFilter({
      startDate: '',
      endDate: '',
      lastname: '',
      medication: false,
      lab: false,
      referral: false,
      other: false,
      paid: false,
      unpaid: false,
      failed: false,
    })
  }

  const startProgressBar = () => {
    setProgress(0)
    setBuffer(10)
    clearInterval(progressInterval.current)
    progressInterval.current = null

    // startErrorTimer()
    progressInterval.current = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
      setBuffer(oldProgress => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, 500)
  }

  const stopProgressBar = () => {
    clearInterval(progressInterval.current)
    progressInterval.current = null
    setProgress(0)
    setBuffer(0)
  }

  // BEGIN - Initial load of orders
  useEffect(() => {
    console.log('Initial load of orders')
    clinicID && fetchEmrOrders()
  }, [clinicID, ordersLimit])
  // END - Initial load of orders

  const cleanUp = () => {
    clearAllOrderInfo()
    setOrderType('')
    setNewOrderOpen(false)
    setGender(null)
    setErrorMessage(null)
    setNextDisabled(false)
    setPatientProfile(null)
  }

  const passPatientToOrder = patientInfo => {
    if (!patientInfo) {
      return
    }
    console.log('Passing patient to order')
    console.log(patientInfo)
    setPatientUserID(patientInfo?.id || patientInfo?.uid || '')
    setFirstname(patientInfo?.firstname || '')
    setLastname(patientInfo?.lastname || '')
    setFormattedPhone(patientInfo?.phone || '')
    setDob(formatDateForDatePicker(patientInfo?.dob || patientInfo?.birthday) || '')
    setGender(patientInfo?.gender || '')
    setStreet(patientInfo?.address?.street || '')
    setStreet2(patientInfo?.address?.street2 || '')
    setCity(patientInfo?.address?.city || '')
    setState(patientInfo?.address?.state || '')
    setZip(patientInfo?.address?.zip || '')
  }

  useEffect(() => {
    console.log('dob', dob)
  }, [dob])

  const handleAccount = useCallback(async () => {
    setNextDisabled(true)
    startProgressBar()
    // Get email from form

    if (!email && !phone) {
      console.log('No email or phone')
      setErrorMessage('Please enter an email or phone number')
      setNextDisabled(false)
      stopProgressBar()
      return false
    }

    // if (!(email && phone)) {
    //   console.log('Both email and phone')
    //   setErrorMessage('Please enter both an email and phone number')
    //   setNextDisabled(false)
    //   stopProgressBar()
    //   return false
    // }

    // check if email is valid
    if (email && !isEmail(email)) {
      console.log('Invalid email')
      setErrorMessage('Invalid email')
      setNextDisabled(false)
      stopProgressBar()
      return false
    }

    // check if phone is valid
    if (phone && !isPhone(phone)) {
      console.log('Invalid phone')
      setErrorMessage('Invalid phone')
      setNextDisabled(false)
      stopProgressBar()
      return false
    }

    const userStatus = await createAccount({ email, phone, clinicID })

    // check if email exists in db
    if (userStatus?.error) {
      if (userStatus?.error?.errorInfo?.code === 'auth/email-already-exists') {
        setErrorMessage(null)
        setPatientProfile(userStatus?.profile || {})
        passPatientToOrder(userStatus?.profile || {})
        stopProgressBar()
        return userStatus.user
      }

      if (userStatus?.error?.errorInfo?.code === 'auth/phone-number-already-exists') {
        console.log('Phone already exists')
        console.log(userStatus)
        setPatientProfile(userStatus?.profile || {})
        passPatientToOrder(userStatus?.profile || {})
        stopProgressBar()
        return userStatus.user
      }

      if (userStatus?.error?.errorInfo?.code === 'auth/invalid-email') {
        console.log('Invalid email')
        setErrorMessage('Invalid email')
        setNextDisabled(false)
        stopProgressBar()
        return false
      }

      if (userStatus?.error?.errorInfo?.code === 'auth/invalid-phone-number') {
        console.log('Invalid phone')
        setErrorMessage('Invalid phone')
        setNextDisabled(false)
        stopProgressBar()
        return false
      }

      console.log('User status has an error')
      console.log(userStatus?.error)

      setErrorMessage('Error creating account')
      setNextDisabled(false)
      stopProgressBar()
      return false
    }

    console.log('User created')
    console.log(userStatus)
    setPatientProfile(userStatus?.user)

    passPatientToOrder(userStatus?.user)

    stopProgressBar()

    return userStatus.user
  }, [email, phone, clinicID])

  const [planOptions, setPlanOptions] = useState([])

  const [selectedPlan, setSelectedPlan] = useState('default')
  const [selectedPlanLabel, setSelectedPlanLabel] = useState(null)

  useEffect(() => {
    if (!selectedPlan || selectedPlan === 'default') {
      setAlgoliaFilters(null)
      return undefined
    }
    setAlgoliaFilters(`networkId:${selectedPlan}`)
  }, [selectedPlan])

  useEffect(() => {
    if (!newOrderOpen) {
      setSelectedPlan('default')
      clearPatientInfo()
      clearGeneralOrderInfo()
      return undefined
    }

    // plans associated is an object with keys as network(plan) IDs and values as plan info
    if (plansAssociated && Object.keys(plansAssociated).length > 0) {
      const planOptions = Object.keys(plansAssociated).map(planID => {
        return {
          value: planID,
          label: plansAssociated[planID].name,
        }
      })

      // if url is not mishe.co add this id to the list of plans otherwise use other id
      if (window.location.hostname !== 'mishe.co') {
        planOptions.unshift({
          value: 'qwGk5c8R6wfOvVjh6SJ7',
          label: 'Mishe Health Plan',
        })
      } else {
        planOptions.unshift({
          value: 'UUrfH2FiTx2LjkdzUhnP',
          label: 'Mishe Health Plan',
        })
      }

      planOptions.unshift({
        value: 'default',
        label: 'Select a plan',
      })

      setPlanOptions(planOptions)
    }
  }, [newOrderOpen, plansAssociated])

  const handleTabChange = newValue => {
    setOrdersTab(newValue)
    if (openFilters) {
      setOpenFilters(false)
    }
  }

  const buildStepperComponents = useCallback(() => {
    if (orderType === 'referral') {
      return (
        <StepperContainer
          bubbleInfo={referralOrderBubbleInfo}
          disableSubmit={submitDisabled}
          stepControlDetails={{
            show: true,
            handleFinished: () => {
              createNewOrder(cleanUp)
            },
          }}
        >
          <OrdersPatientInfoForm
            key='patient-info'
            values={{
              firstname: firstname || '',
              lastname: lastname || '',
              email: email || '',
              phone: phone || '',
              dob: dob || '',
              gender: gender || '',
              street: street || '',
              street2: street2 || '',
              city: city || '',
              state: state || '',
              zip: zip || '',
            }}
            setters={{
              setFirstname,
              setLastname,
              setEmail,
              setPhone: setFormattedPhone,
              setDob,
              setGender,
              setStreet,
              setStreet2,
              setCity,
              setState,
              setZip,
            }}
          />
          <DiagnosisCodeSearch
            key='diagnosis-code'
            values={{
              diagnosisCodeData,
            }}
            setters={{
              setDiagnosisCodeData,
            }}
          />
          <Box>
            {!serviceSelected && (
              <Box>
                <Box display='flex' gap='16px' alignItems='center' mb={2}>
                  <Typography variant='h4' margin='0px'>
                    {selectedPlan !== 'default'
                      ? selectedPlanLabel
                      : 'Select a plan to view services'}
                  </Typography>
                  <Zoom in={selectedPlan !== 'default'}>
                    <Box>
                      <Button
                        variant='outlined'
                        color='primary'
                        size='small'
                        onClick={() => {
                          setSelectedPlan('default')
                        }}
                      >
                        <Box display='flex' alignItems='center' gap='4px'>
                          <Icon name='edit' />
                          <Typography variant='body1' margin='0px'>
                            Change Plan
                          </Typography>
                        </Box>
                      </Button>
                    </Box>
                  </Zoom>
                </Box>
                <Collapse in={selectedPlan === 'default'} timeout={{ enter: 500, exit: 500 }}>
                  <SelectField
                    label='Plan'
                    value={selectedPlan}
                    margin='none'
                    onChange={e => {
                      setSelectedPlan(e.target.value)
                      setSelectedPlanLabel(
                        planOptions.find(option => option.value === e.target.value)?.label,
                      )
                    }}
                    options={planOptions}
                  />
                </Collapse>
              </Box>
            )}
            <Collapse in={algoliaFilters} timeout={{ enter: 500, exit: 500 }}>
              <ServiceSearch
                key='service-selection'
                values={{
                  serviceSelected,
                  algoliaFilters,
                }}
                setters={{
                  setServiceSelected,
                }}
              />
            </Collapse>
          </Box>
          <OrderNotes
            key='additional-notes'
            values={{
              providerFirstname,
              providerNPI,
              noteContent,
              currentFile,
            }}
            setters={{
              setProviderFirstname,
              setProviderNPI,
              setNoteContent,
              setCurrentFile,
            }}
          />
          <ReviewOrder
            key='review-order'
            values={{
              firstname,
              lastname,
              email,
              phone,
              dob,
              gender,
              street,
              street2,
              city,
              state,
              zip,
              noteContent,
              currentFile,
              serviceSelected,
              diagnosisCodeData,
              providerFirstname,
              providerNPI,
            }}
          />
        </StepperContainer>
      )
    } else {
      return (
        <StepperContainer
          bubbleInfo={medicationOrderBubbleInfo}
          disableSubmit={submitDisabled}
          stepControlDetails={{
            show: true,
            handleFinished: () => {
              createNewOrder(cleanUp)
            },
          }}
        >
          <OrdersPatientInfoForm
            key='patient-info'
            values={{
              firstname: firstname || '',
              lastname: lastname || '',
              email: email || '',
              phone: phone || '',
              dob: dob || '',
              gender: gender || '',
              street: street || '',
              street2: street2 || '',
              city: city || '',
              state: state || '',
              zip: zip || '',
            }}
            setters={{
              setFirstname,
              setLastname,
              setEmail,
              setPhone: setFormattedPhone,
              setDob,
              setGender,
              setStreet,
              setStreet2,
              setCity,
              setState,
              setZip,
            }}
          />
          <DiagnosisCodeSearch
            key='diagnosis-code'
            values={{
              diagnosisCodeData,
            }}
            setters={{
              setDiagnosisCodeData,
            }}
          />
          <MedicationSearch
            key='medication-selection'
            values={{
              medicationSelected,
              SIG,
              quantity,
              drugAllergies,
              otherMedications,
              medicalConditions,
              pregnant,
              nursing,
              safetyCap,
            }}
            setters={{
              setMedicationSelected,
              setSIG,
              setQuantity,
              setDrugAllergies,
              setOtherMedications,
              setMedicalConditions,
              setPregnant,
              setNursing,
              setSafetyCap,
            }}
          />
          <OrderNotes
            key='additional-notes'
            values={{
              providerFirstname,
              providerNPI,
              noteContent,
              currentFile,
            }}
            setters={{
              setProviderFirstname,
              setProviderNPI,
              setNoteContent,
              setCurrentFile,
            }}
          />
          <ReviewMedicationOrder
            key='review-order'
            values={{
              firstname,
              lastname,
              email,
              phone,
              dob,
              gender,
              street,
              street2,
              city,
              state,
              zip,
              noteContent,
              currentFile,
              medicationSelected,
              SIG,
              quantity,
              drugAllergies,
              otherMedications,
              medicalConditions,
              pregnant,
              nursing,
              safetyCap,
              diagnosisCodeData,
              providerFirstname,
              providerNPI,
            }}
          />
        </StepperContainer>
      )
    }
  }, [
    orderType,
    firstname,
    lastname,
    email,
    phone,
    dob,
    gender,
    street,
    street2,
    city,
    state,
    zip,
    diagnosisCodeData,
    serviceSelected,
    medicationSelected,
    SIG,
    quantity,
    drugAllergies,
    otherMedications,
    medicalConditions,
    pregnant,
    nursing,
    safetyCap,
    providerFirstname,
    providerNPI,
    noteContent,
    currentFile,
    algoliaFilters,
    submitDisabled,
  ])

  return (
    // if orders exist itterate through them and display them, if there are no orders display a message
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h1' gutterBottom>
          Orders
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          display='flex'
          flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
          sx={{
            width: '100%',
            justifyContent: {
              xs: 'center',
              sm: 'space-between',
            },
          }}
        >
          <Box
            display='flex'
            sx={{
              width: { xs: '100%', sm: '500px' },
              marginBottom: { xs: '8px', sm: '0px' },
            }}
          >
            <ButtonGroup style={{ width: '100%' }}>
              <Button
                style={{ minWidth: 'auto', width: '33.4%' }}
                variant={ordersTab === 'new' ? 'contained' : 'outlined'}
                color='primary'
                onClick={() => handleTabChange('new')}
              >
                New Order
              </Button>
              <Button
                style={{ minWidth: 'auto', width: '33.4%' }}
                variant={ordersTab === 'sent' ? 'contained' : 'outlined'}
                color='primary'
                onClick={() => handleTabChange('sent')}
              >
                Sent Orders
              </Button>
              <Button
                style={{ minWidth: 'auto', width: '33.4%' }}
                variant={ordersTab === 'received' ? 'contained' : 'outlined'}
                color='primary'
                onClick={() => handleTabChange('received')}
              >
                Received Orders
              </Button>
            </ButtonGroup>
          </Box>
          {ordersTab !== 'new' && (
            <Box
              display='flex'
              sx={{
                width: { xs: '100%', sm: '500px' },
                marginBottom: { xs: '8px', sm: '0px' },
                justifyContent: { xs: 'center', sm: 'flex-end' },
              }}
            >
              <Box padding='0 8px'>
                <Button
                  style={{ width: '100%' }}
                  variant={openFilters ? 'contained' : 'outlined'}
                  onClick={() => {
                    if (openFilters) {
                      handleFilterClear()
                    } else {
                      handleFilterReset()
                    }
                    setOpenFilters(!openFilters)
                  }}
                >
                  Open Filters
                </Button>
              </Box>
              <Box>
                <SelectField
                  label='Orders Shown'
                  value={ordersLimit}
                  margin='none'
                  onChange={e => setOrdersLimit(e.target.value)}
                  options={[
                    { value: '10', label: '10' },
                    { value: '30', label: '30' },
                    { value: '50', label: '50' },
                    { value: '100', label: '100' },
                  ]}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Grid>

      <FilterContainer
        open={openFilters}
        leftComponents={
          <TypeFilters
            filterFunction={(type, value) => {
              setOrdersFilter({ ...ordersFilter, [type]: value })
            }}
          />
        }
        rightComponents={
          <>
            <DateFilters
              filterFunction={(type, value) => {
                setOrdersFilter({ ...ordersFilter, [type]: value })
              }}
            />
            <LastnameFilter
              filterFunction={(type, value) => {
                setOrdersFilter({ ...ordersFilter, [type]: value })
              }}
            />
          </>
        }
        actionComponents={
          <>
            <Box padding='8px'>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => {
                  handleFilterClear()
                  setOpenFilters(false)
                }}
              >
                Clear Filters
              </Button>
            </Box>
            <Box padding='8px'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  fetchEmrOrders()
                }}
              >
                Apply Filters
              </Button>
            </Box>
          </>
        }
      />

      {ordersTab === 'sent' && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {displayEmrOrders?.sent?.length > 0 ? (
              displayEmrOrders?.sent?.map(order => {
                if (order?.display) {
                  return OrderDetailsCard({
                    order,
                    variant: 'grid',
                  })
                }
                return null
              })
            ) : (
              <>
                {emrOrdersLoading === false && (
                  <Grid item xs={12}>
                    <Typography variant='h4' gutterBottom>
                      You have not placed any orders.
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
      {ordersTab === 'received' && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {displayEmrOrders?.received?.length > 0 ? (
              displayEmrOrders?.received?.map(order => {
                if (order?.display) {
                  return OrderDetailsCard({
                    order,
                    variant: 'grid',
                  })
                }
                return null
              })
            ) : (
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  You have not received any orders.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {ordersTab === 'new' && (
        <>
          <Grid item xs={12}>
            <Typography variant='h5' gutterBottom>
              Actions
            </Typography>
            <Box
              display='flex'
              gap='16px'
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Button
                variant={orderType === 'referral' ? 'contained' : 'outlined'}
                color='primary'
                onClick={() => {
                  if (orderType === 'referral') {
                    setOrderType('')
                    setNewOrderOpen(false)
                    return
                  }
                  setOrderType('referral')
                  setNewOrderOpen(true)
                  setPatientProfile(null)
                  setNextDisabled(false)
                }}
              >
                New referral order
              </Button>
              <Button
                variant={orderType === 'medication' ? 'contained' : 'outlined'}
                color='primary'
                onClick={() => {
                  if (orderType === 'medication') {
                    setOrderType('')
                    setNewOrderOpen(false)
                    return
                  }
                  setOrderType('medication')
                  setNewOrderOpen(true)
                  setPatientProfile(null)
                  setNextDisabled(false)
                }}
                style={{
                  margin: '0px',
                }}
              >
                New medication order
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {newOrderOpen && (
              <Box
                mb={3}
                style={{
                  // light purple
                  backgroundColor: '#f5f3ff',
                  padding: '1rem',
                  borderRadius: '1rem',
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant='h4' color='primary' mb={1}>
                      Creating a new {orderType} order
                    </Typography>
                    <Typography variant='h5' color='primary' mb={0}>
                      Please enter the patient&apos;s email or phone number.
                    </Typography>
                  </Grid>
                  {errorMessage && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant='body1' color='error'>
                        {errorMessage}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id='initial-email'
                      value={email}
                      onChange={e => setEmail(e.target.value?.toLowerCase()?.trim() || '')}
                      disabled={nextDisabled}
                      label='Email'
                      variant='outlined'
                      fullWidth
                      style={{
                        backgroundColor: '#fff',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={1} lg={1}>
                    <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
                      <Typography variant='body1' color='primary'>
                        <strong>OR</strong>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id='initial-phone'
                      value={phone}
                      onChange={e => setFormattedPhone(e.target.value?.trim() || '')}
                      disabled={nextDisabled}
                      label='Phone Number'
                      variant='outlined'
                      fullWidth
                      style={{
                        backgroundColor: '#fff',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Button
                      variant='contained'
                      color='primary'
                      fullWidth
                      disabled={nextDisabled}
                      onClick={handleAccount}
                    >
                      Next
                    </Button>
                  </Grid>
                  {progressInterval.current && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <LinearProgress variant='buffer' value={progress} valueBuffer={buffer} />
                    </Grid>
                  )}
                  {patientProfile && (
                    <Box padding='16px 0px 0px 24px' width='100%'>
                      {buildStepperComponents()}
                    </Box>
                  )}
                </Grid>
              </Box>
            )}
          </Grid>
        </>
      )}

      {emrOrdersLoading && (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
        >
          <CircularProgress />
        </div>
      )}
    </Grid>
  )
}

export default ProviderOrders
