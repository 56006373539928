import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'

const ComponentShifterFadeControl = ({
  children,
  cycleLength = 5000,
  buttonColor = '',
  healthPlanHomePage,
}) => {
  const [fadeIn, setFadeIn] = useState(true)
  const [childShift, setChildShift] = useState(0)
  const [buttonShift, setButtonShift] = useState(0)
  const [debouncedClick, setDebouncedClick] = useState(false)
  const [autoShift, setAutoShift] = useState(true)

  const childrenArray = Object.keys(children)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedClick(false)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [debouncedClick])

  useEffect(() => {
    if (autoShift) {
      const interval = setInterval(() => {
        setFadeIn(false)
        setTimeout(() => {
          setChildShift(prevShift => (prevShift + 1) % childrenArray.length)
          setButtonShift(prevShift => (prevShift + 1) % childrenArray.length)
          setFadeIn(true)
        }, 500)
      }, cycleLength)

      return () => clearInterval(interval)
    }
    return () => {}
  }, [autoShift, buttonShift, children, childrenArray.length, cycleLength])

  const handleButtonClick = shift => {
    setAutoShift(false)
    if (!debouncedClick) {
      setFadeIn(false)
      setDebouncedClick(true)

      if (buttonShift === shift) {
        setButtonShift(0)
      } else {
        setButtonShift(shift)
      }

      setTimeout(() => {
        if (childShift === shift) {
          setChildShift(0)
        } else {
          setChildShift(shift)
        }
        setFadeIn(true)
      }, 500)
    }
  }

  return (
    <Box
      position='relative'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width='100%'
      height='100%'
    >
      <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='100%'>
        <Box height='100%'>
          <IconButton
            size='small'
            onClick={() =>
              handleButtonClick(buttonShift === 0 ? childrenArray.length - 1 : buttonShift - 1)
            }
            style={{
              height: '100%',
              borderRadius: '0 50% 50% 0',
              color: buttonColor,
            }}
            sx={{
              width: {
                xs: '40px',
                md: '100px',
              },
            }}
          >
            <Icon name='chevronLeft' />
          </IconButton>
        </Box>
        <Fade in={fadeIn} timeout={{ enter: 1000, exit: 500 }}>
          <Box
            padding='0 2rem'
            display='flex'
            justifyContent='center'
            sx={{
              width: {
                xs: healthPlanHomePage ? '100%' : 'calc(100% - 200px)',
                md: healthPlanHomePage ? '100%' : 'calc(100% - 200px)',
              },
            }}
          >
            {children[childShift]}
          </Box>
        </Fade>
        <Box height='100%'>
          <IconButton
            size='small'
            onClick={() =>
              handleButtonClick(buttonShift === childrenArray.length - 1 ? 0 : buttonShift + 1)
            }
            style={{
              height: '100%',
              borderRadius: '50% 0 0 50%',
              color: buttonColor,
            }}
            sx={{
              width: {
                xs: '40px',
                md: '100px',
              },
            }}
          >
            <Icon name='chevronRight' />
          </IconButton>
        </Box>
      </Box>
      <Box
        // position='absolute'
        // bottom='16px'
        marginTop={healthPlanHomePage ? '16px' : '0'}
        display='flex'
        justifyContent='center'
        alignItems='center'
        width='100%'
      >
        {childrenArray.map((child, index) => (
          <IconButton
            key={index}
            size='small'
            onClick={() => {
              setAutoShift(false)
              setFadeIn(false)
              setButtonShift(index)
              setTimeout(() => {
                setChildShift(index)
                setFadeIn(true)
              }, 500)
            }}
            style={{
              color: buttonColor,
            }}
          >
            <Icon
              name={buttonShift === index ? 'fiberManualRecord' : 'fiberManualRecordOutlined'}
              size='small'
            />
          </IconButton>
        ))}
      </Box>
    </Box>
  )
}

ComponentShifterFadeControl.propTypes = {
  children: PropTypes.array.isRequired,
  cycleLength: PropTypes.number,
  buttonColor: PropTypes.string,
}

export default ComponentShifterFadeControl
