import PropTypes from 'prop-types'
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Box from '@mui/material/Box'
import Button from '../stories/Button'

import InstantSearchContainerCustom from '@app/containers/InstantSearchContainerCustom'
import ServiceSearchCardNetwork from './ServiceSearchCardNetwork'
import PlanAdminProviderSearchCard from './PlanAdminProviderSearchCard'
import ProviderSearchCard from './ProviderSearchCard'
import Typography from '@app/stories/Typography'

import { useWhiteLabelingContext } from '../context/WhiteLabelingContext'

const NetworkCareTypesListNetwork = ({ networkSlug, networkData }) => {
  const { searchTypes, providerSearchButtonText, servicesSearchButtonText } = networkData
  const [searchLimit, setSearchLimit] = useState(10)
  const [currentSearchType, setCurrentSearchType] = useState('services')

  const { planColorPrimary } = useWhiteLabelingContext()

  const ProvidersSearchComponent = () => {
    const { providerSearchTagFilters = [] } = networkData

    const configuration = {
      hitsPerPage: searchLimit,
    }

    if (providerSearchTagFilters?.length > 0) {
      configuration.filters = providerSearchTagFilters.map(tag => `tags:${tag}`).join(' OR ')
    }

    return (
      <InstantSearchContainerCustom
        HitComponent={PlanAdminProviderSearchCard}
        // hitClick={`/plans/${networkSlug}/service/`}
        indexName='providers'
        showInitialHits
        configuration={configuration}
        searchBoxText='Search for a provider'
        noResultsMessage='No results for'
        networkLanderSearch
        noResultsColor='#000'
        customZIndex={5}
        setSearchLimit={setSearchLimit}
        searchLimit={searchLimit}
        whiteLabelingPrimaryColor={planColorPrimary}
      />
    )
  }

  const SearchComponent = () => {
    return (
      <Box
        width='100%'
        sx={{
          minHeight: {
            xs: '110vh',
            sm: '110vh',
            md: '75vh',
            lg: '75vh',
          },
        }}
      >
        <InstantSearchContainerCustom
          HitComponent={ServiceSearchCardNetwork}
          hitClick={`/plans/${networkSlug}/service/`}
          indexName='network_services'
          showInitialHits
          configuration={{
            filters: `network.slug:${networkSlug}`,
            hitsPerPage: searchLimit,
          }}
          searchBoxText='Search for symptom, service, or provider'
          noResultsMessage='No results for'
          noResultsColor='#000'
          customZIndex={5}
          networkLanderSearch
          setSearchLimit={setSearchLimit}
          searchLimit={searchLimit}
          usePagination
          overflowShown
          whiteLabelingPrimaryColor={planColorPrimary}
        />
      </Box>
    )
  }
  if (!searchTypes || searchTypes?.length === 0) {
    return (
      <Section id='specialties'>
        <Container>
          <Box position='relative'>
            <SearchComponent />
          </Box>
        </Container>
      </Section>
    )
  }

  if (searchTypes.includes('providers') && searchTypes.includes('services')) {
    return (
      <Section id='specialties'>
        <Box
          position='relative'
          padding='20px'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          marginBottom='40px'
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            marginBottom='20px'
          >
            <Typography variant='h3' gutterBottom color={planColorPrimary || '#652d92'}>
              Search for providers or services
            </Typography>
            <Typography variant='body1' gutterBottom weight='light'>
              Find the right provider or service to meet your needs
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            marginBottom='20px'
            borderBottom={`1px solid ${planColorPrimary || '#652d92'}`}
            width='min-content'
            paddingX='32px'
          >
            <Button
              size='medium'
              variant='unstyled'
              onClick={() => setCurrentSearchType('services')}
              style={{
                borderBottom:
                  currentSearchType === 'services'
                    ? `5px solid ${planColorPrimary || '#652d92'}`
                    : '5px solid #ffffff00',
                marginLeft: '0px',
                borderRadius: '0px',
                color:
                  currentSearchType === 'services' ? `${planColorPrimary || '#652d92'}` : '#000',
                whiteSpace: 'nowrap',
              }}
            >
              {servicesSearchButtonText || 'Services'}
            </Button>
            <Button
              size='medium'
              variant='unstyled'
              onClick={() => setCurrentSearchType('providers')}
              style={{
                borderBottom:
                  currentSearchType === 'providers'
                    ? `5px solid ${planColorPrimary || '#652d92'}`
                    : '5px solid #ffffff00',
                marginLeft: '0px',
                borderRadius: '0px',
                color:
                  currentSearchType === 'providers' ? `${planColorPrimary || '#652d92'}` : '#000',
                whiteSpace: 'nowrap',
              }}
            >
              {providerSearchButtonText || 'Providers'}
            </Button>
          </Box>
        </Box>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: { xs: '90vh' } }}>
              {currentSearchType === 'providers' && <ProvidersSearchComponent />}
              {currentSearchType === 'services' && <SearchComponent />}
            </Grid>
          </Grid>
        </Container>
      </Section>
    )
  }

  if (searchTypes.includes('providers')) {
    return (
      <Section id='specialties'>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: { xs: '90vh', md: '60vh' } }}>
              <ProvidersSearchComponent />
            </Grid>
          </Grid>
        </Container>
      </Section>
    )
  }

  return (
    <Section id='specialties'>
      <Container>
        <Grid container spacing={3} display='flex' alignItems='center'>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ height: '80vh' }}>
            <SearchComponent />
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

NetworkCareTypesListNetwork.propTypes = {
  networkSlug: PropTypes.string.isRequired,
}

export default NetworkCareTypesListNetwork
