import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { formatDateForUI } from '@app/libs/formatters'
import MemberPlanCard from '@app/components/MemberPlanCard'
import PatientPlanCopayInfo from '@app/components/PatientPlanCopayInfo'
import VoucherDetailsEntry from '@app/containers/VoucherDetailsEntry'
import renderGauge from '../Guages'

const HEADERS = ['Name', 'Type', 'Spend Amount', 'Family Amount Spent', 'Total Services']
const BREAKDOWN_HEADERS = ['Name', 'Type', 'Voucher', 'Date', 'Amount Paid']

const SpendUp = (planID, plan, profile) => (
  <>
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        overflow: 'scroll',
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        sx={{
          width: { xs: '100%', md: '33%' },
          padding: '16px',
          marginBottom: { xs: '32px', md: '0' },
        }}
      >
        <Card sx={{ maxWidth: 600, margin: '20px auto', boxShadow: 3 }}>
          <CardContent>
            <PatientPlanCopayInfo copayInfo={plan.planData.planDesign} />
          </CardContent>
        </Card>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        sx={{
          width: { xs: '100%', md: '33%' },
          padding: '16px',
          marginBottom: { xs: '32px', md: '0' },
        }}
      >
        {renderGauge(plan.deductible().spent, plan.deductible().total, 'Deductible')}
        {renderGauge(plan.oop().spent, plan.oop().total, 'Total OOP')}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        sx={{
          width: { xs: '100%', md: '33%' },
          padding: '16px',
          marginBottom: { xs: '32px', md: '0' },
        }}
      >
        <MemberPlanCard
          firstName={profile?.firstname}
          lastName={profile?.lastname}
          memberNumber={profile?.uid}
          groupNumber={planID}
          pcpCopay='$25'
          specialistCopay={plan?.planData?.planDesign?.officeOrSpecialistVisit?.copay}
          emergencyRoomCopay={plan?.planData?.planDesign?.emergencyRoom?.copay}
          memberServiceNumber='(601)-647-4326'
          planType={plan?.planData?.planType || '---'}
          effectiveDate={plan?.planData?.effectiveDate}
          prescriptionGroup={plan?.planData?.prescriptionGroup || '---'}
          prescriptionCopay='$15 / $30 / $60'
        />
      </Box>
    </Box>

    <Typography variant='h6' align='center'>
      Family Summary
    </Typography>
    <TableContainer component={Paper} sx={{ width: '100%', mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            {HEADERS.map(header => (
              <TableCell key={header}>
                <Typography variant='h6'>{header}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {plan.familyVouchers.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                {row.name || `${row.firstname || ''} ${row.lastname || ''}`.trim()}
              </TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>
                {row.vouchers.reduce((total, voucher) => total + voucher.amountPaid, 0)}
              </TableCell>
              <TableCell>${plan.totalFamilySpend()}</TableCell>
              <TableCell>{row.vouchers.length}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {plan.memberType == 1 && (
      <>
        <Typography variant='h6' sx={{ mt: 4 }} align='center'>
          Family Breakdown
        </Typography>
        <TableContainer component={Paper} sx={{ width: '100%', mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                {BREAKDOWN_HEADERS.map(header => (
                  <TableCell key={header}>
                    <Typography variant='h6'>{header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {plan.familyBreakdown().map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {row.name || `${row.firstname || ''} ${row.lastname || ''}`.trim()}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>
                    <VoucherDetailsEntry voucher={row.voucher}>
                      <Button size='small' variant='outlined'>
                        <Typography variant='body1' family='poppins'>
                          {row.voucher.voucherName}
                        </Typography>
                      </Button>
                    </VoucherDetailsEntry>
                  </TableCell>

                  <TableCell>{formatDateForUI(row.voucher.date, true) || '---'}</TableCell>
                  <TableCell>{row.voucher.amountPaid}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )}
  </>
)

export default SpendUp
