import Proptypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '../Typography'
import Link from '../Link'

const checkForLink = item => {
  if (item.includes('[') && item.includes(']') && item.includes('(') && item.includes(')')) {
    return true
  }
  return false
}

const parseLinks = inputText => {
  const parsedChunks = []

  // Define the regular expression pattern
  const pattern = /(.*?)\[(.*?)\]\((.*?)\)|(.+?$)/g

  // Find all matches using the regular expression
  const matches = Array.from(inputText.matchAll(pattern))

  // Initialize an index variable to keep track of the current position in the input text
  let indexForOrder = 0

  // Iterate through the matches and create objects
  matches.forEach(match => {
    if (match[1] !== undefined) {
      // Matched text before a link
      const textBefore = match[1]
      if (textBefore) {
        parsedChunks.push({
          type: 'text',
          content: textBefore,
        })
        indexForOrder += textBefore.length
      }
    }
    if (match[2] !== undefined) {
      // Matched a link
      const linkText = match[2]
      const linkUrl = match[3]
      parsedChunks.push({
        type: 'link',
        content: linkText,
        link: linkUrl,
      })
      indexForOrder += linkText.length + 4 + linkUrl.length // Add 4 for the characters "[],()"
    }
    if (match[4] !== undefined) {
      // Matched text after the last link
      const textAfter = match[4]
      if (textAfter) {
        parsedChunks.push({
          type: 'text',
          content: textAfter,
        })
        indexForOrder += textAfter.length
      }
    }
  })
  return parsedChunks
}

const generateLinkJSX = (chunkArr, props) => {
  // take in array of objects with meta data
  return chunkArr.map((chunk, index) => {
    // if the chunk is text, return the text
    if (chunk.type === 'text') {
      return chunk.content
    }

    // if the chunk is a link, return the link
    if (chunk.type === 'link') {
      return (
        <Link
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          type='external'
          display='inline'
          target='_blank'
          rel='noopener'
          href={chunk.link}
          {...props}
        >
          {chunk.content}
        </Link>
      )
    }
    return null
  })
}

const jsxWrapper = (item, index, mt) => {
  return (
    <Box mt={mt} key={index}>
      {item}
    </Box>
  )
}

// Begin Element
const FormattedText = ({
  text = '',
  textType = 'body1',
  textWeight = 'light',
  mt = 2,
  ...props
}) => {
  // if the item is not a string, return nothing
  if (typeof text !== 'string') return <></>

  // if the item is a string, split it by the pipe character
  return text.split('|').map((item, index) => {
    // if the item is an empty string, return a line break
    // eslint-disable-next-line react/no-array-index-key
    if (item === '') return <br key={index} />

    let jsxContent = null

    // if the item is a string with a link, return a link
    if (checkForLink(item)) {
      // fallback to default text if there is an error parsing the links
      try {
        // split the block of text into an array of objects with meta data
        const parsedChunks = parseLinks(item)

        // Map the parsed chunks to an array of JSX elements
        const textWithLinks = generateLinkJSX(parsedChunks, props)

        // Filter out null values
        const filteredTextWithLinks = textWithLinks.filter(Boolean)

        jsxContent = (
          <Typography variant={textType} weight={textWeight} {...props}>
            {filteredTextWithLinks}
          </Typography>
        )
      } catch (error) {
        console.log('Error parsing links: ')
        console.log(error)
        jsxContent = (
          <Typography variant={textType} weight={textWeight} {...props}>
            {item}
          </Typography>
        )
      }
    } else {
      // if the item is a string without a link, return a paragraph
      jsxContent = (
        <Typography variant={textType} weight={textWeight} {...props}>
          {item}
        </Typography>
      )
    }

    // return the content wrapped in box
    return jsxContent ? jsxWrapper(jsxContent, index, mt) : <></>
  })
}
// End Element

FormattedText.propTypes = {
  text: Proptypes.string,
  textType: Proptypes.string,
  textWeight: Proptypes.string,
  mt: Proptypes.number,
}

export default FormattedText
