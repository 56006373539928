import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Container from '../stories/Container'
import Form from '@app/containers/SignUpForm'
import Typography from '../stories/Typography'
import Link from '../stories/Link'
import Section from '../stories/Section'

const SignUpPage = () => {
  useEffect(() => {
    // will not work because it only tracks logged in users
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Sign Up',
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Mishe | Shop Care, Bypass Insurance</title>
        <meta property='og:title' content='Mishe | Shop Care, Bypass Insurance' />
        <meta
          name='description'
          property='og:description'
          content='Bypass insurance and shop cash price care options from licensed clinicians with Mishe.'
        />
        <meta property='og:url' content='https://mishe.co/signup' />
        <link rel='canonical' href='https://mishe.co/signup' />
      </Helmet>
      <Container
        maxWidth='xs'
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Section
          sx={{
            width: { xs: '100%', sm: '80%', md: '60%' },
            mt: 4,
          }}
        >
          <Typography variant='h2' gutterBottom>
            Create account
          </Typography>
          <Typography variant='body2' weight='light' color='inherit'>
            Already have an account?{' '}
            <Link to='/signin' display='standard'>
              Log in here.
            </Link>
          </Typography>
          <Form />
          <br />
        </Section>
      </Container>
    </>
  )
}

export default SignUpPage
