import { memo } from 'react'
import Box from '@mui/material/Box'
import Typography from '@app/stories/Typography'
import Button from '@app/stories/Button'
import GeneralModal from '@app/containers/GeneralModal'
import Icon from '@app/stories/Icons'
import CopyVoucher from '@app/components/CopyVoucher'
import VidCallButton from '@app/components/VidCallButton'
import EobButton from '@app/components/EobButton'

const VoucherFunctions = ({ voucher, isProvider }) => (
  <Box>
    <Box mt={2}>
      <Box marginTop='12px'>
        <VidCallButton voucher={voucher} />
      </Box>
      {isProvider && voucher?.customer?.idLink && (
        <Box mt={2}>
          <GeneralModal
            id='patientID'
            headerText='Patient Identification'
            maxWidth={'lg'}
            customButton={
              <Button variant='outlined' color='primary' size='small' style={{ boxShadow: 'none' }}>
                <Box display='flex' gap='8px' alignItems='center'>
                  <Icon name='pageViewOutlined' />
                  <Typography variant='body2' family='poppins'>
                    View Patient ID
                  </Typography>
                </Box>
              </Button>
            }
          >
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              style={{ maxWidth: '400px' }}
            >
              <img
                // make sure image fits in box
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  border: '3px solid #652d92',
                  borderRadius: '8px',
                }}
                src={voucher?.customer?.idLink}
                alt='PatientIdentification'
              />
            </Box>
          </GeneralModal>
        </Box>
      )}
      <Box marginTop='12px'>
        <CopyVoucher voucher={voucher} label='Copy Voucher Link' />
      </Box>
      <Box marginTop='12px'>
        <EobButton voucher={voucher} label='Explanation of Benefits' />
      </Box>
    </Box>
  </Box>
)

export default memo(VoucherFunctions)
