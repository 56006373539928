import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiChip from '@mui/material/Chip'

const StyledChip = styled(MuiChip)`
  min-width: ${props => props.theme.spacing(10)};
  margin: 0px ${props => props.theme.spacing(3)};
  padding: ${props => props.theme.spacing(1.25)};
  ${props =>
    props.type === 'info' &&
    css`
      color: #fff;
      font-weight: bold;
      background-color: ${props.theme.palette.info.dark};
      border-color: ${props.theme.palette.info.dark};
    `}
  ${props =>
    props.type === 'error' &&
    css`
      color: #fff;
      font-weight: bold;
      background-color: ${props.theme.palette.error.dark};
      border-color: ${props.theme.palette.error.dark};
    `}
  ${props =>
    props.type === 'success' &&
    css`
      color: #fff;
      font-weight: bold;
      background-color: ${props.theme.palette.success.dark};
      border-color: ${props.theme.palette.success.dark};
    `}
  ${props =>
    props.type === 'bundle' &&
    css`
      color: #fff;
      font-weight: bold;
      background-color: #652d92;
      border-color: #652d92;
    `}
  ${props =>
    props.type === 'external' &&
    css`
      color: #fff;
      font-weight: bold;
      background-color: ${props.theme.palette.success.dark};
      border-color: ${props.theme.palette.success.dark};
    `}
  ${props =>
    props.redeemedbundle === true &&
    css`
      color: #fff;
      font-weight: bold;
      background: linear-gradient(
        to right,
        #7004c6 0%,
        #7004c6 41.5%,
        ${props.theme.palette.success.dark} 41.5%,
        ${props.theme.palette.success.dark} 100%
      );
      border-color: #7004c6;
    `}
`

const Chip = ({ label, type = '', ...props }) => {
  const { redeemedbundle } = props || { redeemedbundle: false }

  const chipLabel = redeemedbundle ? `bundle redeemed` : label
  return <StyledChip label={chipLabel} type={type} variant='outlined' size='small' {...props} />
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  redeemedbundle: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default Chip
