import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import GeneralModal from '@app/containers/GeneralModal'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material'

const EobButton = ({ voucher, label }) => {
  const { financials, customer, service, organization } = voucher

  console.log(voucher)
  console.log(financials)
  console.log(customer)
  console.log(organization)
  console.log(service)

  if (!financials) {
    return <></>
  }

  return (
    <GeneralModal
      id='explanationOfBenefits'
      headerText='Explanation of Benefits'
      maxWidth={'lg'}
      customButton={
        <Button
          variant='outlined'
          size='small'
          style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        >
          <Icon name='assignmentOutlined' />
          <Typography variant='body2' style={{ marginLeft: '8px' }}>
            {label}
          </Typography>
        </Button>
      }
    >
      <Box display='flex' justifyContent='center' alignItems='center'>
        <Box p={2}>
          <Box display='flex' justifyContent='space-between' alignItems='flex-start' pb={2}>
            <Box>
              <Typography variant='h6' fontWeight='bold' style={{ textTransform: 'capitalize' }}>
                Patient's Name:{' '}
                {`${customer?.firstname || ''} ${customer?.lastname || ''}`.trim() || '---'}
              </Typography>
              {/* <Typography variant='h6' fontWeight='bold'>
                Patient Acct. Number: {customer?.user_id || '---'}
              </Typography> */}
              <Typography variant='h6' fontWeight='bold'>
                Plan Paid:{' '}
                <Typography variant='h6' component='span' color='error' fontWeight='bold'>
                  {financials?.amount_paid || 0}
                </Typography>
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                Patient Paid:{' '}
                <Typography variant='h6' component='span' color='error' fontWeight='bold'>
                  {financials?.out_of_pocket || 0}
                </Typography>
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                To: {organization?.name || '---'}
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                It is not your responsibility to pay:{' '}
                <Typography variant='h6' component='span' color='error' fontWeight='bold'>
                  {financials?.original_charge - financials?.allowed_amount || 0}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Typography variant='h6' fontWeight='bold'>
                Claim Number: {voucher?.DOCUMENT_NUM || voucher?.id || '---'}
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                Claim Processed Date:{' '}
                {financials?.paid_date ||
                  voucher?.createdAt?.toDate()?.toLocaleDateString() ||
                  '---'}
              </Typography>
              {/* <Typography variant='h6' fontWeight='bold'>
            Sequence Number: ''
            </Typography> */}
              <Typography variant='h6' fontWeight='bold'>
                Provider of Services: {organization?.name || '---'}
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                Place of Service:{' '}
                {voucher?.point_of_service_description ||
                  voucher?.place_of_service_description ||
                  '---'}
              </Typography>
            </Box>
          </Box>

          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>DATE</TableCell>
                  <TableCell>TREATMENT</TableCell>
                  <TableCell>BILLED</TableCell>
                  <TableCell>SAVINGS</TableCell>
                  <TableCell>CONTRACTED</TableCell>
                  <TableCell>INELIGIBLE</TableCell>
                  <TableCell>PLAN PAID</TableCell>
                  <TableCell>DEDUC</TableCell>
                  <TableCell>COINS</TableCell>
                  <TableCell>COPAY</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {service?.date_of_service_initial ||
                      voucher?.createdAt?.toDate()?.toLocaleDateString() ||
                      ''}
                  </TableCell>
                  <TableCell>{service?.name || 0}</TableCell>
                  <TableCell>{financials?.original_charge || 0}</TableCell>
                  <TableCell>{financials?.original_charge - financials?.allowed_amount}</TableCell>
                  <TableCell>{financials?.allowed_amount || 0}</TableCell>
                  <TableCell>{financials?.ineligible_amount || 0}</TableCell>
                  <TableCell>{financials?.amount_paid || 0}</TableCell>
                  <TableCell>{financials?.deductible || 0}</TableCell>
                  <TableCell>{financials?.coinsurance || 0}</TableCell>
                  <TableCell>{financials?.copay || 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>CLAIM TOTALS</TableCell>
                  <TableCell>{financials?.original_charge || 0}</TableCell>
                  <TableCell>{financials?.original_charge - financials?.allowed_amount}</TableCell>
                  <TableCell>{financials?.allowed_amount || 0}</TableCell>
                  <TableCell>{financials?.ineligible_amount || 0}</TableCell>
                  <TableCell>{financials?.amount_paid || 0}</TableCell>
                  <TableCell>{financials?.deductible || 0}</TableCell>
                  <TableCell>{financials?.coinsurance || 0}</TableCell>
                  <TableCell>{financials?.copay || 0}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </GeneralModal>
  )
}

EobButton.propTypes = {
  voucher: PropTypes.object.isRequired,
  label: PropTypes.string,
}

export default EobButton
