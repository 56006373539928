import { useState } from 'react'
import { query, where, getDocs, and, or, orderBy, limit } from 'firebase/firestore'
import { useCurrentProfile } from '@app/context/CurrentUser'
import { subCollectionModular } from '@app/firebase/firestore'

const usePayouts = () => {
  const [payoutData, setPayoutData] = useState([])
  const [payoutsLoading, setPayoutsLoading] = useState(null)
  const { organizationId } = useCurrentProfile()

  const fetchPayouts = async () => {
    try {
      let limitRows = 100

      console.log('organizationId', organizationId)
      const constructQuery = []

      let constructQueryOrder = [orderBy('time', 'desc'), limit(limitRows)]

      console.log('constructQuery', constructQuery)

      const payoutQuery = query(
        subCollectionModular({
          docName: 'organizations',
          docId: organizationId,
          subDocName: 'payouts',
        }),
        ...constructQuery,
        // ...constructQueryOrder,
      )

      const querySnapshotPayoutDoc = await getDocs(payoutQuery)

      let payoutLoop = 0
      const payoutData = []
      const totalPayouts = querySnapshotPayoutDoc.docs.length
      console.log('querySnapshotPayoutDoc', querySnapshotPayoutDoc)

      console.log('totalPayouts--', totalPayouts)

      if (querySnapshotPayoutDoc.empty === false) {
        for await (let payoutDoc of querySnapshotPayoutDoc.docs) {
          payoutLoop++

          payoutData.push({
            ...payoutDoc.data(),
            id: payoutDoc.id,
            uid: payoutDoc.id,
          })
          console.log('totalPayouts,', totalPayouts, payoutLoop)

          if (payoutLoop === totalPayouts) {
            setPayoutsLoading(false)
            setPayoutData(payoutData)
          }
        }
      }
    } catch (error) {
      console.log('error fetching organization payouts')
      console.log(error)
      setPayoutsLoading(false)
    }
  }

  console.log('payoutData', payoutData)

  return {
    fetchPayouts,
    payoutData,
    payoutsLoading,
  }
}

export default usePayouts
