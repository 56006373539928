import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Hidden } from '@mui/material'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import Link from '../stories/Link'
import NavLink from '../stories/NavLink'
import Button from '../stories/Button'

import CollapsableListItem from '@app/components/CollapsableListItem'
import HowItWorks from '@app/components/HowItWorksMini'
import MisheFlow from '@app/components/MisheFlow'

import ConciergeHero from '../assets/images/concierge-hero.png'
import MisheLiveChat from '../assets/images/mishe-live-chat.png'

const SupportPage = () => {
  const { search } = useLocation()
  const [currentIndex, setCurrentIndex] = useState('')
  const ref = new URLSearchParams(search).get('ref')

  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'SupportPage',
    })
  }, [])

  const openChatBox = () => {
    window.HubSpotConversations.widget.open()
  }

  const onChangeCollapse = e => {
    setCurrentIndex(e)
  }

  return (
    <>
      <Helmet>
        <title>Have Questions? mishe’s Concierge Team is Ready with Answers.</title>
        <meta
          property='og:title'
          content='Have Questions? mishe’s Concierge Team is Ready with Answers.'
        />
        <meta
          name='description'
          property='og:description'
          content='We want to simplify healthcare and are here to serve you. Our team will help you answer questions, navigate available options, and schedule appointments.'
        />
        <meta property='og:url' content='https://mishe.co/support' />
        <link rel='canonical' href='https://mishe.co/support' />
      </Helmet>
      <Container maxWidth='lg'>
        <Section>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Hidden mdDown>
                <Typography variant='h1' gutterBottom>
                  mishe{' '}
                  <Box component='span' color='primary.main'>
                    Concierge
                  </Box>
                </Typography>
                <Typography variant='body1' weight='light' style={{ maxWidth: '460px' }}>
                  We want to simplify healthcare and are here to serve you. Our team will help you
                  answer questions, navigate available options, and schedule appointments. Think of
                  us as your live virtual assistant – a bridge between you and your care. Reach us
                  by phone, text, email or chat!
                </Typography>
                <Box mt={4}>
                  <NavLink
                    type='button'
                    display='standard'
                    color='primary'
                    family='poppins'
                    onClick={() => {
                      openChatBox()
                    }}
                  >
                    Talk to a concierge{' '}
                    <ArrowForwardIcon
                      style={{
                        color: '#662D91',
                        fontSize: '18px',
                        marginLeft: 10,
                      }}
                    />
                  </NavLink>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Typography variant='h1' gutterBottom align='center'>
                  mishe{' '}
                  <Box component='span' color='primary.main'>
                    Concierge
                  </Box>
                </Typography>
                <Typography variant='body1' weight='light' align='center'>
                  We understand that buying healthcare online can be a new and confusing process.
                  This is why our concierge team is ready to answer your questions. Chat, call or
                  text the team for assistance. Get help navigating available care options and
                  answering questions. Leverage the concierge team for help scheduling appointments.
                  We’re just a text away.
                </Typography>
                <Box mt={4} textAlign='center'>
                  <NavLink
                    type='button'
                    onClick={() => {
                      openChatBox()
                    }}
                    display='primary'
                  >
                    Talk to a concierge{' '}
                    <ArrowForwardIcon
                      style={{
                        color: '#662D91',
                        fontSize: '18px',
                        marginLeft: 10,
                      }}
                    />
                  </NavLink>
                </Box>
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} />
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img src={ConciergeHero} alt='ConciergeHero' width='100%' />
            </Grid>
          </Grid>
        </Section>
        <Section>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Typography variant='h2' gutterBottom style={{ maxWidth: '430px' }}>
                Quickly{' '}
                <Box component='span' color='primary.main'>
                  Live Chat{' '}
                </Box>
                with the mishe Team
              </Typography>
              <Typography variant='body1' weight='light' style={{ maxWidth: '430px' }}>
                We understand that buying healthcare online can be a new and confusing process. This
                is why our concierge team is ready to answer your questions.
              </Typography>
              <br /> <br />
              <Box mb={3}>
                <Typography
                  variant='body2'
                  gutterBottom
                  weight='light'
                  style={{ display: 'flex', alignItems: ' center' }}
                >
                  <CheckCircleIcon style={{ color: '#662D91' }} />
                  <span style={{ marginLeft: 20 }}>
                    Chat, call or text the team for assistance.
                  </span>
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography
                  variant='body2'
                  gutterBottom
                  weight='light'
                  style={{ display: 'flex', alignItems: ' center' }}
                >
                  <CheckCircleIcon style={{ color: '#662D91' }} />
                  <span style={{ marginLeft: 20 }}>
                    Get help navigating available care options and answering questions.
                  </span>
                </Typography>
              </Box>
              <Typography
                variant='body2'
                gutterBottom
                weight='light'
                style={{ display: 'flex', alignItems: ' center' }}
              >
                <CheckCircleIcon style={{ color: '#662D91' }} />
                <span style={{ marginLeft: 20 }}>
                  Leverage the concierge team for help scheduling appointments. We’re just a text
                  away.
                </span>
              </Typography>
              <Box mt={4} mb={1}>
                <Button
                  type='button'
                  size='medium'
                  onClick={() => {
                    openChatBox()
                  }}
                >
                  Live chat with concierge
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} />
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <img src={MisheLiveChat} alt='MisheLiveChat' width='100%' />
            </Grid>
          </Grid>
        </Section>
        <MisheFlow />
        <Section>
          <Typography variant='h2' align='center'>
            Frequently Asked{' '}
            <Box component='span' color='primary.main'>
              Questions
            </Box>
          </Typography>
          <List disablePadding value={currentIndex}>
            <CollapsableListItem
              key='question1'
              value='1'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>How do I get in contact with mishe ?</span>}
            >
              <Typography variant='body2' weight='light'>
                Contact us at{' '}
                <Link type='external' display='inline' to='mailto:help@mishe.co'>
                  help@mishe.co
                </Link>{' '}
                or{' '}
                <Link type='external' display='inline' to='tel:+1 (601) 647–4326'>
                  1-855-MISHECO
                </Link>{' '}
                (
                <Link type='external' display='inline' size='small' to='tel:+1 (601) 647–4326'>
                  1-855-647-4326
                </Link>
                ).
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question2'
              value='2'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>What is mishe ?</span>}
            >
              <Typography variant='body2' weight='light'>
                Mishe bypasses insurance and connects consumers directly with local licensed
                providers at their transparent cash prices. The marketplace is the first of mishe’s
                omnichannel engagement tools developed to help consumers and providers take back
                healthcare via transparency, equity and access for all.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question3'
              value='3'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>How does mishe work?</span>}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={9} md={5}>
                  <HowItWorks />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box mt={{ xs: 2, md: 0 }}>
                    <Typography variant='body2' weight='light'>
                      <b>1. Search and compare prices.</b>
                      <br />
                      Search or filter by service, description, specialty, organization, provider,
                      location or price.
                    </Typography>
                    <br />
                    <Typography variant='body2' weight='light'>
                      <b>2. Purchase care.</b>
                      <br />
                      Pay for care online to lock in the mishe price.
                      <br />
                      Access your dashboard to view scheduling or walk-in instructions and your
                      voucher code (used as payment method).
                    </Typography>
                    <br />
                    <Typography variant='body2' weight='light'>
                      <b>3. Schedule service.</b>
                      <br />
                      Contact your provider to schedule service (if you’re not contacted first).
                      <br />
                      Follow the scheduling or walk-in instructions listed in your dashboard.
                    </Typography>
                    <br />
                    <Typography variant='body2' weight='light'>
                      <b>4. See your provider.</b>
                      <br />
                      Present your voucher code during check-in to redeem care.
                    </Typography>
                    <br />
                    <NavLink to='/search' bgcolor='primary'>
                      Search for Affordable Healthcare
                    </NavLink>
                  </Box>
                </Grid>
              </Grid>
            </CollapsableListItem>
            <CollapsableListItem
              key='question4'
              icon='question'
              value='4'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Who does mishe serve?</span>}
            >
              <Typography variant='body2' weight='light'>
                Mishe provides access to care for everyone.
                <br />
                Whether you are uninsured, underinsured, a freelancer, a gig economy worker, a
                part-time worker, an expat, undocumented, traveling, sticking to a budget, in need
                of care not covered by your insurance plan, in between jobs, have unused HSA/FSA
                funds, or you have conventional insurance coverage and are in need of a
                straightforward shopping experience, mishe helps all consumers eliminate financial
                unknowns and burdens associated with healthcare consumption.
                <br />
                <br />
                <b>
                  If you need emergency care, please call 911 or go to your nearest emergency room.
                </b>
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question5'
              icon='question'
              value='5'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Does mishe cost anything for consumers?</span>}
            >
              <Typography variant='body2' weight='light'>
                No, there is no cost to consumers who use mishe.
                <br />
                Consumers only pay for the services they choose and nothing more. We collect a
                nominal subscription fee from providers to help fund our mission to increase access
                to affordable care.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question6'
              icon='question'
              value='6'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Are providers on mishe licensed?</span>}
            >
              <Typography variant='body2' weight='light'>
                They sure are! All providers are screened for active licensure and disciplinary
                history.{' '}
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question7'
              icon='question'
              value='7'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Is my information safe?</span>}
            >
              <Typography variant='body2' weight='light'>
                Data privacy is incredibly important to your friends at mishe. We do everything we
                can to maximize confidentiality. mishe’s multi-layer security features ensure
                privacy and discretion while protecting consumer and provider information. We have
                implemented administrative, physical and technical safeguards across our encrypted
                ecommerce marketplace and infrastructure to ensure HIPAA compliance and security.
                <br />
                <br />
                Contact us at{' '}
                <Link type='external' display='inline' to='mailto:help@mishe.co'>
                  help@mishe.co
                </Link>{' '}
                or{' '}
                <Link type='external' display='inline' to='tel:+1 (601) 647–4326'>
                  1-855-MISHECO
                </Link>{' '}
                (
                <Link type='external' display='inline' size='small' to='tel:+1 (601) 647–4326'>
                  1-855-647-4326
                </Link>
                ) to request access to or deletion of your data.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question8'
              icon='question'
              value='8'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Why are mishe prices so affordable?</span>}
            >
              <Typography variant='body2' weight='light'>
                mishe helps providers market their cash prices and accept payment directly from
                consumers, which eliminates expensive billing costs. Providers receive 100% of their
                earnings, which guarantees most competitive price for consumers. By cutting out the
                waste, mishe leaves both consumers and providers with more money in their pockets.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question9'
              icon='question'
              value='9'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Can I use mishe if I have insurance?</span>}
            >
              <Typography variant='body2' weight='light'>
                Yes, consumers with commercial insurance or Medicaid may use mishe to purchase
                healthcare services. However, to preserve the right to access care at the cash
                price, both consumers and providers are prohibited from filing insurance claims for
                services purchased on mishe. Per the Balanced Budget Act of 1997, Medicare
                beneficiaries may only purchase services on mishe’s marketplace that are not covered
                by Medicare.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question10'
              icon='question'
              value='10'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Does mishe carry the same restrictions as insurance coverage?</span>}
            >
              <Typography variant='body2' weight='light'>
                With mishe, there are no provider network restrictions, no coverage restrictions, no
                authorizations, no denials, no questionnaires, no surprise bills, no deductibles, no
                coinsurance, no co-pays, and no premiums. What you pay for services chosen is all
                you pay, so you keep savings from care you don’t use.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question12'
              icon='question'
              value='12'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>How can I list my provider organization or facility?</span>}
            >
              <Typography variant='body2' weight='light'>
                Contact us at{' '}
                <Link type='external' display='inline' to='mailto:help@mishe.co'>
                  help@mishe.co
                </Link>{' '}
                or{' '}
                <Link type='external' display='inline' to='tel:+1 (601) 647–4326'>
                  1-855-MISHECO
                </Link>{' '}
                (
                <Link type='external' display='inline' size='small' to='tel:+1 (601) 647–4326'>
                  1-855-647-4326
                </Link>
                ).
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question13'
              icon='question'
              value='13'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Can I use my FSA/HSA account to pay for care?</span>}
            >
              <Typography variant='body2' weight='light'>
                Yes! mishe processes payments via Stripe and was assigned an HSA/FSA-eligible
                Merchant Category Code (MCC) so credit card companies know services are directly
                related to medical needs.
              </Typography>
            </CollapsableListItem>
          </List>
        </Section>
      </Container>
    </>
  )
}

export default SupportPage
