// src/utils/userFormatterUtil.js
import firebase from 'firebase/compat/app'

import { formatDateForUI } from '../libs/formatters'
const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const getFullName = voucher => {
  const customer = voucher?.customer || {}
  return `${customer.firstname || '---'} ${customer.lastname || '---'}`
}

export const getVoucherType = voucher => {
  if (voucher.parentUid) return 'SubVoucher'
  if (voucher.bundleID) return 'Bundle Parent'
  if (!voucher.bundleID) return 'Individual Voucher'
  return 'Unknown Voucher'
}

export const getAddress = voucher => {
  const address = voucher?.customer?.address || {}
  return `${address.street || '---'} ${address.city || '---'} ${address.state || '---'} ${
    address.zip || '---'
  }`
}

export const getBirthday = voucher => {
  const customer = voucher?.customer || {}
  return formatDateForUI(customer?.dob || customer?.birthday) || '---'
}

export const getGender = voucher => {
  const customer = voucher?.customer || {}
  return customer.gender || '---'
}

export const getPhone = voucher => {
  const customer = voucher?.customer || {}
  return customer.phone || '---'
}

export const getVoucherAppDate = voucher => {
  const apptDate =
    voucher?.appointmentDate instanceof firebase.firestore.Timestamp
      ? voucher.appointmentDate.toDate().toLocaleString('en-US', dateFormatOptions)
      : voucher?.appointmentDate instanceof Date
        ? voucher.appointmentDate.toLocaleString('en-US', dateFormatOptions)
        : voucher?.appointmentDate || null
  return apptDate || 'Appointment Pending'
}
