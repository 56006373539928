import { useState, useEffect } from 'react'
import { collection } from '../firebase/firestore'

export const useEmployerPlan = (memberId, planId) => {
  const [planData, setPlanData] = useState(null)
  const [spendType, setSpendType] = useState(null)
  const [familyVouchers, setFamilyVouchers] = useState(null)
  const [memberType, setMemberType] = useState(null)
  const [spendRules, setSpendRules] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [empty, setEmpty] = useState(false)

  useEffect(() => {
    let isMounted = true // To handle potential component unmounts during async calls.

    const fetchData = async () => {
      try {
        let relevantPlanMembers = await collection('networks')
          .doc(planId)
          .collection('members')
          .where('memberId', '==', memberId)
          .get()
          .then(snapshot => {
            if (snapshot.empty) {
              return collection('networks')
                .doc(planId)
                .collection('members')
                .doc(memberId)
                .get()
                .then(doc => {
                  if (doc.exists) {
                    return {
                      empty: false,
                      docs: [doc],
                    }
                  }
                  return {
                    empty: true,
                  }
                })
            }
            return snapshot
          })

        if (relevantPlanMembers?.empty) {
          setEmpty(true)
          setLoaded(true)
        } else {
          const firstMemberData = relevantPlanMembers.docs[0].data()

          console.log('firstMemberData', firstMemberData)

          if (!firstMemberData) {
            console.log('Member data not found.')
            setEmpty(true)
            setLoaded(true)
          }

          if (firstMemberData.type === 1) {
            const familyPlanId = firstMemberData.familyPlanId
            relevantPlanMembers = await collection('networks')
              .doc(planId)
              .collection('members')
              .where('familyPlanId', '==', familyPlanId)
              .get()
          }

          // TODO ADD FAMILY ID TO VOUCHERS

          const vouchersPerPerson = relevantPlanMembers.docs.map(doc => ({
            type: doc.data()?.relation || 'Subscriber',
            name:
              doc.data()?.name ||
              `${doc.data()?.firstname || ''} ${doc.data()?.lastname || ''}`.trim(),
            vouchers: doc.data()?.vouchersSpentOn || [],
          }))

          const planRef = await collection('networks').doc(planId).get()
          const fetchedPlanData = planRef.data()

          if (!fetchedPlanData) {
            console.log('Plan data not found.')
            throw new Error('Plan data not found.')
          }

          const spendType = fetchedPlanData?.planDesign?.designType?.spend || null

          // Set Spend Information
          let spendDownRules = null
          if (spendType === 'down') {
            // if a family exists apply spend rules from the family
            const familyPlanId = firstMemberData?.familyPlanId

            let familyData = null

            if (familyPlanId) {
              familyData = await collection('networks')
                .doc(planId)
                .collection('families')
                .doc(familyPlanId)
                .get()
            }

            if (familyData?.exists && familyData?.data()?.spendRules?.sharedPool) {
              const { spendRules } = familyData.data()
              spendDownRules = {
                maxSpend: spendRules?.maxSpend || spendRules?.limit || 0,
                spent: spendRules?.spent || 0,
              }
            } else {
              const { spendRules } = firstMemberData
              spendDownRules = {
                maxSpend: spendRules?.maxSpend || spendRules?.limit || 0,
                spent: spendRules?.spent || 0,
              }
            }
          }

          if (isMounted) {
            setPlanData(fetchedPlanData)
            setSpendType(spendType)
            setMemberType(firstMemberData.type)
            setSpendRules(spendDownRules)
            setFamilyVouchers(vouchersPerPerson)
            setEmpty(false)
            setLoaded(true)
          }
        }
      } catch (error) {
        setEmpty(true)
        setLoaded(true)
        console.error('Failed to fetch plan data:', error)
      }
    }

    if (planId) {
      fetchData()
    }

    // Cleanup function
    return () => {
      isMounted = false // Prevent state updates if the component unmounts.
    }
  }, [memberId, planId])

  const familyBreakdown = () => {
    const output = []
    if (memberType === 1) {
      familyVouchers?.forEach(familyMember => {
        if (familyMember.type !== 1) {
          const vouchers = familyMember.vouchers.map(voucher => ({
            name: familyMember.name,
            type: familyMember.type,
            voucher,
          }))
          output.push(...vouchers)
        }
      })
    }
    return output
  }

  const totalFamilySpend = () => {
    return (
      familyVouchers?.reduce((total, member) => {
        const vouchersSpend = member.vouchers.reduce((sum, service) => sum + service.amountPaid, 0)
        return total + vouchersSpend
      }, 0) || 0
    )
  }

  const deductible = () => {
    const totalPaidTowardDeductible =
      familyVouchers?.reduce((total, member) => {
        return total + member.vouchers.reduce((sum, voucher) => sum + voucher.amountPaid, 0)
      }, 0) || 0

    const totalDeductible = planData?.planDesign?.deductible?.family || 0
    const remainingDeductible = totalDeductible - totalPaidTowardDeductible

    return {
      remaining: remainingDeductible,
      total: totalDeductible,
      spent: totalPaidTowardDeductible,
    }
  }

  const oop = () => {
    const totalPaidTowardOOP =
      familyVouchers?.reduce((total, member) => {
        return total + member.vouchers.reduce((sum, voucher) => sum + voucher.amountPaid, 0)
      }, 0) || 0

    const totalOOPMax = planData?.planDesign?.outOfPocketMax?.family || 0
    const remainingOOP = Math.max(0, totalOOPMax - totalPaidTowardOOP)

    return { remaining: remainingOOP, total: totalOOPMax, spent: totalPaidTowardOOP }
  }

  const spend = () => {
    const totalSpent =
      spendRules?.spent ||
      familyVouchers?.reduce((total, member) => {
        return total + member.vouchers.reduce((sum, voucher) => sum + voucher.amountPaid, 0)
      }, 0) ||
      0

    const remainingBalance = spendRules?.maxSpend - (spendRules?.spent || totalSpent || 0) || 0

    return { remaining: remainingBalance, max: spendRules.maxSpend, spent: totalSpent }
  }

  return {
    loaded,
    empty,
    planData,
    spendType,
    familyVouchers,
    memberType,
    familyBreakdown,
    totalFamilySpend,
    deductible,
    oop,
    spend,
  }
}
