import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LanguageIcon from '@mui/icons-material/Language'
import EmailIcon from '@mui/icons-material/Email'
import { Hidden } from '@mui/material'
import ShareFallbackModal from './ShareFallback'
import Modal from '@app/containers/GeneralModal'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import Link from '../stories/Link'
import Avatar from './OrganizationAvatar'
import { scrollToHash } from '../libs/scroll'
import useProviders from '../hooks/useOrganizationProviders'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'
import FormattedText from '../stories/FormatFromDB'

const checkForSocials = socialObject => {
  try {
    if (typeof socialObject === 'object') {
      return Object.entries(socialObject).length > 0
    }
    return false
  } catch (e) {
    return false
  }
}

const OrganizationInfo = ({ primary, organization }) => {
  const { hash } = useLocation()
  const [providers] = useProviders(organization.uid)

  useEffect(() => {
    let timeout
    if (hash) {
      timeout = setTimeout(() => {
        // wait 0.3s before scrolling
        scrollToHash(hash)
      }, 200)
    }
    return () => {
      // clear timeout on re-render
      clearTimeout(timeout)
    }
  })

  return (
    <>
      <Section>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={2}>
              <Box margin='auto' display='flex' justifyContent='center'>
                <Avatar src={organization.image} alt={organization.name} size='large'>
                  {organization.name}
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Hidden mdDown>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={10} md={10}>
                    <Typography variant='h1' gutterBottom>
                      {organization.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Box justifyContent='flex-end'>
                      <ShareFallbackModal />
                    </Box>
                  </Grid>
                </Grid>
                <FormattedText text={organization.description} />
              </Hidden>
              <Hidden mdUp>
                <Typography variant='h1' align='center'>
                  {organization.name}
                  <ShareFallbackModal />
                </Typography>
                {/* Mobile Socials */}
                <Box width='100%' textAlign='center'>
                  {checkForSocials(organization?.socialMediaLinks) && (
                    <Box display='flex' textAlign='center' justifyContent='center'>
                      <Box textAlign='center' justifyContent='center'>
                        <Grid container spacing={1}>
                          {organization.socialMediaLinks.ig && (
                            <IconButton
                              target='_blank'
                              href={organization.socialMediaLinks.ig}
                              size='large'
                            >
                              <Icon name='instagram' color='primary' />
                            </IconButton>
                          )}
                          {organization.socialMediaLinks.fb && (
                            <IconButton
                              target='_blank'
                              href={organization.socialMediaLinks.fb}
                              size='large'
                            >
                              <Icon name='facebook' color='primary' />
                            </IconButton>
                          )}
                          {organization.socialMediaLinks.li && (
                            <IconButton
                              target='_blank'
                              href={organization.socialMediaLinks.li}
                              size='large'
                            >
                              <Icon name='linkedin' color='primary' />
                            </IconButton>
                          )}
                          {organization.socialMediaLinks.tw && (
                            <IconButton
                              target='_blank'
                              href={organization.socialMediaLinks.tw}
                              size='large'
                            >
                              <Icon name='twitter' color='primary' />
                            </IconButton>
                          )}
                          {organization.socialMediaLinks.tt && (
                            <IconButton
                              target='_blank'
                              href={organization.socialMediaLinks.tt}
                              size='large'
                            >
                              <Icon name='tiktok' color='primary' />
                            </IconButton>
                          )}
                          {organization.socialMediaLinks.yt && (
                            <IconButton
                              target='_blank'
                              href={organization.socialMediaLinks.yt}
                              size='large'
                            >
                              <Icon name='youtube' color='primary' />
                            </IconButton>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  )}
                </Box>
                <FormattedText text={organization.description} />
              </Hidden>
            </Grid>
            {/* Desktop Info */}
            <Hidden mdDown>
              <Grid item xs={12} sm={12} md={4}>
                {/* Desktop Primary Location */}
                {!organization.orgVirtual && (
                  <Box mb={4} display='flex'>
                    <Link
                      display='standalone'
                      type='external'
                      target='_blank'
                      align='center'
                      to={`http://maps.google.com/?q=${primary.address}, ${primary.city}, ${primary.state}, ${primary.zipcode}`}
                    >
                      <LocationOnIcon style={{ color: '#662D91' }} />
                    </Link>
                    <Box ml={1}>
                      <Typography variant='caption' weight='semibold'>
                        <Box component='span' color='primary.main'>
                          PRIMARY LOCATION
                        </Box>
                      </Typography>
                      <br />
                      <Typography variant='body1' weight='light'>
                        {primary.name}
                      </Typography>
                      <Typography variant='body1' weight='light'>
                        {primary.address}
                      </Typography>
                      <Typography variant='body1' weight='light'>
                        {primary.city}, {primary.state} {primary.zipcode}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {/* Desktop Licensed States */}
                {organization.licensedStates && organization.licensedStates.length > 0 && (
                  <Box mb={4} display='flex'>
                    <CheckCircleOutlineIcon style={{ color: '#662D91' }} />
                    <Box ml={1}>
                      <Typography variant='caption' weight='semibold'>
                        <Box component='span' color='primary.main'>
                          LICENSED STATES
                        </Box>
                      </Typography>
                      <br />
                      <Grid container spacing={1}>
                        {organization.licensedStates.map(state => (
                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} key={state}>
                            {state}
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                )}
                {/* Desktop Socials */}
                {checkForSocials(organization?.socialMediaLinks) && (
                  <Box mb={4} display='flex'>
                    <Grid container spacing={1}>
                      {organization.socialMediaLinks.ig && (
                        <IconButton
                          target='_blank'
                          href={organization.socialMediaLinks.ig}
                          size='large'
                        >
                          <Icon name='instagram' color='primary' />
                        </IconButton>
                      )}
                      {organization.socialMediaLinks.fb && (
                        <IconButton
                          target='_blank'
                          href={organization.socialMediaLinks.fb}
                          size='large'
                        >
                          <Icon name='facebook' color='primary' />
                        </IconButton>
                      )}
                      {organization.socialMediaLinks.li && (
                        <IconButton
                          target='_blank'
                          href={organization.socialMediaLinks.li}
                          size='large'
                        >
                          <Icon name='linkedin' color='primary' />
                        </IconButton>
                      )}
                      {organization.socialMediaLinks.tw && (
                        <IconButton
                          target='_blank'
                          href={organization.socialMediaLinks.tw}
                          size='large'
                        >
                          <Icon name='twitter' color='primary' />
                        </IconButton>
                      )}
                      {organization.socialMediaLinks.tt && (
                        <IconButton
                          target='_blank'
                          href={organization.socialMediaLinks.tt}
                          size='large'
                        >
                          <Icon name='tiktok' color='primary' />
                        </IconButton>
                      )}
                      {organization.socialMediaLinks.yt && (
                        <IconButton
                          target='_blank'
                          href={organization.socialMediaLinks.yt}
                          size='large'
                        >
                          <Icon name='youtube' color='primary' />
                        </IconButton>
                      )}
                    </Grid>
                    {/* </Box> */}
                  </Box>
                )}
                {/* Desktop Contact Info */}
                <Box display='flex'>
                  <Box ml={1}>
                    <Grid container spacing={1} justifyContent='center'>
                      <Grid item md='auto'>
                        {organization.phone && (
                          <Link
                            display='standalone'
                            type='external'
                            target='_blank'
                            align='center'
                            to={`tel:${organization.phone}`}
                          >
                            <PhoneIphoneIcon fontSize='large' style={{ color: '#662D91' }} />
                          </Link>
                        )}
                      </Grid>
                      <Grid item md='auto'>
                        {organization.email && (
                          <Link
                            display='standalone'
                            type='external'
                            target='_blank'
                            align='center'
                            mr={3}
                            to={`mailto:${organization.email}`}
                          >
                            <EmailIcon fontSize='large' style={{ color: '#662D91' }} />
                          </Link>
                        )}
                      </Grid>
                      <Grid item md='auto'>
                        {organization.website && (
                          <Link
                            display='standalone'
                            type='external'
                            target='_blank'
                            align='center'
                            to={organization.website}
                          >
                            <LanguageIcon fontSize='large' style={{ color: '#662D91' }} />
                          </Link>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Hidden>
            {/* Mobile Info Section */}
            <Hidden mdUp>
              <Grid container display='flex' justifyContent='center'>
                {/* Mobile Location */}
                {!organization.orgVirtual && (
                  <Grid item xs={4} sm={4} md={4} textAlign='center'>
                    <Box mb={4} display='flex' justifyContent='center' textAlign='center'>
                      <Box textAlign='center'>
                        <Typography variant='caption' weight='semibold'>
                          <Box component='span' color='primary.main'>
                            PRIMARY LOCATION
                          </Box>
                        </Typography>
                        <br />
                        <Grid container spacing={1} justifyContent='center'>
                          <Link
                            display='standalone'
                            type='external'
                            target='_blank'
                            align='center'
                            to={`http://maps.google.com/?q=${primary.address}, ${primary.city}, ${primary.state}, ${primary.zipcode}`}
                          >
                            <LocationOnIcon fontSize='large' style={{ color: '#662D91' }} />
                          </Link>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                )}
                {/* Mobile Licensed States */}
                {organization.licensedStates && organization.licensedStates.length > 0 && (
                  <Grid
                    item
                    xs={!organization.orgVirtual ? 4 : 6}
                    sm={!organization.orgVirtual ? 4 : 6}
                    md={!organization.orgVirtual ? 4 : 6}
                    textAlign='center'
                  >
                    <Box display='flex' textAlign='center' justifyContent='center'>
                      <Box textAlign='center' justifyContent='center'>
                        <Typography variant='caption' weight='semibold'>
                          <Box component='span' color='primary.main'>
                            LICENSED STATES
                          </Box>
                        </Typography>
                        <br />
                        <Grid container spacing={1} justifyContent='center'>
                          {organization.licensedStates[0] && (
                            <Grid item xs={3} sm={3} key={organization.licensedStates[0]}>
                              {organization.licensedStates[0]}
                            </Grid>
                          )}
                          {organization.licensedStates[1] && (
                            <Grid item xs={3} sm={3} key={organization.licensedStates[1]}>
                              {organization.licensedStates[1]}
                            </Grid>
                          )}
                          {organization.licensedStates[2] && (
                            <Grid item xs={3} sm={3} key={organization.licensedStates[2]}>
                              {organization.licensedStates[2]}
                            </Grid>
                          )}
                          <Grid item xs='auto' sm='auto' key={organization.licensedStates[2]}>
                            <Modal headerText='Licensed States' buttonText='View All'>
                              <Grid container spacing={1} justifyContent='center'>
                                {organization.licensedStates.map(state => (
                                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2} key={state}>
                                    {state}
                                  </Grid>
                                ))}
                              </Grid>
                            </Modal>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                )}
                {/* Mobile Contact Info */}
                {(organization.phone || organization.email || organization.website) && (
                  <Grid
                    item
                    xs={!organization.orgVirtual ? 4 : 6}
                    sm={!organization.orgVirtual ? 4 : 6}
                    md={!organization.orgVirtual ? 4 : 6}
                    textAlign='center'
                  >
                    <Box display='flex' textAlign='center' justifyContent='center'>
                      <Box textAlign='center' justifyContent='center'>
                        <Typography variant='caption' weight='semibold'>
                          <Box component='span' color='primary.main'>
                            CONTACTS
                          </Box>
                        </Typography>
                        <br />
                        <Grid container spacing={1} justifyContent='center'>
                          {organization.phone && (
                            <Link
                              display='standalone'
                              type='external'
                              target='_blank'
                              align='center'
                              mr={3}
                              to={`tel:${organization.phone}`}
                            >
                              <PhoneIphoneIcon fontSize='large' style={{ color: '#662D91' }} />
                            </Link>
                          )}
                          {organization.email && (
                            <Link
                              display='standalone'
                              type='external'
                              target='_blank'
                              align='center'
                              mr={3}
                              to={`mailto:${organization.email}`}
                            >
                              <EmailIcon fontSize='large' style={{ color: '#662D91' }} />
                            </Link>
                          )}
                          {organization.website && (
                            <Link
                              display='standalone'
                              type='external'
                              target='_blank'
                              align='center'
                              to={organization.website}
                            >
                              <LanguageIcon fontSize='large' style={{ color: '#662D91' }} />
                            </Link>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container display='flex' justifyContent='center' spacing={1}>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <Box display='flex' justifyContent='center'>
                    {/* TODO Switch links to buttons */}
                    <Link
                      display='standard'
                      type='blank'
                      onClick={() => {
                        scrollToHash('#services')
                      }}
                      size='large'
                      style={{
                        textDecoration: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      Services
                    </Link>
                  </Box>
                </Grid>
                {!organization.orgVirtual && (
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Box display='flex' justifyContent='center'>
                      <Link
                        display='standard'
                        type='blank'
                        onClick={() => {
                          scrollToHash('#locations')
                        }}
                        size='large'
                        style={{
                          // faded purple
                          // backgroundColor: '#662D91',
                          // padding: '10px 20px',
                          // color: '#fff',
                          // borderRadius: '5px',
                          textDecoration: 'none',
                          fontWeight: 'bold',
                        }}
                      >
                        Locations
                      </Link>
                    </Box>
                  </Grid>
                )}
                {providers.length !== 0 && (
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Box display='flex' justifyContent='center'>
                      <Link
                        display='standard'
                        type='blank'
                        onClick={() => {
                          scrollToHash('#providers')
                        }}
                        size='large'
                        style={{
                          // faded purple
                          // backgroundColor: '#662D91',
                          // padding: '10px 20px',
                          // color: '#fff',
                          // borderRadius: '5px',
                          textDecoration: 'none',
                          fontWeight: 'bold',
                        }}
                      >
                        Providers
                      </Link>
                    </Box>
                  </Grid>
                )}
                {organization.images && (
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Box display='flex' justifyContent='center'>
                      <Link
                        display='standard'
                        type='blank'
                        onClick={() => {
                          scrollToHash('#photos')
                        }}
                        size='large'
                        style={{
                          // faded purple
                          // backgroundColor: '#662D91',
                          // padding: '10px 20px',
                          // color: '#fff',
                          // borderRadius: '5px',
                          textDecoration: 'none',
                          fontWeight: 'bold',
                        }}
                      >
                        Photos
                      </Link>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  )
}

OrganizationInfo.propTypes = {
  primary: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  organization: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    images: PropTypes.node,
    phone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    description: PropTypes.string,
    licensedStates: PropTypes.arrayOf(PropTypes.string),
    socialMediaLinks: PropTypes.shape({
      fb: PropTypes.string,
      tw: PropTypes.string,
      ig: PropTypes.string,
      li: PropTypes.string,
      yt: PropTypes.string,
      tt: PropTypes.string,
    }),
    orgVirtual: PropTypes.bool,
    review: PropTypes.shape({
      count: PropTypes.number,
      link: PropTypes.string,
    }),
  }).isRequired,
}

export default OrganizationInfo
