export const formatDollarAmount = amount => {
  return amount ? `$${amount?.toLocaleString()}` : ''
}

export const formatBenefit = benefit => {
  const { copay, coinsurance, deductible } = benefit || {}
  if (!copay && !deductible && !coinsurance) return '---'

  const parts = [
    copay && `${formatDollarAmount(copay)} Copay`,
    deductible && 'Deductible',
    coinsurance && `${coinsurance}%`,
  ].filter(Boolean)

  return parts.join(' + ')
}
