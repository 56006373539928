import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Hidden, Box, Divider } from '@mui/material'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Filter1Icon from '@mui/icons-material/Filter1'
import Filter2Icon from '@mui/icons-material/Filter2'
import Filter3Icon from '@mui/icons-material/Filter3'
import CheckIcon from '@mui/icons-material/Check'
import CircleIcon from '@mui/icons-material/FiberManualRecord'
import { PaymentMethodMessagingElement } from '@stripe/react-stripe-js'
import ReactStars from 'react-rating-stars-component'
import PricingDisclaimerModal from './PricingDisclaimerModal'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import { useCurrentNetwork } from '../context/CurrentNetwork'
import NetworkServiceCardDescription from '../stories/NetworkServiceCard'
import Button from '../stories/Button'
import Link from '../stories/Link'
import FAQ from '../stories/NetworkFAQs'
import GoogleMap from '@app/components/GoogleMap'
import FormattedText from '../stories/FormatFromDB'
import StripeElementsProvider from '@app/containers/StripeElementsProvider'
import InsuranceCardIcon from '../assets/images/insurance_card_no_bg.png'
import GeneralModal from '@app/containers/GeneralModal'
import SerifLogo from '../assets/images/serifLogo.png'
import TurquosieLogo from '../assets/images/turquosie_Logo.svg'
import { scrollToHash } from '../libs/scroll'
import CollapsableFormattedText from '../stories/CollapsableFormattedText'
import NetworkServiceLoader from '../stories/NetworkServiceLoader'
import Icon from '../stories/Icons'
import IconButton from '../stories/IconButton'

import { useMapEffects } from '../context/MapEffects'

const formatFaqData = faqObj => {
  if (Object.entries(faqObj).length === 0) return null
  return Object.entries(faqObj).sort((a, b) => {
    return a[0] - b[0]
  })
}

const formatInsurancePricing = insuranceRanges => {
  if (Object.entries(insuranceRanges).length === 0) return null
  return Object.entries(insuranceRanges).sort((a, b) => {
    return a[0] - b[0]
  })
}

const generateInsurancePricing = insuranceRanges => {
  const formattedInsuranceRanges = formatInsurancePricing(insuranceRanges)
  if (!formattedInsuranceRanges) return <></>
  return formattedInsuranceRanges.map(range => {
    return (
      <Box
        key={range[0]}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        {range[1].img && <img width='40px' src={range[1].img} alt={range[1].name} />}
        <Typography variant='body2' weight='light'>
          {range[1].name}
        </Typography>
        <Typography variant='body2' weight='light'>
          ${range[1].min.toLocaleString()} - ${range[1].max.toLocaleString()}
        </Typography>
      </Box>
    )
  })
}

const NetworkServiceInfoLander = ({ landerData = null, misheCode, network }) => {
  const navigate = useNavigate()
  const [serviceData, setServiceData] = useState(null)
  const [formattedFaqData, setFormattedFaqData] = useState(null)
  const { clinicLocations } = useCurrentNetwork()
  const { handleHover } = useMapEffects()
  const [onPlanOnly, setOnPlanOnly] = useState(false)

  const { specialtyLanderData } = landerData || {}

  const scrolledRef = useRef(false)
  const { hash } = window.location

  useEffect(() => {
    if (hash && !scrolledRef.current) {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        scrolledRef.current = true
      }
    }
  }, [hash])

  // add this to its own function
  const [specialtyTag, serviceTag] = misheCode.split('-')

  useEffect(() => {
    if (serviceData?.faq) {
      setFormattedFaqData(formatFaqData(serviceData.faq))
    }
  }, [serviceData])

  useEffect(() => {
    if (specialtyLanderData && specialtyTag && serviceTag) {
      const specialtyInfo = specialtyLanderData.find(
        specialty => specialty.specialtyTag === specialtyTag,
      )
      const serviceInfo = specialtyInfo?.services.find(service => service.serviceTag === serviceTag)

      // if there are no redirect to the home network page, this usually means there
      if (!serviceInfo) {
        console.log('--- please check the slug and mishe code ---')
        navigate(`/plans/${network}`)
      }
      setServiceData(serviceInfo)
    }
  }, [specialtyLanderData, specialtyTag, serviceTag, network, navigate])

  return (
    <>
      {/* helmet */}
      {serviceData?.metaTitle && (
        <Helmet>
          <title>{serviceData?.metaTitle}</title>
        </Helmet>
      )}
      <Section id='specialties' variant='noPadding' mt={1}>
        {(!landerData || !serviceData) && <NetworkServiceLoader />}
        {serviceData && (
          <Hidden mdDown>
            <Container>
              <Grid container spacing={3} display='flex'>
                <Grid item md={12} lg={12}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      marginTop: '-50px',
                    }}
                  >
                    {/* Desktop Book Consultation Buttons */}
                    <Box
                      mb={3}
                      width='100%'
                      style={{
                        position: 'sticky',
                        top: '120px',
                        zIndex: '1000',
                        borderRadius: '5px',
                        padding: '15px',
                      }}
                    >
                      <Box alignItems='center' width='100%'>
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                          <Button
                            onClick={() => {
                              window.open('https://meet.intercom.com/margie', '_blank')
                            }}
                            display='inline'
                            variant='contained'
                            size='small'
                            fullWidth
                            style={{
                              color: '#fff',
                              width: '33%',
                              padding: '5px',
                              borderRadius: '5px',
                              backgroundColor: '#662D91',
                              boxShadow: '1px 1px 5px 0px #662D91',
                              fontSize: '20px',
                              fontWeight: 'bold',
                            }}
                          >
                            <Typography
                              variant='h5'
                              family='poppins'
                              style={{ marginBottom: '0px', padding: '8px' }}
                            >
                              Schedule free consultation
                            </Typography>
                          </Button>
                          <Button
                            onClick={() => {
                              scrollToHash('#serviceCards')
                            }}
                            display='inline'
                            variant='outlined'
                            size='small'
                            fullWidth
                            style={{
                              width: '33%',
                              padding: '5px',
                              borderRadius: '5px',
                              boxShadow: '1px 1px 5px 0px #662D91',
                              fontSize: '20px',
                              fontWeight: 'bold',
                            }}
                          >
                            <Typography
                              variant='h5'
                              family='poppins'
                              style={{ marginBottom: '0px', padding: '8px' }}
                            >
                              Choose a Clinic
                            </Typography>
                          </Button>
                          <Button
                            display='inline'
                            variant='contained'
                            size='small'
                            fullWidth
                            style={{
                              padding: '5px',
                              width: '33%',
                              fontSize: '20px',
                              border: '1px solid #662D91',
                              borderRadius: '5px',
                              fontWeight: 'bold',
                            }}
                            className='open-live-chat'
                          >
                            <Typography
                              variant='h5'
                              family='poppins'
                              style={{ marginBottom: '0px', padding: '8px' }}
                            >
                              Chat with us now
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    {/* End of Desktop Book Consultation Buttons */}
                    <Box
                      // style={{
                      //   marginBottom: '20px'
                      // }}
                      style={{
                        position: 'fixed',
                        zIndex: '1000',
                        bottom: '20px',
                        left: '20px',
                      }}
                    >
                      <Button
                        variant='outlined'
                        onClick={() => {
                          window.location.href = `/plans/${network}`
                        }}
                        style={{
                          height: '48px',
                          width: '48px',
                          minWidth: '48px',
                          borderRadius: '24px',
                          fontSize: '32px',
                          padding: '8px',
                        }}
                      >
                        <Icon name='leftArrow' fontSize='inherit' />
                        {/* <Typography
                        variant='body1'
                        family='poppins'
                        style={{ marginBottom: '0px', padding: '4px' }}
                      >
                        {network}
                      </Typography> */}
                      </Button>
                    </Box>

                    {serviceData.serviceName && (
                      <Box>
                        <Typography variant='h2' color='textPrimary'>
                          {serviceData.serviceName}
                        </Typography>
                      </Box>
                    )}

                    {/* Desktop Description */}
                    {serviceData.description && (
                      <Box mb={3} width='100%'>
                        {/* <FormattedText text={serviceData.description} /> */}
                        <CollapsableFormattedText text={serviceData.description} />
                      </Box>
                    )}
                    {/* End of Desktop Description */}

                    {/* Desktop How Mishe works section */}
                    {!serviceData.hideHowMisheWorks && (
                      <Box mt={3} width='100%'>
                        <Grid
                          container
                          alignItems='center'
                          width='100%'
                          style={{
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                            borderRadius: '5px',
                            backgroundColor: serviceData.hiwBgColor || '#f5f5f5',
                            padding: '15px',
                          }}
                        >
                          <Typography variant='h4' color='textPrimary'>
                            How Mishe Works
                          </Typography>
                          <Box mt={2} width='100%' alignItems='center' display='flex'>
                            <Filter1Icon
                              fontSize='small'
                              style={{ color: '#303030', marginRight: '10px' }}
                            />
                            <Typography variant='body2' weight='light'>
                              We pre-negotiate prices for you
                            </Typography>
                          </Box>
                          <Box mt={2} width='100%' alignItems='center' display='flex'>
                            <Filter2Icon
                              fontSize='small'
                              style={{ color: '#303030', marginRight: '10px' }}
                            />
                            <Typography variant='body2' weight='light'>
                              You pre-pay for your care
                            </Typography>
                          </Box>
                          <Box mt={2} mb={2} width='100%' alignItems='center' display='flex'>
                            <Filter3Icon
                              fontSize='small'
                              style={{ color: '#303030', marginRight: '10px' }}
                            />
                            <Typography variant='body2' weight='light'>
                              We schedule your appointment for you
                            </Typography>
                          </Box>
                          <Box mt={1} width='100%'>
                            <Divider
                              style={{
                                marginBottom: '20px',
                              }}
                            />
                            <Typography variant='body2' weight='light' textalignment='justified'>
                              We&apos;re always available to answer your questions.
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                    {/* End of Desktop How Mishe works */}

                    {/* Desktop Care Options */}
                    {serviceData.relatedLinksJson &&
                      Object.keys(serviceData.relatedLinksJson).length !== 0 && (
                        <Box width='100%' mt={3}>
                          <Grid
                            container
                            style={{
                              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                              borderRadius: '5px',
                              padding: '15px',
                            }}
                          >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography variant='h4' color='textPrimary'>
                                Care Options
                              </Typography>
                            </Grid>
                            {Object.keys(serviceData.relatedLinksJson)
                              .filter(item => {
                                return serviceData.relatedLinksJson[item] !== null
                              })
                              .sort((a, b) => {
                                return b - a
                              })
                              .map(item => (
                                <Grid
                                  item
                                  md={2}
                                  lg={2}
                                  key={item}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Link
                                    to={serviceData.relatedLinksJson[item]?.link}
                                    align='center'
                                    type='external'
                                    style={{
                                      color: '#303030',
                                      margin: '10px',
                                    }}
                                  >
                                    <Typography variant='body1' weight='medium' align='center'>
                                      {serviceData.relatedLinksJson[item]?.text}
                                    </Typography>
                                  </Link>
                                </Grid>
                              ))}
                          </Grid>
                        </Box>
                      )}
                    {/* End of care options */}

                    {/* Desktop Checklist and Whats Included Container */}
                    <Box mt={3} width='100%'>
                      <Grid container width='100%' spacing={3}>
                        {/* Desktop Whats Included */}
                        {serviceData?.includedInService?.length !== 0 && (
                          <Grid
                            item
                            md={serviceData?.checklistForCare?.length !== 0 ? 6 : 12}
                            lg={serviceData?.checklistForCare?.length !== 0 ? 6 : 12}
                          >
                            <>
                              {Array.isArray(serviceData.includedInService) ? (
                                <Box width='100%'>
                                  <Grid
                                    container
                                    alignItems='center'
                                    width='100%'
                                    style={{
                                      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                                      borderRadius: '5px',
                                      padding: '15px',
                                      // backgroundColor: '#f5f5f5'
                                    }}
                                  >
                                    <Typography variant='h4' color='textPrimary'>
                                      What&apos;s included?
                                    </Typography>
                                    {serviceData.includedInService.map(item => (
                                      <Box
                                        mt={1}
                                        key={item}
                                        width='100%'
                                        alignItems='center'
                                        display='flex'
                                      >
                                        <CircleIcon
                                          fontSize='small'
                                          style={{
                                            color: '#303030',
                                            marginRight: '10px',
                                          }}
                                        />
                                        <Typography variant='body2' weight='light'>
                                          {item}
                                        </Typography>
                                      </Box>
                                    ))}
                                  </Grid>
                                </Box>
                              ) : (
                                <Box width='100%'>
                                  <Grid
                                    container
                                    alignItems='center'
                                    justifyContent='space-between'
                                    width='100%'
                                    style={{
                                      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                                      borderRadius: '5px',
                                      padding: '15px',
                                      // backgroundColor: '#f5f5f5'
                                    }}
                                  >
                                    <Box width='100%'>
                                      <Typography variant='h4' color='textPrimary'>
                                        What&apos;s included?
                                      </Typography>
                                    </Box>
                                    <Box width='100%' mt={2}>
                                      <FormattedText
                                        variant='body2'
                                        weight='light'
                                        text={serviceData.includedInService}
                                      />
                                    </Box>
                                  </Grid>
                                </Box>
                              )}
                            </>
                          </Grid>
                        )}
                        {/* End of Desktop Whats Included */}

                        {/* Desktop Checklist for care */}
                        {serviceData?.checklistForCare?.length !== 0 && (
                          <Grid
                            item
                            md={serviceData?.includedInService ? 6 : 12}
                            lg={serviceData?.includedInService ? 6 : 12}
                          >
                            <Box width='100%'>
                              <Grid
                                container
                                width='100%'
                                style={{
                                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                                  borderRadius: '5px',
                                  padding: '15px',
                                  // backgroundColor: '#f5f5f5'
                                }}
                              >
                                <Typography variant='h4' color='textPrimary'>
                                  Checklist for care
                                </Typography>{' '}
                                {serviceData.checklistForCare.map(item => (
                                  <Box
                                    mt={1}
                                    key={item}
                                    width='100%'
                                    alignItems='center'
                                    display='flex'
                                  >
                                    <CheckIcon
                                      fontSize='small'
                                      style={{
                                        color: '#303030',
                                        marginRight: '10px',
                                      }}
                                    />
                                    <Typography variant='body2' weight='light'>
                                      {item}
                                    </Typography>
                                  </Box>
                                ))}
                              </Grid>
                            </Box>
                          </Grid>
                        )}
                        {/* End of Desktop Checklist for care */}
                      </Grid>
                    </Box>
                    {/* End of Desktop Checklist and Whats Included Container */}

                    {/* Desktop Related Content */}
                    {Array.isArray(serviceData?.relatedLinks) &&
                      serviceData.relatedLinks.length !== 0 && (
                        <Box width='100%' mt={3}>
                          <Grid
                            container
                            style={{
                              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                              borderRadius: '5px',
                              padding: '15px',
                            }}
                          >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography variant='h4' color='textPrimary'>
                                Related Content
                              </Typography>
                            </Grid>
                            {serviceData.relatedLinks.map(item => (
                              <Grid
                                item
                                md={2}
                                lg={2}
                                key={item}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <FormattedText
                                  text={item}
                                  style={{
                                    margin: '10px',
                                  }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      )}
                    {/* End of Desktop Related Content */}

                    {/* Desktop FAQ */}
                    {formattedFaqData && (
                      <Box width='100%' mt={3}>
                        <Grid
                          container
                          alignItems='center'
                          width='100%'
                          style={{
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                            borderRadius: '5px',
                            padding: '15px',
                          }}
                        >
                          <Box width='100%'>
                            <Typography variant='h4' color='textPrimary'>
                              Frequently asked questions.
                            </Typography>
                          </Box>
                          <Box width='100%' mt={2}>
                            <FAQ data={formattedFaqData} />
                          </Box>
                        </Grid>
                      </Box>
                    )}
                    {/* End of Desktop FAQ */}
                  </Box>

                  {/* Desktop Visit Type */}
                  {serviceData.visitType && (
                    <Box mt={3} mb={3} width='100%'>
                      <Grid
                        container
                        alignItems='center'
                        justifyContent='space-between'
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          padding: '15px',
                        }}
                      >
                        <Typography variant='body2' color='textPrimary'>
                          Visit type
                        </Typography>
                        <Typography variant='body1' weight='light'>
                          {serviceData.visitType}
                        </Typography>
                      </Grid>
                    </Box>
                  )}
                  {/* End of Desktop Visit Type */}

                  {/* Desktop Total Procedure Price */}
                  {serviceData.totalProcedurePrice && (
                    <Box width='100%' mt={3}>
                      <Grid
                        container
                        justifyContent='space-between'
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          padding: '15px',
                        }}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <Typography variant='body2' color='textPrimary'>
                              Total Procedure Price Starting At:
                            </Typography>
                            <Typography variant='body1' weight='light'>
                              ${serviceData.totalProcedurePrice.toLocaleString()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box
                            style={{
                              marginTop: '10px',
                            }}
                          >
                            <StripeElementsProvider>
                              <PaymentMethodMessagingElement
                                options={{
                                  amount: serviceData.totalProcedurePrice * 100,
                                  currency: 'USD',
                                  paymentMethodTypes: ['klarna', 'affirm'],
                                  countryCode: 'US',
                                }}
                              />
                            </StripeElementsProvider>
                          </Box>
                          {/* Desktop Insurance Ranges */}
                          {serviceData.insuranceRanges &&
                            Object.entries(serviceData.insuranceRanges).length !== 0 && (
                              <Box display='block' alignItems='center' mt={2}>
                                <Box
                                  style={{
                                    marginTop: '0px',
                                  }}
                                >
                                  <Box>
                                    <img
                                      width='30px'
                                      src={InsuranceCardIcon}
                                      alt='Insurance Card'
                                    />
                                  </Box>
                                  <Box>
                                    <GeneralModal
                                      headerText='Insurance Pricing'
                                      customButton={
                                        <Button
                                          size='small'
                                          variant='text'
                                          customvariant='blank'
                                          style={{
                                            textDecoration: 'underline',
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            color: 'rgba(0,0,0,0.7)',
                                          }}
                                        >
                                          Check Insurance Pricing
                                        </Button>
                                      }
                                      customFooter={
                                        <Box>
                                          <Typography variant='body2' weight='light' align='center'>
                                            Powered by{' '}
                                            <img width='100px' src={SerifLogo} alt='Serif Logo' /> &{' '}
                                            <img
                                              width='100px'
                                              src={TurquosieLogo}
                                              alt='Turquosie Logo'
                                            />
                                          </Typography>
                                        </Box>
                                      }
                                    >
                                      {generateInsurancePricing(serviceData.insuranceRanges)}
                                    </GeneralModal>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {/* End of Desktop Total Procedure Price */}
                </Grid>

                {/* Desktop Service Cards Container */}
                <Grid item md={12} lg={12}>
                  {clinicLocations && clinicLocations?.length > 0 && (
                    <Grid container mt={2} width='100%' id='serviceCards' spacing={2}>
                      {/* <Grid item md={12} lg={12}>
                      <Box
                        mt={2}
                        width='100%'
                        style={{
                          padding: '15px',
                          borderRadius: '5px',
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)'
                        }}
                      >
                        <Typography
                          variant='h4'
                          color='textPrimary'
                          align='center'
                        >
                          For Filters
                        </Typography>
                      </Box>
                    </Grid> */}
                      <Grid item md={4} lg={4}>
                        {/* Desktop Service Cards */}
                        <Button
                          variant='outlined'
                          style={{
                            width: '100%',
                            borderRadius: '5px',
                            marginBottom: '16px',
                            color: '#7004c6',
                          }}
                          onClick={() => {
                            setOnPlanOnly(!onPlanOnly)
                          }}
                        >
                          {onPlanOnly ? (
                            <Typography variant='body1' family='poppins'>
                              Show all plans
                            </Typography>
                          ) : (
                            <Typography variant='body1' family='poppins'>
                              Show on plan only
                            </Typography>
                          )}
                        </Button>
                        <Box
                          width='100%'
                          style={{
                            padding: '15px',
                            borderRadius: '5px',
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          }}
                        >
                          <Box height='calc(70vh - 72px)' overflow='scroll'>
                            {clinicLocations.map(clinicObj => {
                              const { clinic, services, locations } = clinicObj
                              const locationIDs = locations.map(location => location.id)

                              return (
                                <Box
                                  key={clinic.id}
                                  width='100%'
                                  margin='12px 0px 0px 0px'
                                  display={onPlanOnly && clinicObj.notOnNetwork ? 'none' : 'block'}
                                >
                                  {/* Clinic Title area */}
                                  {!clinicObj.notOnNetwork && (
                                    <Box color='#7004c6'>
                                      <Typography
                                        variant='h5'
                                        color='inherit'
                                        style={{
                                          margin: '0px',
                                          fontSize: '14px',
                                        }}
                                      >
                                        On Plan
                                      </Typography>
                                    </Box>
                                  )}
                                  {clinicObj.notOnNetwork && (
                                    <Box color='#325ae8'>
                                      <Typography
                                        variant='h5'
                                        color='inherit'
                                        style={{
                                          margin: '0px',
                                          fontSize: '14px',
                                        }}
                                      >
                                        Off Plan
                                      </Typography>
                                    </Box>
                                  )}
                                  <Box
                                    display='flex'
                                    justifyContent='flex-start'
                                    alignItems='center'
                                  >
                                    <Typography
                                      variant='h3'
                                      style={{
                                        margin: '0px',
                                        fontSize: '24px',
                                      }}
                                    >
                                      {clinic.name}
                                    </Typography>
                                    <IconButton
                                      size='medium'
                                      style={{
                                        padding: '4px',
                                        marginLeft: '8px',
                                      }}
                                      onClick={() => {
                                        navigate(`/clinics/${clinicObj.clinic.slug}`)
                                      }}
                                    >
                                      <Icon name='info' fontSize='inherit' />
                                    </IconButton>
                                  </Box>
                                  {/* <Divider /> */}
                                  {/* Clinic Services area */}
                                  <Grid container>
                                    {services.map(service => (
                                      <Grid item md={12} lg={12} key={service.uid}>
                                        <Box
                                          bgcolor='#f9f9f9'
                                          boxShadow='0px 0px 2px 0px rgba(0,0,0,0.1)'
                                          style={{
                                            margin: '8px',
                                            borderRadius: '5px',
                                            padding: '15px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                          }}
                                          onMouseEnter={() => handleHover(true, locationIDs)}
                                          onMouseLeave={() => handleHover(false)}
                                        >
                                          {service.bundle && (
                                            <Box display='flex' alignItems='center'>
                                              <Box color='#7004c6'>
                                                <Typography
                                                  variant='h5'
                                                  color='inherit'
                                                  style={{
                                                    margin: '0px',
                                                    fontSize: '14px',
                                                  }}
                                                >
                                                  Bundle
                                                </Typography>
                                              </Box>
                                            </Box>
                                          )}
                                          {/* bubble top thing */}
                                          <Box display='flex' alignItems='center'>
                                            <Typography
                                              variant='h4'
                                              style={{
                                                marginBottom: '0px',
                                                fontSize: '20px',
                                              }}
                                            >
                                              {service.name}
                                            </Typography>
                                            <Box
                                              display='flex'
                                              padding='0px 4px'
                                              sx={{
                                                justifyContent: {
                                                  md: 'center',
                                                  lg: 'flex-end',
                                                  xl: 'flex-end',
                                                },
                                              }}
                                            >
                                              <Button
                                                variant='text'
                                                customvariant='blank'
                                                size='small'
                                                onClick={() => {
                                                  if (service?.organization?.review?.link) {
                                                    window.open(
                                                      service.organization.review.link,
                                                      '_blank',
                                                    )
                                                  }
                                                }}
                                              >
                                                <ReactStars
                                                  value={
                                                    service.organization?.review &&
                                                    service.organization.review?.count
                                                      ? service.organization.review.count
                                                      : 0
                                                  }
                                                  size={15}
                                                  edit={false}
                                                  activeColor={
                                                    clinicObj.notOnNetwork ? '#325ae8' : '#7004c6'
                                                  }
                                                  isHalf
                                                />
                                              </Button>
                                            </Box>
                                          </Box>
                                          {service.orderRequired && (
                                            <Box>
                                              <Typography variant='body1'>
                                                Order Required
                                              </Typography>
                                            </Box>
                                          )}
                                          <Box minHeight='50px'>
                                            {service.description && (
                                              <NetworkServiceCardDescription
                                                text={service.description}
                                              />
                                            )}
                                            <Box>
                                              {service?.bundleComponents &&
                                                service?.bundleComponents?.length > 0 && (
                                                  <>
                                                    <Divider
                                                      style={{
                                                        margin: '8px 0px',
                                                      }}
                                                    />
                                                    {service.bundleComponents.map(component => (
                                                      <Box
                                                        key={component.id || component.uid}
                                                        display='flex'
                                                        alignItems='center'
                                                        justifyContent='space-between'
                                                        color={
                                                          clinicObj.notOnNetwork
                                                            ? '#325ae8'
                                                            : '#7004c6'
                                                        }
                                                      >
                                                        <Typography
                                                          variant='body1'
                                                          weight='light'
                                                          style={{
                                                            marginRight: '10px',
                                                          }}
                                                        >
                                                          {component.name}
                                                        </Typography>
                                                        {component.organizationName && (
                                                          <Typography
                                                            variant='body1'
                                                            weight='light'
                                                            style={{
                                                              marginRight: '10px',
                                                            }}
                                                          >
                                                            {component.organizationName}
                                                          </Typography>
                                                        )}
                                                      </Box>
                                                    ))}
                                                    <Divider
                                                      style={{
                                                        margin: '8px 0px 12px 0px',
                                                      }}
                                                    />
                                                  </>
                                                )}
                                            </Box>
                                          </Box>
                                          <Box
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                            color={clinicObj.notOnNetwork ? '#325ae8' : '#7004c6'}
                                          >
                                            {service.price && (
                                              <Box
                                                display='flex'
                                                sx={{
                                                  marginTop: {
                                                    md: '10px',
                                                    lg: '0px',
                                                    xl: '0px',
                                                  },
                                                  justifyContent: {
                                                    md: 'center',
                                                    lg: 'flex-end',
                                                    xl: 'flex-end',
                                                  },
                                                }}
                                              >
                                                <Typography
                                                  variant='body1'
                                                  weight='medium'
                                                  style={{
                                                    marginRight: '10px',
                                                  }}
                                                >
                                                  {service.msrp && (
                                                    <s
                                                      style={{
                                                        marginRight: '10px',
                                                        color: '#777',
                                                      }}
                                                    >
                                                      ${service.msrp.toLocaleString()}
                                                    </s>
                                                  )}
                                                  ${service.price.toLocaleString()}
                                                </Typography>
                                                {serviceData.showPricingDisclaimer && (
                                                  <PricingDisclaimerModal
                                                    disclaimerBody={
                                                      serviceData.pricingDisclaimer
                                                        ? serviceData.pricingDisclaimer
                                                        : null
                                                    }
                                                  />
                                                )}
                                              </Box>
                                            )}
                                            {!service?.orderRequired && (
                                              <Button
                                                variant='contained'
                                                style={{
                                                  maxHeight: '40px',
                                                  borderRadius: '20px',
                                                  backgroundColor: clinicObj.notOnNetwork
                                                    ? 'rgba(50, 90, 232, 0.8)'
                                                    : '#7004c6',
                                                }}
                                                className='go-to-checkout-button'
                                                onClick={() => {
                                                  navigate(
                                                    `/plans/${network}/service/${service.uid}/checkout`,
                                                  )
                                                }}
                                              >
                                                <Typography
                                                  variant='body1'
                                                  weight='medium'
                                                  family='poppins'
                                                  style={{
                                                    color: '#fff',
                                                  }}
                                                >
                                                  Book
                                                </Typography>
                                              </Button>
                                            )}
                                          </Box>
                                        </Box>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Box>
                              )
                            })}
                          </Box>
                        </Box>
                      </Grid>
                      {/* Desktop Map */}
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Box
                          width='100%'
                          style={{
                            padding: '15px',
                            borderRadius: '5px',
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          }}
                        >
                          <GoogleMap
                            clinics={clinicLocations}
                            displayHeight='70vh'
                            onPlanOnly={onPlanOnly}
                          />
                        </Box>
                      </Grid>
                      {/* END Desktop Map */}
                    </Grid>
                  )}
                </Grid>
                {/* END Desktop Service Cards Container */}

                {/* Desktop You Should Know Section */}
                {!serviceData.hideYouShouldKnow && (
                  <Grid item md={12} lg={12}>
                    <Box mt={3} width='100%'>
                      <Grid
                        container
                        alignItems='center'
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          backgroundColor: serviceData.yskBgColor || '#f5f5f5',
                          padding: '15px',
                        }}
                      >
                        <Typography variant='h4' color='textPrimary'>
                          You should know
                        </Typography>
                        <Box width='100%' alignItems='center' display='flex'>
                          <Typography variant='body2' weight='light' textalignment='justified'>
                            Based on your health benefits, your service may be reimbursable by your
                            insurance plan. Check with your case manager to determine eligibility
                            after you receive your service. If eligible, your case manager will
                            provide you with the appropriate documentation needed. Mishe does not
                            accept insurance at this time, and is not intended for beneficiaries of
                            government payor programs, including Medicare and Medicaid.
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                )}
                {/* End of You Should Know Section */}
              </Grid>
            </Container>
          </Hidden>
        )}
        {/* End of Desktop */}

        {/* Mobile */}
        {serviceData && (
          <Hidden mdUp>
            <Container>
              <Grid container spacing={3} display='flex'>
                <Grid item xs={12} sm={12}>
                  {/* Mobile Book Consultation Questions */}
                  <Box
                    width='100%'
                    style={{
                      // position: 'sticky',
                      // top: '75px',
                      // zIndex: '1000',
                      backgroundColor: '#fff',
                      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                      borderRadius: '5px',
                      padding: '15px',
                    }}
                  >
                    <Box display='block' alignItems='center'>
                      <Box>
                        <Button
                          onClick={() => {
                            window.open('https://meet.intercom.com/margie', '_blank')
                          }}
                          display='inline'
                          variant='contained'
                          size='small'
                          fullWidth
                          style={{
                            color: '#fff',
                            padding: '1px',
                            borderRadius: '5px',
                            backgroundColor: '#662D91',
                            boxShadow: '1px 1px 5px 0px #662D91',
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                        >
                          Schedule free consultation
                        </Button>
                      </Box>
                      <Box mt={1}>
                        <Button
                          onClick={() => {
                            scrollToHash('#serviceMap')
                          }}
                          display='inline'
                          variant='outlined'
                          size='small'
                          fullWidth
                          style={{
                            padding: '1px',
                            borderRadius: '5px',
                            boxShadow: '1px 1px 5px 0px #662D91',
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                        >
                          Choose a Clinic
                        </Button>
                      </Box>
                      <Box mt={1}>
                        <Button
                          display='inline'
                          variant='contained'
                          size='small'
                          fullWidth
                          style={{
                            padding: '1px',
                            fontSize: '16px',
                            border: '1px solid #662D91',
                            borderRadius: '5px',
                            fontWeight: 'bold',
                          }}
                          className='open-live-chat'
                        >
                          Chat with us now
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  {/* End of Mobile Book Consultation Questions */}

                  {/* Mobile description */}
                  <Box
                    // style={{
                    //   margin: '15px auto',
                    //   display: 'flex',
                    //   justifyContent: 'center',
                    //   alignItems: 'center'
                    // }}
                    style={{
                      position: 'fixed',
                      zIndex: '1000',
                      bottom: '20px',
                      left: '20px',
                    }}
                  >
                    <Button
                      variant='outlined'
                      onClick={() => {
                        window.location.href = `/plans/${network}`
                      }}
                      style={{
                        height: '48px',
                        width: '48px',
                        minWidth: '48px',
                        borderRadius: '24px',
                        fontSize: '32px',
                        padding: '8px',
                      }}
                    >
                      <Icon name='leftArrow' fontSize='inherit' />
                      {/* <Typography variant='body1' family='poppins' style={{ marginBottom: '0px', padding: '4px' }}>
                        {network} Home Page
                      </Typography> */}
                    </Button>
                  </Box>
                  {serviceData.description && serviceData.serviceName && (
                    <Box display='flex' alignItems='center' mt={2}>
                      <Grid
                        container
                        alignItems='center'
                        justifyContent='space-between'
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          padding: '15px',
                        }}
                      >
                        <Box width='100%'>
                          <Typography variant='h5' color='textPrimary'>
                            {serviceData.serviceName}
                          </Typography>
                        </Box>
                        <Box width='100%'>
                          {/* <FormattedText text={serviceData.description} /> */}
                          <CollapsableFormattedText text={serviceData.description} />
                        </Box>
                      </Grid>
                    </Box>
                  )}
                  {/* End of Mobile description */}

                  {/* Mobile How Mishe works section */}
                  {!serviceData.hideHowMisheWorks && (
                    <Box mt={2} width='100%'>
                      <Grid
                        container
                        alignItems='center'
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          backgroundColor: serviceData.hiwBgColor || '#f5f5f5',
                          padding: '15px',
                        }}
                      >
                        <Typography variant='h5' color='textPrimary'>
                          How Mishe Works
                        </Typography>
                        <Box mt={2} width='100%' alignItems='center' display='flex'>
                          <Filter1Icon
                            fontSize='small'
                            style={{ color: '#303030', marginRight: '10px' }}
                          />
                          <Typography variant='body2' weight='light'>
                            We pre-negotiate prices for you
                          </Typography>
                        </Box>
                        <Box mt={2} width='100%' alignItems='center' display='flex'>
                          <Filter2Icon
                            fontSize='small'
                            style={{ color: '#303030', marginRight: '10px' }}
                          />
                          <Typography variant='body2' weight='light'>
                            You pre-pay for your care
                          </Typography>
                        </Box>
                        <Box mt={2} mb={2} width='100%' alignItems='center' display='flex'>
                          <Filter3Icon
                            fontSize='small'
                            style={{ color: '#303030', marginRight: '10px' }}
                          />
                          <Typography variant='body2' weight='light'>
                            We schedule your appointment for you
                          </Typography>
                        </Box>
                        <Box mt={1} width='100%'>
                          <Divider
                            style={{
                              marginBottom: '20px',
                            }}
                          />
                          <Typography variant='body2' weight='light' textalignment='justified'>
                            We&apos;re always available to answer your questions.
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                  {/* End of Mobile How Mishe works section */}

                  {/* Mobile Care Options */}
                  {serviceData.relatedLinksJson &&
                    Object.keys(serviceData.relatedLinksJson).length !== 0 && (
                      <Box mt={3} mb={3} width='100%'>
                        <Grid
                          container
                          width='100%'
                          style={{
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                            borderRadius: '5px',
                            padding: '15px',
                          }}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant='h5' color='textPrimary'>
                              Care Options
                            </Typography>
                          </Grid>
                          {Object.keys(serviceData.relatedLinksJson)
                            .filter(item => {
                              return serviceData.relatedLinksJson[item] !== null
                            })
                            .sort((a, b) => {
                              return b - a
                            })
                            .map(item => (
                              <Grid
                                item
                                md={2}
                                lg={2}
                                key={item}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Box
                                  style={{
                                    margin: '10px',
                                  }}
                                >
                                  <Link
                                    to={serviceData.relatedLinksJson[item]?.link}
                                    align='center'
                                    type='external'
                                    style={{
                                      color: '#303030',
                                    }}
                                  >
                                    <Typography variant='body1' weight='medium' align='center'>
                                      {serviceData.relatedLinksJson[item]?.text}
                                    </Typography>
                                  </Link>
                                </Box>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    )}
                  {/* End of Mobile Care Options */}

                  {/* Mobile Whats included - Array */}
                  {serviceData?.includedInService?.length !== 0 &&
                    Array.isArray(serviceData.includedInService) && (
                      <Box mt={3} mb={3} width='100%'>
                        <Grid
                          container
                          alignItems='center'
                          width='100%'
                          style={{
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                            borderRadius: '5px',
                            padding: '15px',
                          }}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant='h5' color='textPrimary'>
                              What&apos;s included?
                            </Typography>
                          </Grid>
                          {serviceData.includedInService.map(item => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              mt={1}
                              key={item}
                              width='100%'
                              alignItems='center'
                              display='flex'
                            >
                              <CircleIcon
                                fontSize='small'
                                style={{
                                  color: '#303030',
                                  marginRight: '10px',
                                }}
                              />
                              <Typography variant='body2' weight='light'>
                                {item}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  {/* End of mobile Whats included - Array */}

                  {/* Mobile Whats Included - String */}
                  {typeof serviceData?.includedInService === 'string' &&
                    serviceData?.includedInService !== '' && (
                      <Box mt={3} mb={3} width='100%'>
                        <Grid
                          container
                          alignItems='center'
                          width='100%'
                          style={{
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                            borderRadius: '5px',
                            padding: '15px',
                          }}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant='h5' color='textPrimary'>
                              What&apos;s included?
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormattedText
                              variant='body2'
                              weight='light'
                              text={serviceData.includedInService}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  {/* End of Mobile Whats Included - String */}

                  {/* Mobile Checklist for Care */}
                  {Array.isArray(serviceData.checklistForCare) &&
                    serviceData.checklistForCare.length !== 0 && (
                      <Box mt={3} mb={3} width='100%'>
                        <Grid
                          container
                          alignItems='center'
                          width='100%'
                          style={{
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                            borderRadius: '5px',
                            padding: '15px',
                          }}
                        >
                          <Typography variant='h5' color='textPrimary'>
                            Checklist for care
                          </Typography>
                          {serviceData.checklistForCare.map(item => (
                            <Box mt={1} key={item} width='100%' alignItems='center' display='flex'>
                              <CheckIcon
                                fontSize='small'
                                style={{
                                  color: '#303030',
                                  marginRight: '10px',
                                }}
                              />
                              <Typography variant='body2' weight='light'>
                                {item}
                              </Typography>
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  {/* End of Mobile Checklist for Care */}

                  {/* Mobile Related Content */}
                  {Array.isArray(serviceData?.relatedLinks) &&
                    serviceData.relatedLinks.length !== 0 && (
                      <Box width='100%' mt={3} mb={3}>
                        <Grid
                          container
                          style={{
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                            borderRadius: '5px',
                            padding: '15px',
                          }}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant='h5' color='textPrimary'>
                              Related Content
                            </Typography>
                          </Grid>
                          {serviceData.relatedLinks.map(item => (
                            <Grid
                              item
                              md={2}
                              lg={2}
                              key={item}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <FormattedText
                                text={item}
                                style={{
                                  margin: '10px',
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  {/* End of Mobile Related Content */}

                  {/* Mobile FAQ */}
                  {formattedFaqData && (
                    <Box mt={3} mb={3} width='100%'>
                      <Grid
                        container
                        alignItems='center'
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          padding: '15px',
                        }}
                      >
                        <Box width='100%'>
                          <Typography variant='h5' color='textPrimary'>
                            Frequently asked questions.
                          </Typography>
                        </Box>
                        <Box width='100%' mt={2}>
                          <FAQ data={formattedFaqData} />
                        </Box>
                      </Grid>
                    </Box>
                  )}
                  {/* End of Mobile FAQ */}

                  {/* Mobile Visit Type */}
                  {serviceData.visitType && (
                    <Box mt={3} width='100%'>
                      <Grid
                        container
                        alignItems='center'
                        justifyContent='space-between'
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          padding: '15px',
                        }}
                      >
                        <Typography variant='body2' color='textPrimary'>
                          Visit type
                        </Typography>
                        <Typography variant='body1' weight='light'>
                          {serviceData.visitType}
                        </Typography>
                      </Grid>
                    </Box>
                  )}
                  {/* End of Mobile Visit Type */}

                  {/* Mobile total procedure price */}
                  {serviceData.totalProcedurePrice && (
                    <Box mt={3} mb={2} width='100%'>
                      <Grid
                        container
                        alignItems='center'
                        justifyContent='space-between'
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          padding: '15px',
                        }}
                      >
                        <Typography variant='body2' color='textPrimary'>
                          Total Procedure Price Starting At:
                        </Typography>
                        <Typography variant='body1' weight='light'>
                          ${serviceData.totalProcedurePrice.toLocaleString()}
                        </Typography>
                        <Box>
                          <StripeElementsProvider>
                            <PaymentMethodMessagingElement
                              options={{
                                amount: serviceData.totalProcedurePrice * 100,
                                currency: 'USD',
                                paymentMethodTypes: ['klarna', 'affirm'],
                                countryCode: 'US',
                              }}
                            />
                          </StripeElementsProvider>
                        </Box>
                        {serviceData.insuranceRanges &&
                          Object.entries(serviceData.insuranceRanges).length !== 0 && (
                            <Box display='block' alignItems='center' mt={2}>
                              <Box
                                style={{
                                  marginTop: '0px',
                                }}
                              >
                                <Box>
                                  <img width='30px' src={InsuranceCardIcon} alt='Insurance Card' />
                                </Box>
                                <Box>
                                  <GeneralModal
                                    headerText='Insurance Pricing'
                                    customButton={
                                      <Button
                                        size='small'
                                        variant='text'
                                        customvariant='blank'
                                        style={{
                                          textDecoration: 'underline',
                                          fontSize: '12px',
                                          fontWeight: 'bold',
                                          color: 'rgba(0,0,0,0.7)',
                                        }}
                                      >
                                        Check Insurance Pricing
                                      </Button>
                                    }
                                    customFooter={
                                      <Box>
                                        <Typography variant='body2' weight='light' align='center'>
                                          Powered by{' '}
                                          <img width='100px' src={SerifLogo} alt='Serif Logo' /> &{' '}
                                          <img
                                            width='100px'
                                            src={TurquosieLogo}
                                            alt='Turquosie Logo'
                                          />
                                        </Typography>
                                      </Box>
                                    }
                                  >
                                    {generateInsurancePricing(serviceData.insuranceRanges)}
                                  </GeneralModal>
                                </Box>
                              </Box>
                            </Box>
                          )}
                      </Grid>
                    </Box>
                  )}
                  {/* End of Mobile total procedure price */}

                  {clinicLocations && clinicLocations?.length > 0 && (
                    <>
                      {/* Mobile Map */}
                      <Box
                        id='serviceMap'
                        mt={2}
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          padding: '15px',
                        }}
                      >
                        <GoogleMap clinics={clinicLocations} />
                      </Box>
                      {/* End of Mobile Map */}

                      {/* <Box
                      margin='8px 0px'
                      width='100%'
                      style={{
                        padding: '15px',
                        borderRadius: '5px',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)'
                      }}
                    >
                      <Typography
                        variant='h4'
                        color='textPrimary'
                        align='center'
                      >
                        For Filters
                      </Typography>
                    </Box> */}

                      {/* Mobile Service Cards */}
                      <Box
                        width='100%'
                        margin='8px 0px'
                        style={{
                          padding: '15px',
                          borderRadius: '5px',
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                        }}
                      >
                        <Box height='70vh' overflow='scroll'>
                          {clinicLocations.map(clinicObj => {
                            const { clinic, services, locations } = clinicObj
                            const locationIDs = locations.map(location => location.id)

                            return (
                              <Box key={clinic.id} width='100%' margin='12px 0px 0px 0px'>
                                {/* Clinic Title area */}
                                {!clinicObj.notOnNetwork && (
                                  <Box color='#7004c6'>
                                    <Typography
                                      variant='h5'
                                      color='inherit'
                                      style={{
                                        margin: '0px',
                                        fontSize: '14px',
                                      }}
                                    >
                                      On Plan
                                    </Typography>
                                  </Box>
                                )}
                                {clinicObj.notOnNetwork && (
                                  <Box color='#325ae8'>
                                    <Typography
                                      variant='h5'
                                      color='inherit'
                                      style={{
                                        margin: '0px',
                                        fontSize: '14px',
                                      }}
                                    >
                                      Off Plan
                                    </Typography>
                                  </Box>
                                )}
                                <Box display='flex' justifyContent='flex-start' alignItems='center'>
                                  <Typography
                                    variant='h3'
                                    style={{
                                      margin: '0px',
                                      fontSize: '24px',
                                    }}
                                  >
                                    {clinic.name}
                                  </Typography>
                                  <IconButton
                                    size='medium'
                                    style={{
                                      padding: '4px',
                                      marginLeft: '8px',
                                    }}
                                    onClick={() => {
                                      navigate(`/clinics/${clinicObj.clinic.slug}`)
                                    }}
                                  >
                                    <Icon name='info' fontSize='inherit' />
                                  </IconButton>
                                </Box>
                                {/* <Divider /> */}
                                {/* Clinic Services area */}
                                <Grid container>
                                  {services.map(service => (
                                    <Grid
                                      item
                                      md={12}
                                      lg={12}
                                      key={service.uid}
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      <Box
                                        bgcolor='#f9f9f9'
                                        boxShadow='0px 0px 2px 0px rgba(0,0,0,0.1)'
                                        width='100%'
                                        style={{
                                          margin: '8px 0px',
                                          borderRadius: '5px',
                                          padding: '15px',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                        }}
                                        onMouseEnter={() => handleHover(true, locationIDs)}
                                        onMouseLeave={() => handleHover(false)}
                                      >
                                        {service.bundle && (
                                          <Box display='flex' alignItems='center'>
                                            <Box color='#7004c6'>
                                              <Typography
                                                variant='h5'
                                                color='inherit'
                                                style={{
                                                  margin: '0px',
                                                  fontSize: '14px',
                                                }}
                                              >
                                                Bundle
                                              </Typography>
                                            </Box>
                                          </Box>
                                        )}
                                        {/* bubble top thing */}
                                        <Box
                                          display='flex'
                                          alignItems='center'
                                          // flexDirection='column'
                                        >
                                          <Typography
                                            variant='h4'
                                            style={{
                                              marginBottom: '0px',
                                              fontSize: '20px',
                                            }}
                                          >
                                            {service.name}
                                          </Typography>
                                          <Box
                                            display='flex'
                                            padding='0px 4px'
                                            sx={{
                                              justifyContent: {
                                                md: 'center',
                                                lg: 'flex-end',
                                                xl: 'flex-end',
                                              },
                                            }}
                                          >
                                            <Button
                                              variant='text'
                                              customvariant='blank'
                                              size='small'
                                              onClick={() => {
                                                if (service?.organization?.review?.link) {
                                                  window.open(
                                                    service.organization.review.link,
                                                    '_blank',
                                                  )
                                                }
                                              }}
                                            >
                                              <ReactStars
                                                value={
                                                  service.organization?.review &&
                                                  service.organization.review?.count
                                                    ? service.organization.review.count
                                                    : 0
                                                }
                                                size={15}
                                                edit={false}
                                                activeColor={
                                                  clinicObj.notOnNetwork ? '#325ae8' : '#7004c6'
                                                }
                                                isHalf
                                              />
                                            </Button>
                                          </Box>
                                        </Box>
                                        <Box minHeight='50px'>
                                          {service.description && (
                                            <NetworkServiceCardDescription
                                              text={service.description}
                                            />
                                          )}
                                          {service?.bundleComponents &&
                                            service?.bundleComponents?.length > 0 && (
                                              <>
                                                <Divider
                                                  style={{
                                                    margin: '8px 0px',
                                                  }}
                                                />
                                                {service.bundleComponents.map(component => (
                                                  <Box
                                                    key={component.id || component.uid}
                                                    display='flex'
                                                    alignItems='center'
                                                    justifyContent='space-between'
                                                    color={
                                                      clinicObj.notOnNetwork ? '#325ae8' : '#7004c6'
                                                    }
                                                  >
                                                    <Typography
                                                      variant='body1'
                                                      weight='light'
                                                      style={{
                                                        marginRight: '10px',
                                                      }}
                                                    >
                                                      {component.name}
                                                    </Typography>
                                                    {component.organizationName && (
                                                      <Typography
                                                        variant='body1'
                                                        weight='light'
                                                        style={{
                                                          marginRight: '10px',
                                                        }}
                                                      >
                                                        {component.organizationName}
                                                      </Typography>
                                                    )}
                                                  </Box>
                                                ))}
                                                <Divider
                                                  style={{
                                                    margin: '8px 0px 12px 0px',
                                                  }}
                                                />
                                              </>
                                            )}
                                        </Box>
                                        <Box
                                          display='flex'
                                          alignItems='center'
                                          justifyContent='space-between'
                                          color={clinicObj.notOnNetwork ? '#325ae8' : '#7004c6'}
                                        >
                                          {service.price && (
                                            <Box
                                              display='flex'
                                              sx={{
                                                marginTop: {
                                                  md: '10px',
                                                  lg: '0px',
                                                  xl: '0px',
                                                },
                                                justifyContent: {
                                                  md: 'center',
                                                  lg: 'flex-end',
                                                  xl: 'flex-end',
                                                },
                                              }}
                                            >
                                              <Typography
                                                variant='body1'
                                                weight='medium'
                                                style={{
                                                  marginRight: '10px',
                                                  marginTop: '3px',
                                                }}
                                              >
                                                {service.msrp && (
                                                  <s
                                                    style={{
                                                      marginRight: '10px',
                                                      color: '#777',
                                                    }}
                                                  >
                                                    ${service.msrp.toLocaleString()}
                                                  </s>
                                                )}
                                                ${service.price.toLocaleString()}
                                              </Typography>
                                              {serviceData.showPricingDisclaimer && (
                                                <PricingDisclaimerModal
                                                  disclaimerBody={
                                                    serviceData.pricingDisclaimer
                                                      ? serviceData.pricingDisclaimer
                                                      : null
                                                  }
                                                />
                                              )}
                                            </Box>
                                          )}
                                          <Button
                                            variant='contained'
                                            style={{
                                              maxHeight: '40px',
                                              borderRadius: '20px',
                                              backgroundColor: clinicObj.notOnNetwork
                                                ? 'rgba(50, 90, 232, 0.8)'
                                                : '#7004c6',
                                            }}
                                            className='go-to-checkout-button'
                                            onClick={() => {
                                              navigate(
                                                `/plans/${network}/service/${service.uid}/checkout`,
                                              )
                                            }}
                                          >
                                            <Typography
                                              variant='body1'
                                              weight='medium'
                                              family='poppins'
                                              style={{
                                                color: '#fff',
                                              }}
                                            >
                                              Book
                                            </Typography>
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Box>
                            )
                          })}
                        </Box>
                      </Box>
                    </>
                  )}

                  {/* Mobile You Should Know Section */}
                  {!serviceData.hideYouShouldKnow && (
                    <Box mt={3} width='100%'>
                      <Grid
                        container
                        alignItems='center'
                        width='100%'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                          borderRadius: '5px',
                          backgroundColor: serviceData.yskBgColor || '#f5f5f5',
                          padding: '15px',
                        }}
                      >
                        <Typography variant='h5' color='textPrimary'>
                          You should know
                        </Typography>
                        <Box width='100%' alignItems='center' display='flex'>
                          <Typography variant='body2' weight='light' textalignment='justified'>
                            Based on your health benefits, your service may be reimbursable by your
                            insurance plan. Check with your case manager to determine eligibility
                            after you receive your service. If eligible, your case manager will
                            provide you with the appropriate documentation needed. Mishe does not
                            accept insurance at this time, and is not intended for beneficiaries of
                            government payor programs, including Medicare and Medicaid.
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                  {/* End of Mobile You Should Know Section */}
                </Grid>
              </Grid>
            </Container>
          </Hidden>
        )}
        {/* End of Mobile */}
      </Section>
    </>
  )
}

NetworkServiceInfoLander.propTypes = {
  misheCode: PropTypes.string.isRequired,
  network: PropTypes.string.isRequired,
  landerData: PropTypes.shape({
    specialtyListTitle: PropTypes.string.isRequired,
    specialtyListSubtext: PropTypes.string.isRequired,
    specialtyLanderData: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        services: PropTypes.arrayOf(
          PropTypes.shape({
            serviceName: PropTypes.string.isRequired,
            icon: PropTypes.arrayOf(
              PropTypes.shape({
                downloadURL: PropTypes.string,
              }),
            ),
            noticeMessage: PropTypes.string,
            misheCode: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }),
}

export default NetworkServiceInfoLander
