import PropTypes from 'prop-types'
import Chip from '../stories/Chip'

const getType = label => {
  switch (label) {
    case 'external':
      return 'external'
    case 'bundle':
      return 'bundle'
    case 'redeemed':
      return 'success'
    case 'cancelled':
    case 'error':
      return 'error'
    default:
      return 'info'
  }
}

const VouchersChip = ({ voucher, ...props }) => {
  const isRedeemedBundle = voucher.bundle && voucher.status === 'redeemed'
  const label = voucher.external
    ? 'external'
    : isRedeemedBundle
      ? 'redeemed'
      : voucher.bundle
        ? 'bundle'
        : voucher.status
  const type = getType(label)

  return <Chip label={label} type={type} {...props} />
}

VouchersChip.propTypes = {
  voucher: PropTypes.shape({
    bundle: PropTypes.bool,
    status: PropTypes.string,
  }).isRequired,
}

export default VouchersChip
