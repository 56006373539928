import { useMemo } from 'react'
import Hidden from '@mui/material/Hidden'
import { useCurrentUser, useCurrentProfile, useCurrentClinic } from '../context/CurrentUser'
import Box from '@mui/material/Box'
import SignOutButton from '@app/containers/SignOutButton'
import NavLink from '../stories/NavLink'
import SideMenu from './SideMenu'

import useSignInUpModals from '../hooks/useSignInUpModals'
import SignInModal from './SignInModal'
import Typography from '../stories/Typography'
import NavButtonContainer from '@app/containers/NavButtonContainer'
import DropDownMenu from './DropDownMenu'

const TopCorner = ({ primaryColor, secondaryColor }) => {
  const { uid } = useCurrentUser()
  const { plansAssociated = {}, isProvider } = useCurrentProfile()
  const { plansAssociated: clinicPlansAssociated = {} } = useCurrentClinic()

  const { signInOpen, handleOpen } = useSignInUpModals()

  if (uid === null) {
    return 'trying to authenticate...'
  }

  // Memo the sign in modal
  const signInModal = useMemo(() => {
    return (
      <SignInModal
        open={signInOpen}
        handleClose={() => handleOpen('signIn')}
        callback={() => {
          handleOpen('singIn')
        }}
      />
    )
  }, [signInOpen, handleOpen])

  const validPlans = useMemo(() => {
    let goodPlans = []
    const plans = isProvider ? clinicPlansAssociated : plansAssociated
    Object.keys(plans).forEach(planID => {
      if (
        plans[planID]?.name === 'Cost Plus Health Plan' ||
        plans[planID]?.name === 'Mishe Health Plan'
      ) {
        console.warn('Mishe Health Plan is not a valid plan', planID)
        return null
      }

      if (plans[planID]?.membershipStatus !== 'active') {
        console.warn('Plan is not active', planID)
        return null
      }

      if (!plans[planID]?.name) {
        console.warn('Plan has no name', planID)
        return null
      }

      if (!plans[planID]?.slug) {
        console.warn('Plan has no slug', planID)
        return null
      }

      goodPlans.push({
        id: planID,
        ...plans[planID],
      })
    })

    return goodPlans
  }, [plansAssociated, clinicPlansAssociated, isProvider])

  const [firstPlans, lastPlan] = useMemo(() => {
    if (validPlans.length > 1) {
      return [validPlans.slice(0, -1), validPlans.slice(-1)[0]]
    } else {
      return [validPlans, null]
    }
  }, [validPlans])

  return (
    <>
      {signInModal}
      {/* TODO remove hidden as it is depricated, replace with responsive styling or breakpoint components */}
      <Hidden mdUp>
        <Box color={secondaryColor || '#fff'} width='100%'>
          <SideMenu plans={validPlans} />
        </Box>
      </Hidden>
      <Hidden mdDown>
        {validPlans.length > 0 && (
          <DropDownMenu
            text={uid ? 'My Plans' : 'Plans'}
            drawerProps={{
              style: { width: '200px' },
            }}
            variant='longer'
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          >
            {lastPlan ? (
              <>
                {firstPlans.map(plan => (
                  <Box key={plan.id} color={secondaryColor || '#fff'} width='100%'>
                    <NavLink to={`/plans/${plan.slug}`} align='left' width='full'>
                      {plan.name}
                    </NavLink>
                  </Box>
                ))}
                <Box color={secondaryColor || '#fff'} width='100%'>
                  <NavLink
                    to={`/plans/${lastPlan.slug}`}
                    align='left'
                    width='full'
                    style={{ borderRadius: '0 0 21.125px 21.125px' }}
                  >
                    {lastPlan.name}
                  </NavLink>
                </Box>
              </>
            ) : (
              validPlans.map(plan => (
                <Box key={plan.id} color={secondaryColor || '#fff'} width='100%'>
                  <NavLink
                    to={`/plans/${plan.slug}`}
                    align='left'
                    width='full'
                    style={{ borderRadius: '0 21.125px 21.125px 21.125px' }}
                  >
                    {plan.name}
                  </NavLink>
                </Box>
              ))
            )}
          </DropDownMenu>
        )}
        {uid ? (
          <>
            {/* <NavLink to='/dashboard' display='standard'>
            Dashboard
          </NavLink>
          <SignOutButton /> */}
            <Box color={secondaryColor || '#fff'} display='flex'>
              <NavButtonContainer color={secondaryColor}>
                <NavLink to='/dashboard' display='standard' style={{ borderRadius: '21.125px' }}>
                  <Typography variant='body1' weight='medium' color={secondaryColor || '#fff'}>
                    Dashboard
                  </Typography>
                </NavLink>
              </NavButtonContainer>
              <NavButtonContainer color={secondaryColor}>
                <SignOutButton color={secondaryColor} />
              </NavButtonContainer>
            </Box>
          </>
        ) : (
          <>
            <NavButtonContainer>
              <NavLink
                onClick={() => {
                  handleOpen('singIn')
                }}
                display='standard'
                style={{ borderRadius: '21.125px' }}
              >
                Login
              </NavLink>
            </NavButtonContainer>
            <NavButtonContainer>
              <NavLink to='/signup' display='standard' style={{ borderRadius: '21.125px' }}>
                Sign Up
              </NavLink>
            </NavButtonContainer>
          </>
        )}
      </Hidden>
    </>
  )
}

export default TopCorner
