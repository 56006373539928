import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Grid, Box, Hidden } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { collection } from '../firebase/firestore'
import { useCurrentUser } from '../context/CurrentUser'
import OrderDetailsCard from './OrderDetailsCard'
// import DateField from '../stories/DateField'
// import TextField from '../stories/TextField'
import Button from '../stories/Button'

const OrdersDashboardContent = () => {
  const { uid } = useCurrentUser()

  const [orders, setOrders] = useState({
    all: [],
    filtered: [],
  })

  const [openFilters, setOpenFilters] = useState(false)

  const [filters, setFilters] = useState({
    medication: true,
    lab: true,
    referral: true,
    other: true,
    paid: true,
    unpaid: true,
    failed: true,
  })

  const handleToggle = value => () => {
    const newFilters = { ...filters }
    newFilters[value] = !newFilters[value]
    setFilters(newFilters)
  }

  useEffect(() => {
    // get all orders where patient.id is equal to uid
    const getOrders = async () => {
      try {
        console.log('-- Attempting to get orders with ID')
        const ordersRefs = await collection('emrOrders')
          .where('patient.id', '==', uid)
          .orderBy('createdAt', 'desc')
          .get()

        console.log('  -- Orders Dashboard Conetnt - snapshot')
        console.log(ordersRefs)

        if (!ordersRefs?.empty) {
          setOrders({
            all: ordersRefs.docs.map(doc => doc.data()),
            filtered: ordersRefs.docs.map(doc => doc.data()),
          })
          return undefined
        }

        const ordersRefsUsingUID = await collection('emrOrders')
          .where('patient.uid', '==', uid)
          .orderBy('createdAt', 'desc')
          .get()

        console.log('  -- Orders Dashboard Conetnt - snapshot')
        console.log(ordersRefsUsingUID)

        if (!ordersRefsUsingUID?.empty) {
          setOrders({
            all: ordersRefsUsingUID.docs.map(doc => doc.data()),
            filtered: ordersRefsUsingUID.docs.map(doc => doc.data()),
          })
          return undefined
        }

        console.log('  -- Orders Dashboard Conetnt - No matching documents.')
        return undefined
      } catch (error) {
        console.log('  ** error getting orders with email')
        console.log(error)
      }
    }

    if (uid) {
      getOrders()
    }
  }, [uid])

  useEffect(() => {
    const filterOrders = () => {
      const filteredOrders = orders.all.filter(order => {
        if (filters[order.orderType] && filters[order.status]) {
          return order
        }
        return undefined
      })
      setOrders({ ...orders, filtered: filteredOrders })
    }
    filterOrders()
  }, [filters])

  return (
    // if orders exist itterate through them and display them, if there are no orders display a message
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h4' gutterBottom>
          Orders
        </Typography>
        <Button
          variant={openFilters ? 'contained' : 'outlined'}
          onClick={() => {
            setOpenFilters(!openFilters)
          }}
        >
          Filters
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Collapse
          in={openFilters}
          unmountOnExit
          mountOnEnter
          timeout={{ enter: 250, exit: 250 }}
          style={{ width: '100%' }}
        >
          <Fade in={openFilters} timeout={{ enter: 500, exit: 250 }} style={{ width: '100%' }}>
            <Grid item xs={12} style={{ width: '100%' }}>
              <Grid
                container
                style={{
                  width: '100%',
                  boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.2)',
                  borderRadius: '4px',
                  padding: '16px',
                }}
              >
                <Grid item xs={12} md={5} mb={3}>
                  <Typography variant='h5' gutterBottom>
                    Filter by type.
                  </Typography>
                  <Box display='flex' flexDirection='column'>
                    <Box padding='8px'>
                      <Hidden mdDown>
                        <ButtonGroup>
                          <Button
                            variant={filters.medication ? 'contained' : 'outlined'}
                            color='primary'
                            value='medication'
                            selected={filters.medication}
                            onClick={handleToggle('medication')}
                          >
                            Medications
                          </Button>
                          <Button
                            variant={filters.referral ? 'contained' : 'outlined'}
                            color='primary'
                            value='referral'
                            selected={filters.referral}
                            onClick={handleToggle('referral')}
                          >
                            Referrals
                          </Button>
                          <Button
                            variant={filters.lab ? 'contained' : 'outlined'}
                            color='primary'
                            value='lab'
                            selected={filters.lab}
                            onClick={handleToggle('lab')}
                          >
                            Labs
                          </Button>
                          <Button
                            variant={filters.other ? 'contained' : 'outlined'}
                            color='primary'
                            value='other'
                            selected={filters.other}
                            onClick={handleToggle('other')}
                          >
                            Other
                          </Button>
                        </ButtonGroup>
                      </Hidden>
                      <Hidden mdUp>
                        <Box display='flex' flexDirection='column' style={{ width: '100%' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={filters.medication}
                                onChange={handleToggle('medication')}
                                name='medication'
                                color='primary'
                              />
                            }
                            label='Medications'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={filters.referral}
                                onChange={handleToggle('referral')}
                                name='referral'
                                color='primary'
                              />
                            }
                            label='Referrals'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={filters.lab}
                                onChange={handleToggle('lab')}
                                name='lab'
                                color='primary'
                              />
                            }
                            label='Labs'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={filters.other}
                                onChange={handleToggle('other')}
                                name='other'
                                color='primary'
                              />
                            }
                            label='Other'
                          />
                        </Box>
                      </Hidden>
                    </Box>
                    <Box>
                      <Typography variant='h5' gutterBottom>
                        Filter by status.
                      </Typography>
                    </Box>
                    <Hidden mdDown>
                      <Box padding='8px'>
                        <ButtonGroup>
                          <Button
                            variant={filters.paid ? 'contained' : 'outlined'}
                            color='primary'
                            value='other'
                            selected={filters.paid}
                            onClick={handleToggle('paid')}
                          >
                            Paid
                          </Button>
                          <Button
                            variant={filters.unpaid ? 'contained' : 'outlined'}
                            color='primary'
                            value='other'
                            selected={filters.unpaid}
                            onClick={handleToggle('unpaid')}
                          >
                            Unpaid
                          </Button>
                          <Button
                            variant={filters.failed ? 'contained' : 'outlined'}
                            color='primary'
                            value='other'
                            selected={filters.failed}
                            onClick={handleToggle('failed')}
                          >
                            Failed
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Hidden>
                    <Hidden mdUp>
                      <Box
                        display='flex'
                        flexDirection='column'
                        padding='8px'
                        style={{ width: '100%' }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.paid}
                              onChange={handleToggle('paid')}
                              name='paid'
                              color='primary'
                            />
                          }
                          label='Paid'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.unpaid}
                              onChange={handleToggle('unpaid')}
                              name='unpaid'
                              color='primary'
                            />
                          }
                          label='Unpaid'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.failed}
                              onChange={handleToggle('failed')}
                              name='failed'
                              color='primary'
                            />
                          }
                          label='Failed'
                        />
                      </Box>
                    </Hidden>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} md={7} mb={3}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    style={{ width: '100%' }}
                  >
                    <Typography variant='h5' gutterBottom>
                      Filter by date.
                    </Typography>
                    <Box
                      display='flex'
                      sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                        margin: { xs: '0px', sm: '-16px 0px -8px 0px' }
                      }}
                    >
                      <Box
                        sx={{
                          width: { xs: '100%', sm: '50%' },
                          padding: { xs: '0px', sm: '8px' }
                        }}
                      >
                        <DateField label='Start Date' />
                      </Box>
                      <Box
                        sx={{
                          width: { xs: '100%', sm: '50%' },
                          padding: { xs: '0px', sm: '8px' }
                        }}
                      >
                        <DateField label='End Date' />
                      </Box>
                    </Box>
                    <Typography variant='h5' gutterBottom>
                      Filter by keyword.
                    </Typography>
                    <Box
                      sx={{
                        padding: { xs: '0px', sm: '8px' },
                        margin: { xs: '0px', sm: '-16px 0px 0px 0px' }
                      }}
                    >
                      <TextField
                        id='Keyword Search'
                        label='Search'
                        variant='outlined'
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>
          </Fade>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {orders?.filtered.length > 0 ? (
            orders?.filtered.map(order => {
              return OrderDetailsCard({
                order,
                variant: 'grid',
              })
            })
          ) : (
            <Grid item xs={12}>
              <Typography variant='h4' gutterBottom>
                You don&apos;t have any orders yet.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OrdersDashboardContent
