import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Grid, Box, Divider } from '@mui/material'
import Slide from '@mui/material/Slide'
import Fade from '@mui/material/Fade'
import Hidden from '@mui/material/Hidden'
import Collapse from '@mui/material/Collapse'
import { collection } from '../firebase/firestore'
import NationalMap from '@app/components/NationalMap'
import Typography from '../stories/Typography'
import InstantSearchContainerCustom from '@app/containers/InstantSearchContainerCustom'
import ClinicsMapSearchCard from '@app/components/ClinicsMapSearchCard'
import Button from '../stories/Button'
import Icon from '../stories/Icons'

import MapEffectsProvider from '../context/MapEffects'

const NationalMapPage = () => {
  const [locations, setLocations] = useState([])
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(true)
  const [listOpen, setListOpen] = useState(false)
  const [locationSelected, setLocationSelected] = useState(null)
  const [clinicSelected, setClinicSelected] = useState(false)
  const [openClinicModal, setOpenClinicModal] = useState(false)
  const [modalInfoOpen, setModalInfoOpen] = useState(true)
  const [modalHoursOpen, setModalHoursOpen] = useState(true)
  const [modalSpecialtiesOpen, setModalSpecialtiesOpen] = useState(true)

  useEffect(() => {
    if (window?.Intercom) {
      window.Intercom('update', { hide_default_launcher: true })
    }
    return () => {
      if (window?.Intercom) {
        window.Intercom('update', { hide_default_launcher: false })
      }
    }
  }, [window?.Intercom])

  useEffect(() => {
    if (clinicSelected) {
      setOpenClinicModal(true)
      if (window.innerWidth <= 600) {
        setSideBarOpen(false)
      }
    }
  }, [clinicSelected])

  useEffect(() => {
    const getClinicData = async (geopoint, locID, clinicID) => {
      const dbRefs = [
        collection('organizations').doc(clinicID).get(),
        collection('organizations').doc(clinicID).collection('locations').doc(locID).get(),
      ]

      const [clinicRef, locationRef] = await Promise.all(dbRefs)

      const locationData = locationRef.data()
      const clinicInfo = clinicRef.data()

      console.log('  -- National Map Page - LocationData - Clinic Info')
      console.log(locationData)
      console.log(clinicInfo)

      const clinicImage = clinicInfo?.images?.[0]?.url || null

      const clinicData = {
        clinic: { ...clinicInfo },
        clinicImage,
        location: { ...locationData },
        geopoint,
      }
      setClinicSelected(clinicData)
    }

    const { geopoint, _geoloc, id, objectID, clinicID, parentID } = locationSelected || {}

    if ((id || objectID) && (clinicID || parentID) && (geopoint || _geoloc)) {
      getClinicData(geopoint || _geoloc, id || objectID, clinicID || parentID)
    }
  }, [locationSelected])

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden'
    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [])

  const cleanUp = () => {
    setSearchOpen(false)
    setListOpen(false)
  }

  // {* BEGIN Tracking Calls *}

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'National Map',
    })
  }, [])

  // We still use the cache system in order to show all the locations on the map.
  // Algolia limits us to 20k per search and is costly to use for this purpose.
  // We will use the cache system to store all the locations and then use the search to filter the locations.
  // This reduces the call down to one per load.
  useEffect(() => {
    const getLocationsFromCache = async () => {
      const locationsRef = collection('cacheSystem').doc('clinicLocationsAllNew')
      const locationsData = await locationsRef.get().then(doc => doc.data())

      setLocations(locationsData.locations)
    }
    getLocationsFromCache()
  }, [])

  return (
    <>
      <Helmet>
        <title>Clinics Map</title>
        <meta property='og:title' content='Clinics Map ' />
        <meta property='og:url' content='https://www.mishe.co/map' />
        <link rel='canonical' href='https://mishe.co/map' />
      </Helmet>
      <MapEffectsProvider>
        <Grid
          container
          style={{
            position: 'relative',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
          }}
        >
          {locations && (
            <>
              <Slide direction='right' in={sideBarOpen} mountOnEnter unmountOnExit>
                <Box
                  style={{
                    position: 'absolute',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    left: '0px',
                    width: '300px',
                    zIndex: '100',
                    padding: '8px',
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
                    overflowY: 'scroll',
                  }}
                  sx={{
                    top: {
                      xs: '8px',
                      sm: '8px',
                      md: '0px',
                    },
                    height: {
                      xs: 'calc(100vh - 48px)',
                      sm: 'calc(100vh - 48px)',
                      md: 'calc(100vh - 64px)',
                    },
                  }}
                >
                  <Box
                    width='100%'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    paddingBottom='8px'
                  >
                    <Box>
                      <Button
                        variant='blank'
                        onClick={() => {
                          cleanUp()
                          setSearchOpen(true)
                        }}
                        style={{
                          minWidth: '36px',
                          padding: '0',
                          width: '36px',
                          height: '36px',
                          borderRadius: '18px',
                          margin: '0px',
                          backgroundColor: searchOpen ? '#e5d7f5' : 'transparent',
                        }}
                      >
                        <Icon name='search' color='primary' />
                      </Button>
                      <Button
                        variant='blank'
                        onClick={() => {
                          cleanUp()
                          setListOpen(true)
                        }}
                        style={{
                          minWidth: '36px',
                          padding: '0',
                          width: '36px',
                          height: '36px',
                          borderRadius: '18px',
                          margin: '0px',
                          backgroundColor: listOpen ? '#e5d7f5' : 'transparent',
                        }}
                      >
                        <Icon name='filter' color='primary' />
                      </Button>
                    </Box>
                    <Button
                      variant='blank'
                      onClick={() => {
                        setSideBarOpen(false)
                      }}
                      style={{
                        minWidth: '36px',
                        padding: '0',
                        width: '36px',
                        height: '36px',
                        borderRadius: '18px',
                      }}
                    >
                      <Icon name='leftArrow' color='primary' />
                    </Button>
                  </Box>
                  <Divider />
                  {searchOpen && (
                    <Box position='relative'>
                      <InstantSearchContainerCustom
                        HitComponent={ClinicsMapSearchCard}
                        hitClick={setLocationSelected}
                        indexName='clinics'
                        configuration={
                          {
                            // hitsPerPage: 5
                          }
                        }
                        searchBoxText='Search for a clinic'
                        noResultsMessage='No results for'
                        showInitialHits
                      />
                    </Box>
                  )}
                </Box>
              </Slide>
              <Slide direction='right' in={!sideBarOpen} mountOnEnter unmountOnExit>
                <Box
                  style={{
                    position: 'absolute',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    left: '0px',
                    width: '36px',
                    zIndex: '100',
                    paddingTop: '18px',
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
                  }}
                  sx={{
                    top: {
                      xs: '8px',
                      sm: '8px',
                      md: '0px',
                    },
                    height: {
                      xs: 'calc(100vh - 48px)',
                      sm: 'calc(100vh - 48px)',
                      md: 'calc(100vh - 64px)',
                    },
                  }}
                >
                  <Box
                    style={{
                      position: 'absolute',
                      top: '8px',
                      right: '0px',
                    }}
                  >
                    <Button
                      variant='blank'
                      style={{
                        minWidth: '36px',
                        padding: '0',
                        width: '36px',
                        height: '36px',
                        borderRadius: '18px',
                      }}
                      onClick={() => {
                        setSideBarOpen(true)
                      }}
                    >
                      <Icon name='leftArrow' color='primary' />
                    </Button>
                  </Box>
                </Box>
              </Slide>
              <Hidden smDown>
                <Fade in={openClinicModal}>
                  <Box
                    style={{
                      left: sideBarOpen ? '300px' : '36px',
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
                      padding: '0px',
                      top: '0px',
                      width: '300px',
                      height: 'min-content',
                      position: 'absolute',
                      zIndex: '1',
                    }}
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                      }}
                    >
                      <Button
                        variant='blank'
                        onClick={() => {
                          setOpenClinicModal(false)
                        }}
                        style={{
                          minWidth: '36px',
                          padding: '0',
                          width: '36px',
                          height: '36px',
                          borderRadius: '18px',
                        }}
                      >
                        <Icon name='close' color='primary' />
                      </Button>
                    </Box>
                    <Box>
                      <Typography
                        variant='h3'
                        gutterBottom
                        family='poppins'
                        color='primary'
                        weight='light'
                        style={{
                          padding: '8px',
                          width: '90%',
                          marginBottom: '4px',
                        }}
                      >
                        {clinicSelected?.clinic?.name ||
                          clinicSelected?.location?.name ||
                          'Clinic Name'}
                      </Typography>
                      <Divider />
                      <Button
                        variant='blank'
                        style={{
                          width: '100%',
                          height: '36px',
                          padding: '0 8px',
                        }}
                      >
                        <a
                          href={`/clinics/${clinicSelected?.clinic?.slug}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{
                            textDecoration: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            color: '#303030',
                          }}
                        >
                          <Typography
                            variant='body1'
                            color='inherit'
                            style={{
                              margin: '8px 0px',
                            }}
                          >
                            Clinic Page
                          </Typography>
                          <Icon name='info' color='inherit' />
                        </a>
                      </Button>
                      <Divider />
                      <Button
                        variant='blank'
                        color='#303030'
                        style={{
                          width: '100%',
                          height: '36px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0 8px',
                        }}
                        onClick={() => {
                          setModalInfoOpen(!modalInfoOpen)
                        }}
                      >
                        <Typography variant='body1' style={{ margin: '8px 0px' }}>
                          Information
                        </Typography>
                        <Icon name={modalInfoOpen ? 'closeArrow' : 'openArrow'} color='inherit' />
                      </Button>
                      <Divider />
                      <Collapse in={modalInfoOpen}>
                        <Box padding='8px'>
                          <Typography variant='body1'>
                            {clinicSelected?.location?.address}
                            <br />
                            {clinicSelected?.location?.city}, {clinicSelected?.location?.state}{' '}
                            {clinicSelected?.location?.zipcode}
                            <br />
                            {clinicSelected?.location?.phone}
                            <br />
                          </Typography>
                        </Box>
                        <Divider />
                      </Collapse>
                      {/* <Button
                      variant='blank'
                      style={{
                        width: '100%',
                        height: '36px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0 8px'
                      }}
                      onClick={() => {
                        setModalHoursOpen(!modalHoursOpen)
                      }}
                    >
                      <Typography variant='body1' style={{ margin: '8px 0px' }}>
                        Hours
                      </Typography>
                      <Icon
                        name={modalHoursOpen ? 'closeArrow' : 'openArrow'}
                        color='inherit'
                      />
                    </Button>
                    <Divider />
                    <Collapse in={modalHoursOpen}>
                      <Box padding='8px'>
                        <Typography variant='body1'>
                          Monday: 9:00 AM - 5:00 PM
                          <br />
                          Tuesday: 9:00 AM - 5:00 PM
                          <br />
                          Wednesday: 9:00 AM - 5:00 PM
                          <br />
                          Thursday: 9:00 AM - 5:00 PM
                          <br />
                          Friday: 9:00 AM - 5:00 PM
                          <br />
                          Saturday: Closed
                          <br />
                          Sunday: Closed
                        </Typography>
                      </Box>
                      <Divider />
                    </Collapse>
                    <Button
                      variant='blank'
                      style={{
                        width: '100%',
                        height: '36px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0 8px'
                      }}
                      onClick={() => {
                        setModalSpecialtiesOpen(!modalSpecialtiesOpen)
                      }}
                    >
                      <Typography variant='body1' style={{ margin: '8px 0px' }}>
                        Specialties
                      </Typography>
                      <Icon
                        name={modalSpecialtiesOpen ? 'closeArrow' : 'openArrow'}
                        color='inherit'
                      />
                    </Button>
                    <Collapse in={modalSpecialtiesOpen}>
                      <Divider />
                      <Box padding='8px'>
                        <Typography variant='body1'>
                          Specialty 1
                          <br />
                          Specialty 2
                          <br />
                          Specialty 3
                        </Typography>
                      </Box>
                    </Collapse> */}
                    </Box>
                  </Box>
                </Fade>
              </Hidden>
              <Hidden smUp>
                <Slide in={openClinicModal} direction='up' mountOnEnter unmountOnExit>
                  <Box
                    style={{
                      left: '0px',
                      top: '60vh',
                      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
                      width: '100vw',
                      height: '40vh',
                      position: 'fixed',
                      zIndex: '100000',
                      backgroundColor: '#fff',
                      overflowY: 'scroll',
                    }}
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                      }}
                    >
                      <Button
                        variant='blank'
                        onClick={() => {
                          setOpenClinicModal(false)
                        }}
                        style={{
                          minWidth: '36px',
                          padding: '0',
                          width: '36px',
                          height: '36px',
                          borderRadius: '18px',
                        }}
                      >
                        <Icon name='close' color='primary' />
                      </Button>
                    </Box>
                    <Box>
                      <Typography
                        variant='h3'
                        gutterBottom
                        family='poppins'
                        color='primary'
                        weight='light'
                        style={{
                          padding: '8px',
                          width: '90%',
                          marginBottom: '4px',
                        }}
                      >
                        {clinicSelected?.clinic?.name ||
                          clinicSelected?.location?.name ||
                          'Clinic Name'}
                      </Typography>
                      <Divider />
                      <Button
                        variant='blank'
                        style={{
                          width: '100%',
                          height: '36px',
                          padding: '0 8px',
                        }}
                      >
                        <a
                          href={`/clinics/${clinicSelected?.clinic?.slug}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{
                            textDecoration: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            color: '#303030',
                          }}
                        >
                          <Typography
                            variant='body1'
                            color='inherit'
                            style={{
                              margin: '8px 0px',
                            }}
                          >
                            Clinic Page
                          </Typography>
                          <Icon name='info' color='inherit' />
                        </a>
                      </Button>
                      <Divider />
                      <Button
                        variant='blank'
                        color='#303030'
                        style={{
                          width: '100%',
                          height: '36px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0 8px',
                        }}
                        onClick={() => {
                          setModalInfoOpen(!modalInfoOpen)
                        }}
                      >
                        <Typography variant='body1' style={{ margin: '8px 0px' }}>
                          Information
                        </Typography>
                        <Icon name={modalInfoOpen ? 'closeArrow' : 'openArrow'} color='inherit' />
                      </Button>
                      <Divider />
                      <Collapse in={modalInfoOpen}>
                        <Box padding='8px'>
                          <Typography variant='body1'>
                            {clinicSelected?.location?.address}
                            <br />
                            {clinicSelected?.location?.city}, {clinicSelected?.location?.state}{' '}
                            {clinicSelected?.location?.zipcode}
                            <br />
                            {clinicSelected?.location?.phone}
                            <br />
                          </Typography>
                        </Box>
                        <Divider />
                      </Collapse>
                      <Button
                        variant='blank'
                        style={{
                          width: '100%',
                          height: '36px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0 8px',
                        }}
                        onClick={() => {
                          setModalHoursOpen(!modalHoursOpen)
                        }}
                      >
                        <Typography variant='body1' style={{ margin: '8px 0px' }}>
                          Hours
                        </Typography>
                        <Icon name={modalHoursOpen ? 'closeArrow' : 'openArrow'} color='inherit' />
                      </Button>
                      <Divider />
                      <Collapse in={modalHoursOpen}>
                        <Box padding='8px'>
                          <Typography variant='body1'>
                            Monday: 9:00 AM - 5:00 PM
                            <br />
                            Tuesday: 9:00 AM - 5:00 PM
                            <br />
                            Wednesday: 9:00 AM - 5:00 PM
                            <br />
                            Thursday: 9:00 AM - 5:00 PM
                            <br />
                            Friday: 9:00 AM - 5:00 PM
                            <br />
                            Saturday: Closed
                            <br />
                            Sunday: Closed
                          </Typography>
                        </Box>
                        <Divider />
                      </Collapse>
                      <Button
                        variant='blank'
                        style={{
                          width: '100%',
                          height: '36px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0 8px',
                        }}
                        onClick={() => {
                          setModalSpecialtiesOpen(!modalSpecialtiesOpen)
                        }}
                      >
                        <Typography variant='body1' style={{ margin: '8px 0px' }}>
                          Specialties
                        </Typography>
                        <Icon
                          name={modalSpecialtiesOpen ? 'closeArrow' : 'openArrow'}
                          color='inherit'
                        />
                      </Button>
                      <Divider />
                      <Collapse in={modalSpecialtiesOpen}>
                        <Box padding='8px'>
                          <Typography variant='body1'>
                            Specialty 1
                            <br />
                            Specialty 2
                            <br />
                            Specialty 3
                          </Typography>
                        </Box>
                      </Collapse>
                    </Box>
                  </Box>
                </Slide>
              </Hidden>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <NationalMap
                  locations={locations}
                  hitClick={setLocationSelected}
                  newCenter={clinicSelected.geopoint}
                />
              </Grid>
            </>
          )}
        </Grid>
      </MapEffectsProvider>
    </>
  )
}

export default NationalMapPage
