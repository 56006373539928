import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Heading from '../stories/Heading'
import { Card } from '../stories/Card'
import Link from '../stories/Link'
import NavLink from '../stories/NavLink'
import CollapsableListItem from '@app/components/CollapsableListItem'
import HowItWorksMini from '@app/components/HowItWorksMini'

const Questions = () => {
  const { search } = useLocation()
  const [currentIndex, setCurrentIndex] = useState('')
  const ref = new URLSearchParams(search).get('ref')

  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Question Page',
    })
  }, [])

  const onChangeCollapse = e => {
    setCurrentIndex(e)
  }

  return (
    <Container maxWidth='lg'>
      <Section>
        <Heading variant='h1'>Questions</Heading>
        <Card spacing='none'>
          <List disablePadding>
            <CollapsableListItem
              key='question1'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  How do I get in contact with <i>mishe</i> ?
                </span>
              }
            >
              <Typography variant='body1'>
                Contact us at{' '}
                <Link type='external' display='inline' to='mailto:help@mishe.co'>
                  help@mishe.co
                </Link>{' '}
                or{' '}
                <Link type='external' display='inline' to='tel:+1 (601) 647–4326'>
                  1-855-MISHECO
                </Link>{' '}
                (
                <Link type='external' display='inline' size='small' to='tel:+1 (601) 647–4326'>
                  1-855-647-4326
                </Link>
                ).
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question2'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  What is <i>mishe</i> ?
                </span>
              }
            >
              <Typography variant='body1'>
                <i>Mishe</i> is the company that puts consumers in control of their care by
                connecting them directly with providers on the basis of transparent and affordable
                pricing. <i>mishe</i>’s direct-to-consumer e-commerce healthcare marketplace is the
                first of many tools we will use to drive healthcare transparency, affordability,
                collaboration, and innovation.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question3'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  How does <i>mishe</i> work?
                </span>
              }
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={9} md={5}>
                  <HowItWorksMini />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box mt={{ xs: 2, md: 0 }}>
                    <Typography variant='body1'>
                      <strong>1. Search and compare prices.</strong>
                      <br />
                      Search or filter by service, description, specialty, organization, provider,
                      location or price.
                    </Typography>
                    <br />
                    <Typography variant='body1'>
                      <strong>2. Purchase care.</strong>
                      <br />
                      Pay for care online to lock in the <i>mishe</i> price.
                      <br />
                      Access your my <i>mishe</i> dashboard to view scheduling or walk-in
                      instructions and your voucher code (used as payment method).
                    </Typography>
                    <br />
                    <Typography variant='body1'>
                      <strong>3. Schedule service.</strong>
                      <br />
                      Contact your provider to schedule service (if you’re not contacted first).
                      <br />
                      Follow the scheduling or walk-in instructions listed in your my <i>
                        mishe
                      </i>{' '}
                      dashboard.
                    </Typography>
                    <br />
                    <Typography variant='body1'>
                      <strong>4. See your provider.</strong>
                      <br />
                      Present your voucher code during check-in to redeem care.
                    </Typography>
                    <br />
                    <NavLink to='/' bgcolor='primary'>
                      Search for Affordable Healthcare
                    </NavLink>
                  </Box>
                </Grid>
              </Grid>
            </CollapsableListItem>
            <CollapsableListItem
              key='question4'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  Who does <i>mishe</i> serve?
                </span>
              }
            >
              <Typography variant='body1'>
                <i>Mishe Connect&apos;s</i> model is intended to serve as many people as possible.
                <br />
                Whether you are uninsured, underinsured, a freelancer, a gig economy worker, a
                part-time worker, an expat, undocumented, traveling, sticking to a budget, in need
                of care not covered by your insurance plan, in between jobs, have unused HSA/FSA
                funds, or you have conventional insurance coverage and are in need of a
                straightforward shopping experience, <i>mishe</i> helps all consumers eliminate
                financial unknowns and burdens associated with healthcare consumption.
                <br />
                <br />
                <strong>
                  If you need emergency care, please call 911 or go to your nearest emergency room.
                </strong>
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question5'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  Does <i>mishe</i> cost anything for consumers?
                </span>
              }
            >
              <Typography variant='body1'>
                No, there is no cost to consumers who use <i>mishe</i>.<br />
                Consumers only pay for the services they choose and nothing more. We collect a
                nominal subscription fee from providers to help fund our mission to increase access
                to affordable care.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question6'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  Are providers on <i>mishe</i> licensed?
                </span>
              }
            >
              <Typography variant='body1'>
                They sure are! All providers are screened for active licensure and disciplinary
                history.{' '}
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question7'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Is my information safe?</span>}
            >
              <Typography variant='body1'>
                Data privacy is incredibly important to your friends at <i>mishe</i>. We do
                everything we can to maximize confidentiality. <i>mishe</i>’s multi-layer security
                features ensure privacy and discretion while protecting consumer and provider
                information. We have implemented administrative, physical and technical safeguards
                across our encrypted ecommerce marketplace and infrastructure to ensure HIPAA
                compliance and security.
                <br />
                <br />
                Contact us at{' '}
                <Link type='external' display='inline' to='mailto:help@mishe.co'>
                  help@mishe.co
                </Link>{' '}
                or{' '}
                <Link type='external' display='inline' to='tel:+1 (601) 647–4326'>
                  1-855-MISHECO
                </Link>{' '}
                (
                <Link type='external' display='inline' size='small' to='tel:+1 (601) 647–4326'>
                  1-855-647-4326
                </Link>
                ) to request access to or deletion of your data.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question8'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  Why are <i>mishe</i> prices so affordable?
                </span>
              }
            >
              <Typography variant='body1'>
                <i>mishe</i> helps providers set prices at the true cost of care, the discounted
                rates they would otherwise accept from insurance carriers.
                <br />
                These prices represent a fraction of the billed charges seen on surprise bills, and
                are also often more competitive than rates consumers pay towards deductibles.
                Upfront payment means no back-end billing costs or uncertainty for clinicians, which
                improves their bottom line and overall satisfaction.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question9'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  Can I use <i>mishe</i> if I have insurance?
                </span>
              }
            >
              <Typography variant='body1'>
                Yes, consumers with commercial health insurance or Medicaid may use <i>mishe</i> to
                purchase healthcare services.
                <br />
                Medicare beneficiaries may not purchase services covered by Medicare using{' '}
                <i>mishe</i>. The Balanced Budget Act of 1997 forbids private-pay contracts between
                patients and providers for services covered by Medicare.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question10'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  Does <i>mishe</i> carry the same restrictions as insurance coverage?
                </span>
              }
            >
              <Typography variant='body1'>
                With <i>mishe</i>, there are no provider network restrictions, no coverage
                restrictions, no authorizations, no denials, no questionnaires, no surprise bills,
                no deductibles, no coinsurance, no co-pays, and no premiums. What you pay for
                services chosen is all you pay, so you keep savings from care you don’t use.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question11'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={
                <span>
                  How does <i>mishe</i> benefit providers?
                </span>
              }
            >
              <Typography variant='body1'>
                Providers gain immediate access to consumers who previously avoided care due to cost
                concerns.
                <br />
                Upfront payment means no back-end billing costs or uncertainty for clinicians.{' '}
                <i>mishe</i> helps providers set prices for shoppable services and connect with
                patients on the basis of transparent pricing. Provider organizations can spread
                demand among providers and establish new offerings for services otherwise not
                covered. Fair pricing and guaranteed payment help reduce provider burnout and allows
                providers to focus on quality care.
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question12'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>How can I list my provider organization or facility?</span>}
            >
              <Typography variant='body1'>
                Contact us at{' '}
                <Link type='external' display='inline' to='mailto:help@mishe.co'>
                  help@mishe.co
                </Link>{' '}
                or{' '}
                <Link type='external' display='inline' to='tel:+1 (601) 647–4326'>
                  1-855-MISHECO
                </Link>{' '}
                (
                <Link type='external' display='inline' size='small' to='tel:+1 (601) 647–4326'>
                  1-855-647-4326
                </Link>
                ).
              </Typography>
            </CollapsableListItem>
            <CollapsableListItem
              key='question13'
              icon='question'
              currentIndex={currentIndex}
              onChangeCollapse={onChangeCollapse}
              title={<span>Can I use my FSA/HSA account to pay for care?</span>}
            >
              <Typography variant='body1'>
                Yes! <i>mishe</i> processes payments via Stripe and was assigned an HSA/FSA-eligible
                Merchant Category Code (MCC) so credit card companies know services are directly
                related to medical needs.
              </Typography>
            </CollapsableListItem>
          </List>
        </Card>
      </Section>
    </Container>
  )
}

export default Questions
