import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Highlight } from 'react-instantsearch'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import Zoom from '@mui/material/Zoom'
import Icon from '../stories/Icons'
import Collapse from '@mui/material/Collapse'
import Divider from '../stories/Divider'
import LogoText from '../assets/images/LogoText.svg'

const ServiceSearchCard = ({
  data = { msrp: null, referralPrice: null, network: null },
  clearSelection = false,
  onClick = null,
  buttonText = null,
  variant = null,
  exactMatch,
}) => {
  const {
    orderRequired,
    price,
    payerPrice,
    network,
    clinicName,
    referralPrice,
    misheCode,
    objectID,
    bundle,
    _rankingInfo,
    description,
    notEnrolledWithMishe,
  } = data
  const [open, setOpen] = useState(false)

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      padding={variant === 'homepage' ? '16px' : '0px'}
      sx={{
        flexDirection: {
          xs: 'column',
          md: open ? 'column' : 'row',
        },
      }}
    >
      <Box width='100%'>
        {exactMatch && (
          <Zoom in={exactMatch}>
            <Box color='#652d92'>
              <Typography
                variant='body1'
                weight='semibold'
                style={{ fontSize: '14px', marginLeft: '-4px' }}
              >
                Procedure Code Match
              </Typography>
            </Box>
          </Zoom>
        )}
        <Typography variant='h5' weight='bold' style={{ margin: '0px' }}>
          <Highlight attribute='name' hit={data} />
        </Typography>
        {bundle && (
          <>
            <Button variant='text' onClick={() => setOpen(!open)} size='small'>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                color='#652d92'
              >
                <Typography variant='body1' style={{ color: '#652d92' }}>
                  Bundled Services
                </Typography>
                <Box
                  style={{
                    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                  }}
                >
                  <Icon name='chevronRight' />
                </Box>
              </Box>
            </Button>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box paddingLeft='16px' color='#652d92' paddingBottom='8px' width='100%'>
                {data.bundleComponentsUI
                  .sort((a, b) => b.totalPrice - a.totalPrice)
                  .map((service, index) => (
                    <Box>
                      {console.log(service)}
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='flex-start'
                        key={index}
                        flexDirection={open ? 'column' : 'row'}
                      >
                        <Box display='flex' flexDirection='column'>
                          <Box display='flex' color='#652d92'>
                            <Typography variant='body1' style={{ fontSize: '14px' }}>
                              {service.name && service.name}
                            </Typography>
                            <Typography variant='body1' style={{ fontSize: '14px' }}>
                              &nbsp;
                              {service.count > 1 ? `(x${service.count})` : ''}
                            </Typography>
                          </Box>
                        </Box>
                        {service.cptCode && (
                          <Box width='100%' display='flex' justifyContent='space-between'>
                            <Typography variant='body1' weight='light' style={{ fontSize: '14px' }}>
                              Procedure Code:
                            </Typography>
                            <Typography variant='body1' weight='light' style={{ fontSize: '14px' }}>
                              {service.cptCode}
                            </Typography>
                          </Box>
                        )}
                        <Box width='100%' display='flex' justifyContent='space-between'>
                          {service.rendering && (
                            <Typography
                              variant='body1'
                              weight='light'
                              style={{
                                fontSize: '14px',
                              }}
                            >
                              {service.rendering}:
                            </Typography>
                          )}
                          <Typography
                            variant='body1'
                            weight='light'
                            style={{
                              fontSize: '14px',
                            }}
                          >
                            {service.clinicName}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider flexItem />
                    </Box>
                  ))}
              </Box>
            </Collapse>
          </>
        )}
        {clinicName && <Typography variant='body1'>Clinic: {clinicName}</Typography>}
        {!bundle && data?.rendering && <Typography variant='body1'>{data?.rendering}</Typography>}
        {variant === 'homepage' && _rankingInfo?.geoDistance && (
          // distance converted from meters to miles, add commas for readability
          <Typography variant='body1' weight='light'>
            Distance: {parseInt(_rankingInfo.geoDistance / 1609.344, 10).toLocaleString()} miles
          </Typography>
        )}
        {network?.name && variant !== 'homepage' && (
          <Typography variant='body1'>Plan: {network.name}</Typography>
        )}
        {variant === 'homepage' && price && (
          <Box color='#652d92'>
            <Typography variant='body1' weight='bold' color='inherit'>
              Price: ${price || payerPrice || '---'}
            </Typography>
          </Box>
        )}
        {variant === 'homepage' && data?.msrp && (
          <Typography variant='body1' weight='light'>
            MSRP: ${data.msrp}
          </Typography>
        )}
        {variant !== 'homepage' && (
          <Typography variant='body1' weight='light'>
            Price: ${referralPrice || price || '---'}
          </Typography>
        )}
      </Box>
      {!variant && onClick && (
        <Box
          padding='0px 16px'
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{
            padding: {
              xs: '16px 16px 0px 16px',
              md: '0px 16px',
            },
            flexDirection: {
              xs: 'column',
              md: open ? 'row' : 'column',
            },
          }}
        >
          {clearSelection ? (
            <Button
              variant='outlined'
              onClick={() => {
                onClick(data)
              }}
              style={{ whiteSpace: 'nowrap' }}
            >
              {buttonText}
            </Button>
          ) : (
            <>
              <Button
                size='medium'
                disabled={notEnrolledWithMishe}
                variant={notEnrolledWithMishe ? 'text' : 'contained'}
                onClick={() => {
                  onClick(data)
                }}
                sx={{
                  whiteSpace: {
                    xs: 'wrap',
                    md: 'nowrap',
                  },
                }}
              >
                {notEnrolledWithMishe
                  ? 'Service not eligible for same-day payment'
                  : 'Select Service'}
              </Button>
              {!notEnrolledWithMishe ? (
                <Box
                  color='#652d92'
                  display='flex'
                  alignItems='center'
                  gap='4px'
                  sx={{
                    padding: {
                      xs: '8px 8px 0px 8px',
                      md: '8px',
                    },
                  }}
                >
                  <Typography
                    variant='body1'
                    style={{
                      fontSize: '15px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Powered by
                  </Typography>
                  <img
                    src={LogoText}
                    alt='Mishe Logo'
                    style={{ height: '25px', marginBottom: '2px' }}
                  />
                </Box>
              ) : (
                <Box color='#652d92' display='flex' alignItems='center'>
                  <Typography
                    variant='body1'
                    align='center'
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {description}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
      {variant === 'homepage' && (
        <Box
          display='flex'
          flexDirection={open ? 'row' : 'column'}
          justifyContent={open ? 'center' : 'flex-end'}
          alignItems='flex-end'
          paddingX='8px'
          paddingTop={open ? '8px' : '0px'}
          width={open ? '100%' : '175px'}
          gap='8px'
        >
          {misheCode && (
            <Box
              style={{
                width: '100%',
                maxWidth: '150px',
              }}
            >
              <a href={`/plans/${network.slug}/service/${misheCode}`} style={{ margin: '0px 8px' }}>
                <Button variant='outlined' size='small' fullWidth>
                  <Typography variant='body1'>Learn More</Typography>
                </Button>
              </a>
            </Box>
          )}
          {objectID && !orderRequired && (
            // <Box
            //   className='get-started-button-provider'
            //   style={{
            //     width: '100%',
            //     maxWidth: '150px'
            //   }}
            // >
            //   <a style={{ margin: '0px 8px' }}>
            //     <Button variant='outlined' size='small' fullWidth>
            //       <Typography variant='body1'>Get Started</Typography>
            //     </Button>
            //   </a>
            // </Box>
            <Box
              style={{
                width: '100%',
                maxWidth: '150px',
              }}
            >
              <a
                href={`/plans/${network.slug}/service/${objectID}/checkout`}
                style={{ margin: '0px 8px' }}
              >
                <Button variant='outlined' size='small' fullWidth>
                  <Typography variant='body1'>Book Now</Typography>
                </Button>
              </a>
            </Box>
          )}
        </Box>
      )}
      {/* <Divider flexItem style={{ marginTop: '16px' }} /> */}
    </Box>
  )
}

ServiceSearchCard.propTypes = {
  data: PropTypes.shape({
    msrp: PropTypes.number,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    network: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    clinicName: PropTypes.string,
    rendering: PropTypes.string,
    referralPrice: PropTypes.number,
    misheCode: PropTypes.string,
    objectID: PropTypes.string,
    _rankingInfo: PropTypes.shape({
      geoDistance: PropTypes.number,
    }),
    bundle: PropTypes.bool,
    bundledServiceNames: PropTypes.arrayOf(PropTypes.string),
  }),
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  variant: PropTypes.string,
}

export default ServiceSearchCard
