import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { formatDateForUI } from '@app/libs/formatters'
import MemberPlanCard from '@app/components/MemberPlanCard'
import PatientPlanCopayInfo from '@app/components/PatientPlanCopayInfo'
import VoucherDetailsEntry from '@app/containers/VoucherDetailsEntry'
import renderGauge from '../Guages'

const HEADERS = ['Name', 'Type', 'Spend Amount', 'Family Amount Spent', 'Total Services']
const BREAKDOWN_HEADERS = ['Name', 'Type', 'Voucher', 'Date', 'Amount Paid']

const SpendDown = (planID, plan, profile) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginBottom: '32px',
        }}
      >
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Box display='flex' justifyContent='center' alignItems='center' sx={{ mx: 3 }}>
            {renderGauge(plan.spend().remaining, plan.spend().max, 'Remaining Balance')}
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center' sx={{ mx: 3 }}>
            {renderGauge(plan.spend().spent, plan.spend().max, 'Total Spent')}
          </Box>
        </Box>
      </Box>

      <Typography variant='h6' align='center'>
        Family Summary
      </Typography>
      <TableContainer component={Paper} sx={{ width: '100%', mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {HEADERS.map(header => (
                <TableCell key={header}>
                  <Typography variant='h6'>{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {plan.familyVouchers.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>
                  {row.vouchers.reduce((total, voucher) => total + voucher.amountPaid, 0)}
                </TableCell>
                <TableCell>${plan.totalFamilySpend()}</TableCell>
                <TableCell>{row.vouchers.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {plan.memberType == 1 && (
        <>
          <Typography variant='h6' sx={{ mt: 4 }} align='center'>
            Family Breakdown
          </Typography>
          <TableContainer component={Paper} sx={{ width: '100%', mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {BREAKDOWN_HEADERS.map(header => (
                    <TableCell key={header}>
                      <Typography variant='h6'>{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {plan.familyBreakdown().map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>
                      <VoucherDetailsEntry voucher={row.voucher}>
                        <Button size='small' variant='outlined'>
                          <Typography variant='body1' family='poppins'>
                            {row.voucher.voucherName}
                          </Typography>
                        </Button>
                      </VoucherDetailsEntry>
                    </TableCell>

                    <TableCell>{formatDateForUI(row.voucher.date, true) || '---'}</TableCell>
                    <TableCell>{row.voucher.amountPaid}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

export default SpendDown
