import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Grow from '@mui/material/Grow'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'
import locationPin from '../assets/images/locationPin.svg'
import locationPinBundle from '../assets/images/locationPinBundle.svg'
import locationPinOutOfNetwork from '../assets/images/locationPinOutOfNetwork.svg'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import FormattedText from '../stories/FormatFromDB'
import { useMapEffects } from '../context/MapEffects'
import Divider from '../stories/Divider'

const LocationMarker = ({
  data,
  locationID,
  slug = '',
  locationName = '',
  onClick = () => {},
  lat = 0,
  lng = 0,
  variant = 'default',
  outOfNetwork = false,
  address = '',
  city = '',
  state = '',
  hours = '',
  services = [],
  onPlanOnly = false,
  handleSelectLocation,
  handleSwap,
  activeStep,
  fromBundle,
}) => {
  const _geoloc = [{ lat, lng }]
  const { hovered, handleHover, clicked, handleClick } = useMapEffects()

  useEffect(() => {
    if (clicked === locationID && handleSelectLocation) {
      handleSelectLocation(data)
    }
  }, [clicked, locationID, handleSelectLocation, data])

  return (
    <>
      {variant === 'clinicPage' && (
        <Box
          style={{
            width: '0px',
            height: '0px',
            display: onPlanOnly && outOfNetwork ? 'none' : 'block',
          }}
        >
          {(hovered === locationID || hovered?.includes(locationID)) && (
            <Grow in timeout={{ enter: 250, exit: 250 }}>
              <Box
                position='absolute'
                zIndex='1000'
                top={outOfNetwork ? '-78px' : '-58px'}
                left='8px'
                bgcolor={outOfNetwork ? '#bfcbf8' : '#f0e7f7'}
                borderRadius='5px 5px 5px 0'
                padding='4px'
                boxShadow='0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                whiteSpace='nowrap'
                color='#325ae8'
              >
                {outOfNetwork && (
                  <Typography
                    variant='body1'
                    color='inherit'
                    weight='medium'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '12px',
                    }}
                  >
                    Off Plan
                  </Typography>
                )}
                <Typography
                  variant='h5'
                  color={outOfNetwork ? 'inherit' : 'primary'}
                  weight='medium'
                  family='poppins'
                  style={{ margin: '0px' }}
                >
                  {locationName}
                </Typography>
              </Box>
            </Grow>
          )}
          {clicked === locationID && (
            <Grow in={clicked === locationID} timeout={{ enter: 500, exit: 500 }}>
              <Box
                position='absolute'
                zIndex='1000'
                top='-58px'
                left='20px'
                bgcolor={outOfNetwork ? '#bfcbf8' : '#f0e7f7'}
                borderRadius='5px'
                padding={outOfNetwork ? '8px 12px 12px 12px' : '12px'}
                boxShadow='0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                whiteSpace='nowrap'
                color='#325ae8'
              >
                <Box
                  position='absolute'
                  top='-12px'
                  right='-12px'
                  zIndex='1001'
                  bgcolor={outOfNetwork ? '#bfcbf8' : '#f0e7f7'}
                  boxShadow='0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                  borderRadius='50%'
                >
                  <IconButton
                    size='small'
                    style={{
                      color: '#325ae8',
                    }}
                    onClick={() => {
                      handleHover(false)
                      handleClick(false)
                    }}
                  >
                    <Icon name='close' color={outOfNetwork ? 'inherit' : 'primary'} />
                  </IconButton>
                </Box>
                {outOfNetwork && (
                  <Typography
                    variant='body1'
                    color='inherit'
                    weight='medium'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '12px',
                    }}
                  >
                    Off Plan
                  </Typography>
                )}
                <Typography
                  variant='h5'
                  color={outOfNetwork ? 'inherit' : 'primary'}
                  weight='medium'
                  family='poppins'
                  style={{ margin: '0px 0px 4px 0px' }}
                >
                  {locationName}
                </Typography>
                <Box paddingLeft='4px'>
                  <Typography
                    variant='body1'
                    color={outOfNetwork ? 'inherit' : 'primary'}
                    weight='light'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '14px',
                      lineHeight: '120%',
                    }}
                  >
                    {address}
                  </Typography>
                  <Typography
                    variant='body1'
                    color={outOfNetwork ? 'inherit' : 'primary'}
                    weight='light'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '14px',
                      lineHeight: '120%',
                    }}
                  >
                    {city}, {state}
                  </Typography>
                </Box>
                {hours && (
                  <>
                    <Box>
                      <Divider
                        style={{
                          margin: '4px 0px',
                          backgroundColor: `${outOfNetwork ? 'rgb(50, 90, 232)' : '#652d92'}`,
                        }}
                      />
                    </Box>
                    <Typography
                      variant='body1'
                      color={outOfNetwork ? 'inherit' : 'primary'}
                      weight='medium'
                      family='poppins'
                      style={{ margin: '0px' }}
                    >
                      Hours
                    </Typography>
                    <Box paddingLeft='4px'>
                      <FormattedText
                        text={hours}
                        textType='body1'
                        weight='light'
                        align='left'
                        color={outOfNetwork ? 'inherit' : 'primary'}
                        mt={0}
                        style={{
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                        }}
                      />
                    </Box>
                  </>
                )}
                {services && (
                  <>
                    <Box>
                      <Divider
                        style={{
                          margin: '4px 0px',
                          backgroundColor: `${outOfNetwork ? 'rgb(50, 90, 232)' : '#652d92'}`,
                        }}
                      />
                    </Box>
                    <Typography
                      variant='body1'
                      color={outOfNetwork ? 'inherit' : 'primary'}
                      weight='medium'
                      family='poppins'
                      style={{ margin: '0px' }}
                    >
                      Services
                    </Typography>
                    <Box paddingLeft='4px'>
                      {services.map(service => {
                        const { name, price, id } = service
                        return (
                          <Box key={id}>
                            <Typography
                              variant='body1'
                              color={outOfNetwork ? 'inherit' : 'primary'}
                              weight='light'
                              family='poppins'
                              style={{
                                margin: '0px',
                                fontSize: '14px',
                              }}
                            >
                              {name} - ${price}
                            </Typography>
                          </Box>
                        )
                      })}
                    </Box>
                  </>
                )}
              </Box>
            </Grow>
          )}
          <Box
            onMouseEnter={() => handleHover(true, [locationID])}
            onMouseLeave={() => handleHover(false)}
            onMouseDown={() => {
              handleHover(false)
              handleClick(true, locationID)
            }}
            style={{
              position: 'absolute',
              top: '-36px',
              left: '-10px',
              cursor: 'pointer',
            }}
          >
            <img
              src={
                outOfNetwork
                  ? locationPinOutOfNetwork
                  : fromBundle
                    ? locationPinBundle
                    : locationPin
              }
              alt='location pin'
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Box>
      )}
      {variant === 'pharmacyPage' && (
        <Box
          style={{
            position: 'relative',
            width: '0px',
            height: '0px',
          }}
        >
          {(hovered === locationID || hovered?.includes(locationID)) && (
            <Grow in timeout={{ enter: 250, exit: 250 }}>
              <Box
                position='absolute'
                zIndex='1000'
                top='-58px'
                left='8px'
                bgcolor='#f0e7f7'
                borderRadius='5px 5px 5px 0'
                padding='4px'
                boxShadow='0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                whiteSpace='nowrap'
                color='#325ae8'
              >
                <Typography
                  variant='h5'
                  color='primary'
                  weight='medium'
                  family='poppins'
                  style={{
                    margin: '0px',
                    textTransform: 'capitalize',
                  }}
                >
                  {locationName}
                </Typography>
              </Box>
            </Grow>
          )}
          {clicked === locationID && (
            <Grow in={clicked === locationID} timeout={{ enter: 500, exit: 500 }}>
              <Box
                position='absolute'
                zIndex='1000'
                top='-58px'
                left='20px'
                bgcolor='#f0e7f7'
                borderRadius='5px'
                padding='12px'
                boxShadow='0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                whiteSpace='nowrap'
                color='#325ae8'
              >
                <Box
                  position='absolute'
                  top='-12px'
                  right='-12px'
                  zIndex='1001'
                  bgcolor='#f0e7f7'
                  boxShadow='0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                  borderRadius='50%'
                >
                  <IconButton
                    size='small'
                    style={{
                      color: '#325ae8',
                    }}
                    onClick={() => {
                      handleHover(false)
                      handleClick(false)
                    }}
                  >
                    <Icon name='close' color='primary' />
                  </IconButton>
                </Box>
                <Typography
                  variant='h5'
                  color='primary'
                  weight='medium'
                  family='poppins'
                  style={{
                    margin: '0px 0px 4px 0px',
                    textTransform: 'capitalize',
                  }}
                >
                  {locationName}
                </Typography>
                <Box paddingLeft='4px'>
                  <Typography
                    variant='body1'
                    color='primary'
                    weight='light'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '14px',
                      lineHeight: '120%',
                      textTransform: 'capitalize',
                    }}
                  >
                    {address}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='primary'
                    weight='light'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '14px',
                      lineHeight: '120%',
                      textTransform: 'capitalize',
                    }}
                  >
                    {city}, {state}
                  </Typography>
                </Box>
                {hours && (
                  <>
                    <Box>
                      <Divider
                        style={{
                          margin: '4px 0px',
                          backgroundColor: '#652d92',
                        }}
                      />
                    </Box>
                    <Typography
                      variant='body1'
                      color='primary'
                      weight='medium'
                      family='poppins'
                      style={{ margin: '0px' }}
                    >
                      Hours
                    </Typography>
                    <Box paddingLeft='4px'>
                      <FormattedText
                        text={hours}
                        textType='body1'
                        weight='light'
                        align='left'
                        color='primary'
                        mt={0}
                        style={{
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                        }}
                      />
                    </Box>
                  </>
                )}
                <Box paddingTop='8px'>
                  <Button
                    variant='contained'
                    size='small'
                    style={{
                      backgroundColor: '#7004c6',
                      borderRadius: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleSwap(activeStep + 1)
                    }}
                  >
                    <Typography variant='body1' weight='light' family='poppins'>
                      Select this Pharmacy
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grow>
          )}
          <Box
            onMouseEnter={() => handleHover(true, [locationID])}
            onMouseLeave={() => handleHover(false)}
            onMouseDown={() => {
              handleHover(false)
              handleClick(true, locationID)
            }}
            style={{
              position: 'absolute',
              top: '-36px',
              left: '-10px',
              cursor: 'pointer',
            }}
          >
            <img src={locationPin} alt='location pin' style={{ cursor: 'pointer' }} />
          </Box>
        </Box>
      )}
      {variant === 'default' && (
        <Box
          style={{
            position: 'absolute',
            top: '-21px',
          }}
        >
          <Button
            variant='blank'
            onClick={() => {
              onClick({ slug, locationName, _geoloc })
            }}
            style={{
              width: '0px',
              height: '0px',
              minWidth: '0px',
              padding: '0px',
              margin: '0px',
            }}
          >
            <img
              src={outOfNetwork ? locationPinOutOfNetwork : locationPin}
              alt='location pin'
              style={{ cursor: 'pointer' }}
            />
          </Button>
        </Box>
      )}
    </>
  )
}

LocationMarker.propTypes = {
  locationID: PropTypes.string.isRequired,
  locationName: PropTypes.string,
  slug: PropTypes.string,
  onClick: PropTypes.func,
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  outOfNetwork: PropTypes.bool,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  hours: PropTypes.string,
  services: PropTypes.array,
  onPlanOnly: PropTypes.bool,
}

export default LocationMarker
