import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import BlogFunction from '@app/containers/BlogFunction'
import BlogBody from '@app/components/BlogBody'
import LiveChat from '../stories/LiveChat'
import Loader from '../stories/Loader'
import Container from '../stories/Container'
import Section from '../stories/Section'

const BlogPage = () => {
  const { slug } = useParams()

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Blog',
      slug,
    })
  }, [])

  const getIds = content => {
    try {
      const ids = []
      content.forEach(item => {
        if (item.id !== 'undefined' && item.id) {
          ids.push(item.id)
        }
      })
      return ids
    } catch (error) {
      return []
    }
  }

  return (
    <>
      <BlogFunction>
        {blog => {
          if (!blog.content) {
            return (
              <Section>
                <Container>
                  <Loader />
                </Container>
              </Section>
            )
          }
          return (
            <>
              <Helmet>
                <title>{blog.metaTitle && blog.metaTitle}</title>
                <meta property='og:title' content={blog.metaTitle && blog.metaTitle} />
                <meta
                  name='description'
                  property='og:description'
                  content={blog.metaDescription && blog.metaDescription}
                />
                <meta property='og:url' content={`https://mishe.co/blog/${slug}`} />
                <link rel='canonical' href={`https://mishe.co/blog/${slug}`} />
              </Helmet>
              <BlogBody
                markdownContent={blog.markdownContent}
                authorName={blog.authorName}
                authorImage={blog.authorImageURL}
                authorSocials={blog.authorSocials}
                publishedOn={blog.publishedOn}
                tableOfContents={getIds(blog.content)}
                {...blog}
              />
              <LiveChat />
            </>
          )
        }}
      </BlogFunction>
    </>
  )
}

export default BlogPage
