import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { collection } from '../firebase/firestore'
import { useVouchers } from '../context/Vouchers'
import Vouchers from '@app/components/PatientVouchers'
import EmptyTab from '@app/components/EmptyTab'
import Modal from '@app/components/ClaimVoucherModal'
import { useCurrentProfile } from '../context/CurrentUser'

const PatientVouchers = () => {
  const { vouchers, changeVouchers, externalClaims, changeExternalClaims } = useVouchers()
  const { uid } = useCurrentProfile()

  const { search } = useLocation()
  const slug = new URLSearchParams(search).get('claim')
  const flag = true

  useEffect(() => {
    let unsubscribeVouchers, unsubscribeClaims
    if (uid) {
      unsubscribeVouchers = collection('vouchers')
        .where('user.id', '==', uid)
        .onSnapshot(docs => {
          return changeVouchers(docs, true)
        })

      unsubscribeClaims = collection('external-claims')
        .where('patient_info.user_id', '==', uid)
        .onSnapshot(docs => {
          return changeExternalClaims(docs, true)
        })
    }
    return () => {
      if (unsubscribeVouchers) unsubscribeVouchers()
      if (unsubscribeClaims) unsubscribeClaims()
    }
  }, [uid, changeVouchers, changeExternalClaims])

  const allClaimsAndVouchers = [...vouchers, ...externalClaims]

  if (allClaimsAndVouchers.length === 0)
    return <EmptyTab>You haven&apos;t purchased any vouchers yet.</EmptyTab>
  return (
    <>
      {slug && <Modal vouchers={null} flag={flag || false} />}
      <Vouchers rows={allClaimsAndVouchers} />
    </>
  )
}

export default PatientVouchers
