import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'
import AppBar from '../stories/AppBar'
import NavLink from '../stories/NavLink'
import DropDownMenu from './DropDownMenu'
import Logo from '../stories/LogoWhite'
import TopCorner from './TopCorner'

import { useWhiteLabelingContext } from '../context/WhiteLabelingContext'

const TopBar = () => {
  const {
    logoSource,
    planColorSecondary,
    planColorPrimary,
    planSlug,
    planPhoneNumber,
    planSMSNumber,
  } = useWhiteLabelingContext()

  return (
    <AppBar primaryColor={planColorPrimary} secondaryColor={planColorSecondary}>
      <Box display='flex' alignItems='center' flexGrow={1}>
        <NavLink to={planSlug ? `/plans/${planSlug}` : '/'}>
          <Logo size='semiLarge' source={logoSource} />
        </NavLink>
      </Box>
      {/* TODO replace hidden with responsive design or breakpoint components */}
      <Hidden mdDown>
        <Box display='flex' alignItems='center' flexGrow={1} color={planColorSecondary || '#fff'}>
          <DropDownMenu
            text='24/7 Support'
            primaryColor={planColorPrimary}
            secondaryColor={planColorSecondary}
          >
            {(planPhoneNumber || planColorPrimary === '#652d92') && (
              <Box color={planColorSecondary || '#fff'} width='100%'>
                <NavLink
                  to={planPhoneNumber ? `tel:${planPhoneNumber}` : 'tel:855-647-4326'}
                  align='left'
                  width='full'
                  external
                >
                  Call
                </NavLink>
              </Box>
            )}
            <Box color={planColorSecondary || '#fff'} width='100%'>
              <NavLink align='left' width='full' onClick={() => window?.Intercom('show')}>
                Chat
              </NavLink>
            </Box>
            {(planSMSNumber || planColorPrimary === '#652d92') && (
              <Box color={planColorSecondary || '#fff'} width='100%'>
                <NavLink
                  to={planSMSNumber ? `sms:${planSMSNumber}` : 'sms:855-647-4326'}
                  align='left'
                  width='full'
                  external
                  style={{ borderRadius: '0 0 21.125px 21.125px' }}
                >
                  SMS
                </NavLink>
              </Box>
            )}
          </DropDownMenu>
        </Box>
      </Hidden>
      <Box display='flex' alignItems='center'>
        <TopCorner primaryColor={planColorPrimary} secondaryColor={planColorSecondary} />
      </Box>
    </AppBar>
  )
}

export default TopBar
