import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import { Toggle } from '../hooks/useCollapse'
import Divider from '../stories/Divider'
import Icon from '../stories/Icons'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const MuiBox = styled(Box)`
  background: #ffffff;
  border: '1px solid #652d92';
  box-sizing: border-box;
  box-shadow: 3px 3px 10px rgba(88, 28, 134, 0.1);
  border-radius: 10px;
  &:hover {
    border: 1px solid #662d91;
    box-sizing: border-box;
    box-shadow: 3px 3px 10px rgba(88, 28, 134, 0.1);
  }
`

const CollapsableListItem = ({
  icon = 'info',
  title = null,
  value = 'info',
  onChangeCollapse,
  currentIndex = 'info',
  children,
}) => {
  const { planColorPrimary } = useWhiteLabelingContext()

  const PlanColorPrimaryBox = styled(Box)`
    background: #ffffff;
    border: '1px solid ${planColorPrimary}';
    box-sizing: border-box;
    box-shadow: 3px 3px 10px ${planColorPrimary}dd;
    border-radius: 10px;
    &:hover {
      border: 1px solid ${planColorPrimary};
      box-sizing: border-box;
      box-shadow: 3px 3px 10px ${planColorPrimary}dd;
    }
  `

  return (
    <Toggle>
      {({ open, idx, handleClick, handleClose }) => (
        <>
          {planColorPrimary ? (
            <PlanColorPrimaryBox mb={1}>
              <ListItem
                button
                onClick={
                  idx === currentIndex
                    ? handleClose
                    : e => {
                        handleClick(e, value)
                        onChangeCollapse(value)
                      }
                }
              >
                <ListItemIcon>
                  <Box py={2}>
                    <Icon name={icon} color={open ? 'primary' : 'inherit'} />
                  </Box>
                </ListItemIcon>
                <ListItemText>
                  <Box fontWeight={idx === currentIndex ? 500 : 300}>{title}</Box>
                </ListItemText>

                {idx === currentIndex ? <Icon name='closeArrow' /> : <Icon name='openArrow' />}
              </ListItem>
              <Collapse in={idx === currentIndex} timeout='auto' unmountOnExit>
                <Divider variant='middle' />
                <ListItem>
                  <Box p={{ xs: 1, sm: 3 }}>{children}</Box>
                </ListItem>
              </Collapse>
            </PlanColorPrimaryBox>
          ) : (
            <MuiBox mb={1}>
              <ListItem
                button
                onClick={
                  idx === currentIndex
                    ? handleClose
                    : e => {
                        handleClick(e, value)
                        onChangeCollapse(value)
                      }
                }
              >
                <ListItemIcon>
                  <Box py={2}>
                    <Icon name={icon} color={open ? 'primary' : 'inherit'} />
                  </Box>
                </ListItemIcon>
                <ListItemText>
                  <Box fontWeight={idx === currentIndex ? 500 : 300}>{title}</Box>
                </ListItemText>

                {idx === currentIndex ? <Icon name='closeArrow' /> : <Icon name='openArrow' />}
              </ListItem>
              <Collapse in={idx === currentIndex} timeout='auto' unmountOnExit>
                <Divider variant='middle' />
                <ListItem>
                  <Box p={{ xs: 1, sm: 3 }}>{children}</Box>
                </ListItem>
              </Collapse>
            </MuiBox>
          )}
        </>
      )}
    </Toggle>
  )
}

CollapsableListItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
  value: PropTypes.string,
  currentIndex: PropTypes.string,
  onChangeCollapse: PropTypes.func.isRequired,
}

export default CollapsableListItem
