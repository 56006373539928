import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import BlogBody from '@app/components/IntroBlogBody'
import LiveChat from '../stories/LiveChat'

const IntroToMisheBlog = () => {
  const { search } = useLocation()

  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Intro To Mishe Blog',
    })
  }, [])

  return (
    <>
      <BlogBody />
      <LiveChat />
    </>
  )
}

export default IntroToMisheBlog
