import { useEffect } from 'react'
import Container from '../../stories/Container'
import Section from '../../stories/Section'
import Heading from '../../stories/Heading'
import {
  TermsBlock as Block,
  TermsText as Text,
  TermsTitle as Title,
  TermsOrderedList as List,
  TermsListItem as Item,
} from '../../stories/Terms'
import Bold from '../../stories/Bold'

const BAAPage = () => {
  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'BAA Page',
    })
  }, [])

  return (
    <Container>
      <Section>
        <Heading variant='h1'>HIPAA Business Associate Agreement (BAA)</Heading>

        <Block id='baa0'>
          <Text>
            This HIPAA Business Associate Agreement (<Bold>&quot;Agreement&quot;</Bold>) is entered
            into by and between Mishe, INC (hereinafter <Bold>&quot;Business Associate&quot;</Bold>)
            and you (hereinafter <Bold>&quot;Covered Entity&quot;</Bold>) &quot;You&quot;,
            &quot;your&quot;, &quot;yourself&quot; and &quot;Covered Entity&quot; means you, as an
            individual, and the legal entity on behalf of which you are acting as the authorized
            agent or legal representative as identified by you during registration with the App, if
            applicable.
          </Text>
          <Text>
            Covered Entity and Business Associate mutually agree to the terms of this{' '}
            <Bold>Agreement</Bold> to comply with the requirements of the Health Insurance
            Portability and Accountability Act of 1996 (<Bold>&quot;HIPAA&quot;</Bold>),
            HIPAA&apos;s implementing regulations, the Standards for Privacy of Individually
            Identifiable Health Information (the <Bold>&quot;Privacy Rule&quot;</Bold>) and the
            Security Standards for the Protection of Electronic Protected Health Information (the{' '}
            <Bold>&quot;Security Rule&quot;</Bold>) found at Title 45, Parts 160 and 164 of the Code
            of Federal Regulations, dealing with the security, confidentiality, integrity and
            availability of protected health or health-related information, including the Business
            Associate Agreement requirements at 45 §§ 164.314(a) and 164.504(e), as amended by the
            Health Information Technology for Economic and Clinical Health Act contained in Public
            Law 111-005, as it may be codified in the U.S. Code (<Bold>&quot;HITECH Act&quot;</Bold>
            ).
          </Text>
        </Block>

        <Block id='baa1'>
          <Title>1. Definitions</Title>
          <List type='a'>
            <Item>
              <Bold>Protected Health Information (PHI)</Bold> means any information, whether oral or
              recorded in any form or medium, that: (i) relates to the past, present or future
              physical or mental condition of any Individual; the provision of health care to an
              Individual; or the past, present or future payment of the provision of health care to
              an Individual; and (ii) identifies the Individual, or with respect to which there is a
              reasonable basis to believe the information can be used to identify the Individual.
              PHI includes demographic information unless such information is de-identified
              according to the Privacy Rule. &quot;Protected Health Information&quot; includes
              without limitation &quot;Electronic Protected Health Information&quot; as defined
              below.
            </Item>
            <Item>
              <Bold>Electronic Protected Health Information (ePHI)</Bold> means Protected Health
              Information, which is transmitted by Electronic Media (as defined in the HIPAA Privacy
              and Security Rule) or maintained in Electronic Media.
            </Item>
            <Item>
              <Bold>Individual</Bold> means the person who is the subject of PHI, and shall include
              a person who qualifies under the Privacy Rule as a personal representative of the
              Individual.
            </Item>
            <Item>
              Capitalized terms used in this Agreement, but not otherwise defined, shall have the
              same meaning as those terms in the Privacy Rule, Security Rule or HITECH Act.
            </Item>
          </List>
        </Block>

        <Block id='baa2'>
          <Title>2. Prohibition on Unauthorized Use or Disclosure of PHI.</Title>
          <Text>
            Business Associate shall not use or disclose any PHI received from or on behalf of
            Covered Entity except as permitted under the Privacy Rule, as permitted or required by
            this <Bold>Agreement</Bold>, as required by law, or as otherwise authorized in writing
            by Covered Entity.
          </Text>
        </Block>

        <Block id='baa3'>
          <Title>
            3. Business Associate agrees to comply with the minimum necessary requirements of the
            Privacy Rule.
          </Title>
        </Block>

        <Block id='baa4'>
          <Title>4. Use and Disclosure of Protected Health Information.</Title>
          <Text>
            . Except as described in Section 4, Business Associate may use or disclose PHI only for
            the following purpose(s):
          </Text>
          <List type='a'>
            <Item>
              Business Associate may make any and all uses of PHI necessary to perform its
              obligations to Covered Entity, including any obligations required pursuant to this
              Agreement. All other uses not authorized by this Agreement are prohibited unless
              specifically authorized by Covered Entity to Business Associate
            </Item>
          </List>
        </Block>

        <Block id='baa5'>
          <Title>5. Use of PHI for Certain of Business Associate&apos;s Operations.</Title>
          <Text>
            Business Associate may use and/or disclose PHI it creates for, or receives from, Covered
            Entity to the extent necessary for Business Associate&apos;s proper management and
            administration, or to carry out Business Associate&apos;s legal responsibilities, only
            if:
          </Text>
          <List type='a'>
            <Item>The disclosure is required by law; or</Item>
            <Item>
              Business Associate obtains reasonable assurances, evidenced by written contract, from
              any person or organization to which Business Associate shall disclose such PHI that
              such person or organization shall:
              <List type='i'>
                <Item>
                  hold such PHI in confidence and use or further disclose it only for the purpose
                  for which Business Associate disclosed it to the person or organization, or as
                  required by law; and
                </Item>
                <Item>
                  notify Business Associate, who shall in turn promptly notify Covered Entity, of
                  any instance which the person or organization becomes aware of in which the
                  confidentiality of such PHI was breached.
                </Item>
              </List>
            </Item>
          </List>
        </Block>

        <Block id='baa6'>
          <Title>6. The HITECH Act and Additional Use and Disclosure Obligations.</Title>
          <List type='a'>
            <Item>
              Sections 164.308, 164.310, 164.312 and 164.316 of Title 45, Code of Federal
              Regulations, shall apply to Business Associate in the same manner that such sections
              apply to Covered Entity. The additional requirements of the HITECH Act that relate to
              security and that are made applicable with respect to Covered Entity shall also be
              applicable to Business Associate and are, by this reference, incorporated into this
              Agreement.
            </Item>
            <Item>
              Business Associate may use and disclose PHI that Business Associate obtains or creates
              only if such use or disclosure, respectively, is in addition to the other requirements
              of this Agreement, in compliance with each applicable requirement of Section
              164.504(e) of Title 45, Code of Federal Regulations. The additional requirements of
              Subtitle D of the HITECH Act that relate to privacy and that are made applicable to
              Covered Entity shall also be applicable to Business Associate and, by this reference,
              are hereby incorporated into this Agreement.
            </Item>
            <Item>
              Section 16.504(e)(1)(ii) of Title 45, Code of Federal Regulations shall apply to
              Business Associate with respect to compliance with such subsection, in the same manner
              that such section applies to Covered Entity, with respect to compliance with the
              standards of Sections 164.502(e) and 164.504(e) of Title 45, Code of Federal
              Regulations, except that in applying such Section 164.504(e)(1)(ii), each reference to
              the Business Associate, with respect to a contract, shall be treated as a reference to
              the Covered Entity involved in such contract.
            </Item>
          </List>
        </Block>

        <Block id='baa7'>
          <Title>7. Safeguarding of PHI.</Title>
          <Text>
            Business Associate shall, as applicable, develop, implement, maintain, and use
            reasonable and appropriate administrative, technical, and physical safeguards to protect
            the security, confidentiality, integrity and availability of PHI, in any form or media,
            created, received, maintained or transmitted on behalf of the Covered Entity.
          </Text>
        </Block>

        <Block id='baa8'>
          <Title>8. Subcontractors and Agents.</Title>
          <Text>
            If Business Associate provides any PHI which was received from, or created for, Covered
            Entity to a subcontractor or agent, then Business Associate shall enter into an
            agreement with such subcontractor. Business Associate shall require such subcontractor
            or agent to agree to the same restrictions and conditions as are imposed on Business
            Associate by this Agreement.
          </Text>
        </Block>

        <Block id='baa9'>
          <Title>9. Security of e-PHI.</Title>
          <Text>
            Business Associate shall secure all e-PHI by a technology standard that renders PHI
            unusable, unreadable or indecipherable to unauthorized individuals and is consistent
            with guidance issued by the Secretary specifying the technologies and methodologies that
            render e-PHI unusable, unreadable or indecipherable to unauthorized individuals.
          </Text>
        </Block>

        <Block id='baa10'>
          <Title>10. Access to PHI.</Title>
          <Text>
            At the direction of Covered Entity, Business Associate agrees to provide access to any
            PHI held by Business Associate, which Covered Entity has determined to be part of
            Covered Entity&apos;s Designated Record Set, within thirty (30) calendar days of a
            request. This access will be provided to Covered Entity or, as directed by Covered
            Entity, to an Individual, in order to meet the requirements under the Privacy Rule.
          </Text>
        </Block>

        <Block id='baa11'>
          <Title>11. Amendment or Correction to PHI.</Title>
          <Text>
            At the direction of Covered Entity, Business Associate agrees to amend or correct PHI
            held by Business Associate and which Covered Entity has determined to be part of Covered
            Entity&apos;s Designated Record Set, within thirty (30) calendar days of such a request
            by Covered Entity.
          </Text>
        </Block>

        <Block id='baa12'>
          <Title>12. Reporting of Misuse or Unauthorized Disclosures of PHI.</Title>
          <Text>
            At the direction of Covered Entity, Business Associate agrees to amend or correct PHI
            held by Business Associate and which Covered Entity has determined to be part of Covered
            Entity&apos;s Designated Record Set, within thirty (30) calendar days of such a request
            by Covered Entity.
          </Text>
        </Block>

        <Block id='baa13'>
          <Title>13. Mitigating Effect of Misuse or Unauthorized Disclosures of PHI.</Title>
          <Text>
            Business Associate agrees to mitigate, to the extent practicable, any harmful effect
            that is known to Business Associate of a misuse or unauthorized disclosure of PHI by
            Business Associate in violation of the requirements of this Agreement.
          </Text>
        </Block>

        <Block id='baa14'>
          <Title>14. Tracking and Accounting of Disclosures.</Title>
          <Text>
            So that Covered Entity may meet its accounting obligations under the Privacy Rule
            Business Associate agrees as follows:
          </Text>
          <List type='a'>
            <Item>
              <Bold>Disclosure Tracking.</Bold> For each disclosure not excepted under subsection
              (b) below, Business Associate will record for each disclosure of PHI it makes to
              Covered Entity or a third party of PHI that Business Associate creates or receives for
              or from Covered Entity (i) the disclosure date, (ii) the name and (if known) address
              of the person or entity to whom Business Associate made the disclosure, (iii) a brief
              description of the PHI disclosed, and (iv) a brief statement of the purpose of the
              disclosure. For repetitive disclosures which Business Associate makes to the same
              person or entity, including the Covered Entity, for a single purpose, Business
              Associate may provide (i) the disclosure information for the first of these repetitive
              disclosures, (ii) the frequency, periodicity or number of these repetitive
              disclosures, and (iii) the date of the last of these repetitive disclosures. Business
              Associate will make this log of disclosure information available to the Covered Entity
              within thirty (30) business days of the Covered Entity&apos;s request.
            </Item>
            <Item>
              <Bold>Exceptions from Disclosure Tracking.</Bold> Business Associate need not record
              disclosure information or otherwise account for disclosures of PHI that meet each of
              the following conditions:
              <List type='i'>
                <Item>
                  the disclosures are permitted under this Agreement, or are expressly authorized by
                  Covered Entity in another writing; and,
                </Item>
                <Item>the disclosure is for one of the following purposes:</Item>
                <List type='a'>
                  <Item>Covered Entity&apos;s Treatment, Payment, or Health Care Operations;</Item>
                  <Item>
                    in response to a request from the Individual who is the subject of the disclosed
                    PHI, or
                  </Item>
                  <Item>that Individual&apos;s Personal Representative;</Item>
                  <Item>
                    made to persons involved in that Individual&apos;s health care or payment for
                    health care;
                  </Item>
                  <Item>for notification for disaster relief purposes;</Item>
                  <Item>for national security or intelligence purposes; or,</Item>
                  <Item>
                    to law enforcement officials or correctional institutions regarding inmates.
                  </Item>
                </List>
              </List>
            </Item>
            <Item>
              <Bold>Disclosure Tracking Time Periods.</Bold> Business Associate must have available
              for Covered Entity the disclosure information required by this section for the
              six-year period preceding Covered Entity&apos;s request for the disclosure
              information.
            </Item>
          </List>
        </Block>

        <Block id='baa15'>
          <Title>15. Accounting to Covered Entity and to Government Agencies.</Title>
          <Text>
            Business Associate shall make its internal practices, books, and records relating to the
            use and disclosure of PHI received from or on behalf of, or created for, Covered Entity
            available to Covered Entity, or at the request of Covered Entity, to the Secretary of
            the Department of Health and Human Services (HHS) or his/her designee, in a reasonable
            time and manner designated by Covered Entity or the Secretary or his/her designee, for
            the purpose of determining Covered Entity&apos;s compliance with the Privacy Rule.
            Business Associate shall promptly notify Covered Entity of communications with HHS
            regarding PHI provided by or created by Covered Entity and shall provide Covered Entity
            with copies of any information Business Associate has made available to HHS under this
            provision.
          </Text>
        </Block>

        <Block id='baa16'>
          <Title>16. Responsibilities of Covered Entity.</Title>
          <Text>
            With regard to the use and/or disclosure of PHI by the Business Associate, Covered
            Entity hereby agrees to do the following:
          </Text>
          <List type='a'>
            <Item>
              Inform the Business Associate of any limitations in the form of Notice of Privacy
              Practices that Covered Entity provides to individuals pursuant to 45 C.F.R. §164.520,
              to the extent that such limitation may affect Business Associate&apos;s use or
              disclosure of PHI.
            </Item>
            <Item>
              Inform the Business Associate of any changes in, or revocation of, the permission by
              an Individual to use or disclose PHI, to the extent that such limitation may affect
              Business Associate&apos;s use or disclosure of PHI.
            </Item>
            <Item>
              Notify the Business Associate, in writing and in a timely manner, of any restriction
              on the use or disclosure of PHI that Covered Entity has agreed to or is required to
              abide by under 45 CFR 164.522, to the extent that such restriction may impact in any
              manner the use and/or disclosure of PHI by the Business Associate under this Agreement
              or the Services Agreement, except if the Business Associate will use or disclose PHI
              for data aggregation or management if provided for by the Services Agreement;
              administration and/or legal responsibilities of the Business Associate.
            </Item>
            <Item>
              Not request Business Associate to use or disclose PHI in any manner that would not be
              permissible under the Privacy and Security Rule if done by the Covered Entity.
            </Item>
          </List>
        </Block>

        <Block id='baa17'>
          <Title>17. Term and Termination.</Title>
          <List type='a'>
            <Item>This Agreement shall take effect upon execution.</Item>
            <Item>
              In addition to the rights of the parties established by the underlying Agreement, if
              Covered Entity reasonably determines in good faith that Business Associate has
              materially breached any of its obligations under this <Bold>Agreement</Bold>, Covered
              Entity, in its sole discretion, shall have the right to:
              <List type='i'>
                <Item>
                  exercise any of its rights to reports, access and inspection under this Agreement;
                  and/or
                </Item>
                <Item>
                  require Business Associate to submit to a plan of monitoring and reporting, as
                  Covered Entity may determine necessary to maintain compliance with this Agreement;
                  and/or
                </Item>
                <Item>
                  provide Business Associate with a thirty (30) day period to cure the breach; or
                </Item>
                <Item>terminate the Agreement immediately.</Item>
              </List>
            </Item>
            <Item>
              Before exercising any of these options, Covered Entity shall provide written notice to
              Business Associate describing the violation and the action it intends to take.
            </Item>
            <Item>
              If Business Associate makes the determination that a material condition of performance
              has changed under this Agreement or that Covered Entity has breached a material term
              of this Agreement, Business Associate may provide thirty (30) days advance notice of
              its intention to terminate this Agreement.
            </Item>
          </List>
        </Block>

        <Block id='baa18'>
          <Title>18. Return or Destruction of PHI.</Title>
          <Text>
            Upon termination, cancellation, expiration or other conclusion of the Agreement,
            Business Associate shall:
          </Text>
          <List type='a'>
            <Item>
              Return to Covered Entity or, if return is not feasible, destroy all PHI and all Health
              Information in whatever form or medium that Business Associate received from or
              created on behalf of Covered Entity. This provision shall also apply to all PHI that
              is in the possession of subcontractors or agents of Business Associate. In such case,
              Business Associate shall retain no copies of such information, including any
              compilations derived from and allowing identification of PHI. Business Associate shall
              complete such return or destruction as promptly as possible, but not more than thirty
              (30) days after the effective date of the conclusion of this Agreement. Within such
              thirty (30) day period, Business Associate shall certify on oath in writing to Covered
              Entity that such return or destruction has been completed.
            </Item>
            <Item>
              If Business Associate believes that the return or destruction of PHI or Health
              Information is not feasible, Business Associate shall provide written notification of
              the conditions that make return or destruction infeasible. Upon mutual agreement of
              the Parties that return or destruction is not feasible, Business Associate shall
              extend the protections of this Agreement to PHI and Health Information received from
              or created on behalf of Covered Entity, and limit further uses and disclosures of such
              PHI, for so long as Business Associate maintains the PHI.
            </Item>
          </List>
        </Block>

        <Block id='baa19'>
          <Title>19. Miscellaneous.</Title>
          <List type='a'>
            <Item>
              <Bold>Automatic Amendment.</Bold> Upon the effective date of any amendment to HIPAA,
              the Privacy Rule or the Security Rule promulgated by HHS with regard to PHI, this
              Agreement shall automatically amend so that the obligations imposed on Business
              Associate remain in compliance with such regulations.
            </Item>
            <Item>
              <Bold>Cooperation.</Bold> Business Associate shall provide prompt and reasonable
              cooperation to Covered Entity as appropriate and necessary for Covered Entity to fully
              investigate actual or probable Privacy or Security Incidents.
            </Item>
            <Item>
              <Bold>Indemnification.</Bold> Business Associate shall defend and hold Covered Entity
              harmless from all claims, liabilities, damages, or judgments involving a third party,
              including Covered Entity&apos;s costs and attorney fees, including but not limited to
              costs of patient notification, which arise as a result of Business Associate&apos;s
              failure to meet any of its obligations under this Agreement.
            </Item>
            <Item>
              <Bold>Response to Subpoenas.</Bold> In the event Business Associate receives a
              subpoena or similar notice or request from any judicial, administrative or other party
              which would require the production of PHI received from, or created for, Covered
              Entity, Business Associate shall promptly forward a copy of such subpoena, notice or
              request to Covered Entity to afford Covered Entity the opportunity to timely respond
              to the demand for its PHI as Covered Entity determines appropriate according to its
              state and federal obligations.
            </Item>
            <Item>
              <Bold>Amendments.</Bold> This Agreement may be amended or altered only upon mutual
              written agreement of the parties hereto.
            </Item>
            <Item>
              <Bold>Interpretation.</Bold> Any ambiguity in this Agreement shall be resolved in
              favor of a meaning that permits Covered Entity to comply with HIPAA.
            </Item>
          </List>
        </Block>
      </Section>
    </Container>
  )
}

export default BAAPage
