import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import CircleIcon from '@mui/icons-material/CheckCircle'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import Logo from '../stories/PartnerBadge'
import ClaimProfileVideo from '../assets/videos/claim_profile.mp4'
import ClaimProfileGif from '../assets/images/claim_profile.gif'

import { ClaimProfileForm } from '@app/components/HubSpotForm'

const WhyMishePage = () => {
  const { search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Clinic Claim Profile',
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Mishe&#39;s Care Network | Easy Enrollment. Enhanced Rates.</title>
        <meta
          property='og:title'
          content='Mishe&#39;s Care Network | Easy Enrollment. Enhanced Rates.'
        />
        <meta
          name='description'
          property='og:description'
          content='Enrollment in Mishe&#39;s Care Network is quick and straightforward. Gain a built-in referral source with quality clinicians.'
        />
        <meta property='og:url' content='https://mishe.co/claimprofile' />
        <link rel='canonical' href='https://mishe.co/claimprofile' />
      </Helmet>
      <Section>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box mb={5}>
                <Typography variant='h3' gutterBottom mb={4}>
                  Claim your Mishe profile!
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    What you get:
                  </Box>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  Guaranteed payment for services rendered
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  100% of earnings - we don&apos;t mark up prices!
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  Freedom to set your own cash prices
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  Dedicated concierge support for intake & scheduling
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  Digital front door
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  Certified Mishe Partner Badge
                  <Logo size='huge' />
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  Built-in referral network
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  Integrated access to instant consumer financing
                </Typography>
              </Box>
              <Box mb={4}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  <Box>
                    Compliance with{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.cms.gov/nosurprises/consumers/understanding-costs-in-advance'
                      style={{
                        color: '#662D91',
                        textDecoration: 'none',
                      }}
                    >
                      Good Faith Estimates
                    </a>{' '}
                    Requirement of the No Surprises Act
                  </Box>
                </Typography>
              </Box>
              <Typography variant='h6' gutterBottom mb={4}>
                What it costs?{' '}
                <Box component='span' color='primary.main'>
                  Nothing!
                </Box>
              </Typography>
              <Box mb={4}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '460px',
                  }}
                >
                  Our mission is to empower direct-to-consumer healthcare and increase access in an
                  equitable way. Network enrollment is quick and easy and you have full control of
                  your profile.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} />
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ClaimProfileForm />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section bgcolor='background.smoke'>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img src={ClaimProfileGif} alt='Claim Profile Gif' width='100%' />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} />
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box display='flex' justifyContent='center' mb={4}>
                <Typography variant='h3' gutterBottom>
                  Transparency is quality.
                </Typography>
              </Box>
              <Box display='flex' justifyContent='center' mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  align='center'
                  style={{
                    display: 'flex',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  77% of consumers what to know what care will cost before it happens
                </Typography>
              </Box>
              <Box display='flex' justifyContent='center' mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  align='center'
                  style={{
                    display: 'flex',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  63% of consumers avoid care when they don&apos;t know what it will cost
                </Typography>
              </Box>
              <Box display='flex' justifyContent='center' mb={2}>
                <Typography
                  variant='body1'
                  weight='light'
                  gutterBottom
                  align='center'
                  style={{
                    display: 'flex',
                    maxWidth: '460px',
                  }}
                >
                  <CircleIcon style={{ color: '#662D91', marginRight: 15 }} />
                  56% of consumers would consider changing healthcare providers for a better payment
                  experience
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <video
                controls
                style={{
                  width: '100%',
                  height: '100%',
                  top: '0',
                  right: '0',
                  position: 'relative',
                }}
              >
                <source src={ClaimProfileVideo} type='video/mp4' />
              </video>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  )
}

export default WhyMishePage
