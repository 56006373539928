import { useCallback, useState } from 'react'
import { collection } from '../firebase/firestore'
import { ADD_NOTE } from '@app/libs/constant'

const useNotes = () => {
  // const [synced, setSynced] = useState(true)
  const [notes, setNotes] = useState([])

  const addTextNote = useCallback(
    async data => {
      try {
        console.log('Add Text Note')
        console.log('data', data)
        // add a note to the note collection
        const note = {
          noteType: 'text',
          content: data.content,
          timestamp: new Date(),
          patient: data.patient,
          organization: data.organization,
          voucher: data.voucher || null,
          orderID: data.orderID || null,
          noteStatus: data.noteStatus,
        }
        const noteRef = await collection('notes').add(note)
        if (noteRef) {
          const newNote = {
            ...note,
            uid: noteRef.id,
          }
          setNotes([newNote, ...notes])
          return true
        }
        return false
      } catch (error) {
        console.log('error adding text note')
        console.log(error)
        return false
      }
    },
    [notes],
  )

  const addFileNote = useCallback(
    async data => {
      try {
        // add a note to the note collection
        const note = {
          noteType: 'file',
          fileName: data.fileName,
          content: data.content,
          timestamp: new Date(),
          patient: data.patient,
          organization: data.organization,
          voucher: data.voucher || null,
          orderID: data.orderID || null,
          noteStatus: ADD_NOTE.FINALIZE,
        }
        const noteRef = await collection('notes').add(note)
        if (noteRef) {
          const newNote = {
            ...note,
            uid: noteRef.id,
          }
          setNotes([newNote, ...notes])
          return true
        }
        return false
      } catch (error) {
        console.log('error adding file note')
        console.log(error)
        return false
      }
    },
    [notes],
  )

  const addImageNote = useCallback(
    async data => {
      try {
        // add a note to the note collection
        const note = {
          noteType: 'image',
          fileName: data.fileName,
          content: data.content,
          timestamp: new Date(),
          patient: data.patient,
          organization: data.organization,
          voucher: data.voucher || null,
          orderID: data.orderId || null,
          noteStatus: ADD_NOTE.FINALIZE,
        }
        const noteRef = await collection('notes').add(note)
        if (noteRef) {
          const newNote = {
            ...note,
            uid: noteRef.id,
          }
          setNotes([newNote, ...notes])
          return true
        }
        return false
      } catch (error) {
        console.log('error adding image note')
        console.log(error)
        return false
      }
    },
    [notes],
  )

  return {
    addTextNote,
    addFileNote,
    addImageNote,
    notes,
    setNotes,
  }
}

export default useNotes
