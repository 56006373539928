import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import Typography from '@app/stories/Typography'
import { Link } from 'react-router-dom'

const VidCallButton = ({ voucher, label = 'Join Mishe Video Call' }) => {
  const linkToVideoChat = `/videochat?id=${voucher.id}`

  return (
    <Button
      variant='outlined'
      size='small'
      component={Link}
      to={linkToVideoChat}
      target='_blank'
      style={{
        textTransform: 'none',

        alignItems: 'center',
        color: '#652d92',
        borderColor: '#652d92',
      }}
    >
      <VideoCallIcon />
      <Typography variant='body2' style={{ marginLeft: '8px' }}>
        {label}
      </Typography>
    </Button>
  )
}

VidCallButton.propTypes = {
  voucher: PropTypes.object.isRequired,
  label: PropTypes.string,
}

export default VidCallButton
