import { memo } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '../../stories/Typography'
import VoucherChat from '@app/components/VoucherChat'
import { getVoucherType } from '@app/utils/userFormatterUtil'

const VoucherChatContainer = ({ voucher }) => (
  <Grid item xs={12} sm={12} md={4}>
    <Box mt={2}>
      <Typography variant='h4' mb={2}>
        {getVoucherType(voucher) === 'Bundle Parent' ? 'Bundle' : 'Claim'} Chat
      </Typography>
    </Box>
    <VoucherChat
      chatID={voucher?.id || voucher?.uid}
      clinic={voucher?.organization?.name}
      patientName={voucher?.customer?.name}
    />
  </Grid>
)

export default memo(VoucherChatContainer)
