import { createContext, useContext, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { collection } from '../firebase/firestore'
import { signUp } from '../firebase/auth'
import { createOrderNetwork } from '../firebase/functions'

const QuickCheckoutNetwork = createContext()

export const useQuickCheckout = () => useContext(QuickCheckoutNetwork)

const QuickCheckoutNetworkProvider = ({ paymentIntent, children }) => {
  const [paymentIntentId, setPaymentIntentId] = useState(paymentIntent)
  const [disabled, setDisabled] = useState(false)
  const [networkId, setNetworkId] = useState(null)
  const [networkName, setNetworkName] = useState(null)
  const [networkObj, setNetworkObj] = useState(null)
  const [service, setService] = useState(null)
  const [serviceId, setServiceId] = useState(null)
  const [stripeError, setStripeError] = useState(null)
  const [firebaseError, setFirebaseError] = useState(null)

  const createAccount = async (email, password, profile) => {
    try {
      console.log('-- Creating account')
      const status = await signUp(email, password, profile)
      console.log('Account created')
      return {
        uid: status?.uid,
        status: 'success',
      }
    } catch (error) {
      setFirebaseError(error)
      console.log('** There was an error in createAccount')
      console.log(error)
      return {
        code: error?.code,
        status: 'error',
      }
    }
  }

  const createOrder = async formData => {
    if (!service || !networkObj || !paymentIntentId) {
      console.log('No service or network or payment intent')
      console.log(service)
      console.log(networkObj)
      console.log(paymentIntentId)
      return false
    }
    console.log('Creating order')

    const paymentInfo = {
      serviceId,
      networkId,
      networkName,
      paymentIntentId,
      ...formData,
    }

    console.log('Payment info: ')
    console.log(paymentInfo)

    const orderResponse = await createOrderNetwork(paymentInfo).catch(err => {
      console.log('Error creating order')
      console.log(err)
      setFirebaseError(err)
      return false
    })

    console.log('Order response: ')
    console.log(orderResponse)

    if (!orderResponse) {
      return false
    }
    return orderResponse?.paymentIntentId
  }

  const getService = useCallback(
    async serId => {
      if (!serId) {
        console.log(' ***** No service ID')
        return
      }

      const serviceDoc = await collection('networks')
        .doc(networkId)
        .collection('services')
        .doc(serId)
        .get()
        .catch(err => {
          console.log(' ****** Error getting service')
          console.log(err)
          setService(null)
        })

      if (serviceDoc !== undefined && serviceDoc.exists) {
        console.log(' ----- Service found')
        const docData = serviceDoc.data()
        setService({ ...docData, uid: serviceDoc.id, id: serviceDoc.id })
      } else {
        console.log(' ----- Service not found')
        setService(null)
      }
    },
    [setService, networkId],
  )

  const getNetworkByName = useCallback(
    async netName => {
      if (!netName) {
        return
      }
      const networkDocs = await collection('networks')
        .where('slug', '==', netName)
        .get()
        .catch(err => {
          console.log(err)
          setNetworkId(null)
          setNetworkObj(null)
        })
      if (networkDocs.empty) {
        console.log('network not found')
        setNetworkId(null)
        setNetworkObj(null)
        return
      }
      setNetworkId(networkDocs.docs[0].id)
      setNetworkObj({
        id: networkDocs.docs[0].id,
        ...networkDocs.docs[0].data(),
      })
    },
    [setNetworkId, setNetworkObj],
  )

  const getNetworkByID = useCallback(
    async netId => {
      const networkDoc = await collection('networks')
        .doc(netId)
        .get()
        .catch(err => {
          console.log(err)
          setNetworkId(null)
          setNetworkObj(null)
        })
      if (!networkDoc.exists) {
        console.log('network not found')
        setNetworkName(null)
        setNetworkObj(null)
        return
      }
      const data = {
        id: networkDoc.id,
        ...networkDoc.data(),
      }
      setNetworkName(data.name)
      setNetworkObj(data)
    },
    [setNetworkName, setNetworkObj],
  )

  useEffect(() => {
    if (serviceId) {
      getService(serviceId)
    }
    return undefined
  }, [serviceId, getService])

  const value = {
    networkId,
    setNetworkId,
    networkName,
    setNetworkName,
    serviceId,
    setServiceId,
    service,
    getNetworkByName,
    getNetworkByID,
    networkObj,
    createAccount,
    createOrder,
    stripeError,
    firebaseError,
    disabled,
    setDisabled,
  }
  return <QuickCheckoutNetwork.Provider value={value}>{children}</QuickCheckoutNetwork.Provider>
}

QuickCheckoutNetworkProvider.propTypes = {
  children: PropTypes.node.isRequired,
  paymentIntent: PropTypes.string.isRequired,
}

export default QuickCheckoutNetworkProvider
